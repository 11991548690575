export class AppContactTypeModel {
    
    public contactTypeId: number = 0;
    public contactType: string = '';
    public isActive: boolean = true;
    public sortOrder: number = 0;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}
