<div class='wrap alt pb-0'>
    <div class='container'>

        <div class="row">
            <div class="col-12">

                <mdb-card class="mb-5">

                    <mdb-card-body>

                        <mdb-card-title>
                            <h3>
                                Users
                                <button mdbBtn type="button" class="btn-segal float-right" (click)="onNavigateAdd()">
                                    <i class="fa fa-plus" aria-hidden="true"></i> Add
                                </button>
                            </h3>
                        </mdb-card-title>

                        <div *ngIf="!loading"  class="row search-row" style="padding-bottom: 30px;">
                            <div class="col-md-6 dashboard-search">
                                <select class="form-control sort-by z-depth-1" (change)="filterUserList()"
                                    [(ngModel)]="filterTypeId">
                                    <option *ngFor="let filter of optionsFilterType;" [value]="filter.value">
                                        {{filter.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div *ngIf="loading" class='row'>
                            <div class='col'>
                                <div class="spinner-border text-info segal-spinner" role="status">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!loading" class="row">
                            <div class="col">
                                <div class="table-responsive table-clickable">
                                    <table mdbTable>

                                        <thead>
                                            <tr>
                                                <th><a (click)="sortBy('NAME')">Name</a></th>
                                                <th><a (click)="sortBy('BU')">Business Unit</a></th>
                                                <th><a (click)="sortBy('TYPE')">Role</a></th>
                                                <th><a (click)="sortBy('DATE')">Added</a></th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr mdbTableCol *ngFor="let item of pageService.filteredUsers; let i=index"
                                                (click)="onNavigateEdit(item, i)">

                                                <!-- NAME -->
                                                <td>{{item.employee == null ? '' : item.employee.fullName}}
                                                </td>

                                                <!-- BUSINESS UNIT -->
                                                <td>{{item.employee == null ? '' : item.employee.businessUnit}}
                                                </td>

                                                <!-- ROLE -->
                                                <td>{{item.roleType == null ? '' : item.roleType.roleTypeNm}}
                                                </td>

                                                <!-- DATE -->
                                                <td>{{item.createdOn | date}}</td>

                                                <!-- DELETE -->
                                                <td class="text-right">
                                                    <button mdbBtn type="button" class="icon-btn"
                                                        (click)="onDelete(item, i); $event.stopPropagation()">
                                                        <i class="fa fa-trash" aria-hidden="true" title="Remove"></i>
                                                    </button>
                                                </td>

                                            </tr>
                                        </tbody>

                                        <tfoot *ngIf="pageService.filteredUsers.length === 0">
                                            <tr>
                                                <td class="text-center no-results" colspan="5">
                                                    No Users Found
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>
                        </div>

                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>



    </div>
</div>

