
export class EmployeeModel {
    public empId!: string;
    public firstName!: string;
    public middleName!: string;
    public lastName!: string;
    public fullName!: string;
    public nickName!: string;
    public email!: string;
    public office!: string;
    public locationCode!: string;
    public businessUnit!: string;
    public avatar!: string;
}
