import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from './config.service';
import { EmployeeModel } from '@app/_models/employee-model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  
  constructor(private http: HttpClient) { }

  public getActiveEmployees(): Observable<Array<EmployeeModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/bia/employees';

    return this.http
      .get<Array<EmployeeModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}
