import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppDocumentModel } from '@app/_models/app-document-model';
import { DocumentFileTypeService } from '@app/_services/document-file-type.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AppListPageService } from '../app-list/app-list-page.service';
import { AppDocumentPageService } from './app-document-page.service';

@Component({
  selector: 'app-app-document-list',
  templateUrl: './app-document-list.component.html',
  styleUrls: ['./app-document-list.component.scss']
})
export class AppDocumentListComponent implements OnInit {

  loading = true;

  sortDirection = 'ASC';
  appTitle = '';

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private toastService: ToastService,
    private fileTypeService: DocumentFileTypeService,    
    private router: Router,
    private route: ActivatedRoute,
    private pageServiceApp: AppListPageService,
    public pageService: AppDocumentPageService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {
      if (params.appId != undefined) {

        const appId = +params.appId;
        this.loading = true;

        this.pageService.getList(appId).subscribe(result => {

          this.appTitle = this.pageServiceApp.modelDto.application.appName;
          this.loading = false;

          if (result.statusId < 0) {
            this.toastService.error(result.message, 'Document List Error', this.toastMessageOptions);
          }
        })
      }
    });
  }

  getFileType(extension: string) {
    return this.fileTypeService.getType(extension);
  }

  onNavigateList(): void {
    this.router.navigate(['app-list']);
  }

  onNavigateApp() {
    //this.pageService.resetModel();
    this.router.navigate(['app-step'], { queryParams: { appId: this.pageService.appId } });
  }

  //onShowContacts(): void {
  //  this.router.navigate(['app-contact-list'], { queryParams: { appId: this.pageService.appId } });
  //}

  onNavigateEdit(document: AppDocumentModel) {
    this.pageService.resetModel();

    this.pageService.onFileEdit(document.documentId).subscribe(result => {
      if (result) {
        this.pageService.updateDocument().subscribe(saveResult => {
          if (saveResult.statusId < 0) {
            this.toastService.error(saveResult.message, 'Document Update Error', this.toastMessageOptions);
          }
        });
      }
    });
  }

  onNavigateAdd() {
    this.pageService.resetModel();
    
    this.pageService.onFileEdit(0).subscribe(result => {
      if (result) {
        this.pageService.addDocument().subscribe(saveResult => {
          if (saveResult.statusId < 0) {
            this.toastService.error(saveResult.message, 'Document Add Error', this.toastMessageOptions);
          }
        });
      }
    });
  }


  onDownload(document: AppDocumentModel) {
    let fileName = document.fileName + '.' + document.fileExt;
    this.pageService.download(document.documentId, fileName)
  }

  onDelete(document: AppDocumentModel) {

    if (document === null) {
      return;
    }

    const doc = this.pageService.modelList.find(
      (a) => a.documentId === document.documentId
    );

    if (doc == null) {
      console.log(
        `Unable to find document with id ${document.documentId.toString()}`
      );
      return;
    }

    const index = this.pageService.modelList.indexOf(doc, 0);

    if (index < 0) {
      console.log(`Unable to find document with index ${index}`);
      return;
    }

    this.pageService.confirmDeleteDocument().subscribe(result => {

      if (result) {

        this.pageService.deleteDocument(doc).subscribe(deleteResult => {

          if (deleteResult.statusId < 0) {
            this.toastService.error(deleteResult.message, 'Document Delete Error', this.toastMessageOptions);
            return;
          }
          this.pageService.modelList.splice(index, 1);
        })
      }
    });
  }

  sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    
    if (columnName === 'NAME' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.fileName.localeCompare(b.fileName));

    } else if (columnName === 'NAME') {

      this.pageService.modelList.sort((a, b) => b.fileName.localeCompare(a.fileName));

    }

    else if (columnName === 'DESC' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.documentDesc.localeCompare(b.documentDesc));

    } else if (columnName === 'DESC') {

      this.pageService.modelList.sort((a, b) => b.documentDesc.localeCompare(a.documentDesc));

    }

    else if (columnName === 'TYPE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.appDocumentType == null ? '' : a.appDocumentType.documentTypeNm;
        const d = b.appDocumentType == null ? '' : b.appDocumentType.documentTypeNm;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'TYPE') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.appDocumentType == null ? '' : a.appDocumentType.documentTypeNm;
        const d = b.appDocumentType == null ? '' : b.appDocumentType.documentTypeNm;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'DATE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });

    } else if (columnName === 'DATE') {

      this.pageService.modelList.sort((b, a) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });
    }

  }
}

