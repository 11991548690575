import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeModel } from '@app/_models/employee-model';
import { APP_CONFIG } from '@app/_services/config.service';
import { AccountInfo } from '@azure/msal-browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { publishReplay, refCount } from 'rxjs/operators';
import { UserScreenDto } from '../_models/user-screen-dto';
import { UserVaccineDto } from '../_models/user-vaccine-dto';

@Injectable({
  providedIn: 'root'
})
export class WellnessUserService {

  private _user: EmployeeModel = new EmployeeModel();
  private _screenDto: UserScreenDto = new UserScreenDto();

  public admin = true;  // HACK: SET BACK TO FALSE WHEN AD SECURITY PRINCIPAL IN PLACE
  public cached = false;

  constructor(
    private http: HttpClient) { }

  public get user(): EmployeeModel {
    if (this._user == null) {
      console.log("UserService: Missing user");
      return new EmployeeModel();
    }

    return this._user;
  }

  public set user(model: EmployeeModel) {
    this._user = model;
  }

  public get screenDto(): UserScreenDto {
    if (this._screenDto == null) {
      console.log("UserService: Missing user screen DTO");
      return new UserScreenDto();
    }

    return this._screenDto;
  }

  public get vaccineDto(): UserVaccineDto {
    if (this._screenDto == null) {
      console.log("UserService: Missing user vaccine DTO");
      return new UserVaccineDto();
    }

    return this._screenDto.vaccineDto;
  }

  public set screenDto(model: UserScreenDto) {
    this._screenDto = model;
  }

  public set vaccineDto(model: UserVaccineDto) {
    this._screenDto.vaccineDto = model;
  }

  public setAdmin(account: AccountInfo) {

    if (account != undefined) {

      if (account.idTokenClaims != undefined) {

        const claims = account.idTokenClaims as any;
        const roles = claims.roles;

        if (roles != undefined) {

          const roleList = roles as Array<string>;

          roleList.forEach(role => {
            if (role == 'vaccine.admin') {
              this.admin = true;
              console.log("UserService: Admin user");
            }
          });
        }
      }
    }
  }

  public GetUser(): Observable<EmployeeModel> {

    let requestURL = APP_CONFIG.apiDomain + '/datawarehouse/employee';
    //let requestURL = APP_CONFIG.apiDomain + '/DataWarehouse/employee/netLogin/dhan';

    //if (APP_CONFIG.runAsUser.length > 0)  {
    //  requestURL = APP_CONFIG.apiDomain + '/datawarehouse/employee/netLogin/' + APP_CONFIG.runAsUser;
    //}

    return this.http
      .get<EmployeeModel>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public GetEmployeeList(): Observable<Array<EmployeeModel>> {
    return this.http
      .get<Array<EmployeeModel>>(
        APP_CONFIG.apiDomain + '/datawarehouse/Employees'
      )
      .pipe(
        e => e,
        publishReplay(1),
        refCount()
      );
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }

}
