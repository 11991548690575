<form [formGroup]="appDocForm" (ngSubmit)="onSubmit()">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDocument.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}}</h4>
        </div>

        <div class="modal-body">
            <div class="container">

                <!-- error messages -->
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-danger" *ngIf="isSubmitted && !appDocForm.valid">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Please fix the issues
                                below</span>
                            <ul>
                                <li *ngIf="appDocForm.get('documentName')?.errors?.required">
                                    Please select a file.
                                  </li>
                                <li *ngIf="appDocForm.get('documentTypeId')?.errors?.required">
                                    Please select a document type.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Date Uploaded -->
                <div class="row" *ngIf="!isNewDocument || fileDropped">
                    <div class="col-12 col-lg-6">
                        <div class="md-form">
                            <input mdbInput type="text" class="form-control" formControlName="uploadedDate" readonly />
                            <label>Date Uploaded</label>
                        </div>
                    </div>
                </div>

                <!-- Document Name -->
                <div class="row" *ngIf="!isNewDocument || fileDropped">
                    <div class="col-12 col-lg-12">
                        <div class="md-form">
                            <input type="text" mdbInput class="form-control" formControlName="documentName" readonly>
                            <label for="file">File Name</label>
                        </div>
                    </div>
                </div>                

                <!-- Document Type -->
                <div class="row" *ngIf="!isNewDocument || fileDropped">
                    <div class="col-12 col-lg-12">
                        <div class="md-form">
                            <mdb-select formControlName="documentTypeId" [options]="optionsDocumentType"
                                placeholder="Please Select" class="form-control-mdb">
                            </mdb-select>
                            <label>Document Type</label>
                        </div>
                    </div>
                </div>

                <!-- File Description -->
                <div class="row" *ngIf="!isNewDocument || fileDropped">
                    <div class="col-12 col-lg-12">
                        <div class="md-form">
                            <textarea type="text" formControlName="documentDesc" placeholder="Enter ..."
                                class="md-textarea form-control auto-height" mdbInput
                                style="min-height: 143px;"></textarea>
                            <label>File Description</label>
                        </div>
                    </div>
                </div>

                <!-- ngx-dropzone -->
                <div class="row" *ngIf="isNewDocument && !fileDropped">
                    <div class="col-12">
                        <div class="md-form">
                            <ngx-dropzone (change)="onDropFile($event);">
                                <ngx-dropzone-label>Drag and drop a file here or click to browse</ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                    (removed)="onRemove(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="modal-footer">
            <button type="button" mdbBtn class="waves-light btn-segal" aria-label="Close" (click)="onCancel()"
                mdbWavesEffect>Cancel
            </button>
            <button type="submit" mdbBtn class="relative waves-light btn-segal" mdbWavesEffect>Save</button>
        </div>

    </div>
</form>
