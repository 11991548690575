import { Component, OnInit } from '@angular/core';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaProcessComponent } from '../bia-process/bia-process.component';
import { BiaProcessListSortService } from './bia-process-list-sort.service';
import { HelpPageService } from '@app/_services/help-page.service';

@Component({
  selector: 'app-bia-process-list',
  templateUrl: './bia-process-list.component.html',  
  styleUrls: ['./bia-process-list.component.scss'],
  providers: [ BIAEditStepListService, BiaProcessListSortService ]
})
export class BiaProcessListComponent implements OnInit, IBIAStepListComponent {

  isDirty = false;
  saving = false;
  pageIndex = 2;

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaProcessListSortService,
    private helpService: HelpPageService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {        
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {        
    if (this.onAddProcess == null) {
      this.pageService.modelDto.bia.biaBusinessProcess = new Array<BIABusinessProcessModel>();
    }
  }

  onAddProcess() {
    this.onOpenProcess(new BIABusinessProcessModel(), -100);
  }

  onDeleteProcess(process: BIABusinessProcessModel, index: number) {

    this.pageService.confirmDeleteItem('business process').subscribe(result => {

      if (result) {
      
        this.pageService.deleteBIAProcess(process).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.bia.biaBusinessProcess.splice(index, 1);
          }
        })        
      }
    });    
}

  onOpenProcess(process: BIABusinessProcessModel, i: number): void {

    const mf = this.modalService.show(BiaProcessComponent, {
      class: 'modal-lg',
      data: {
        paramItem: process
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedProcess: BIABusinessProcessModel) => {
        if (i == -100) {
          this.pageService.modelDto.bia.biaBusinessProcess.push(updatedProcess);
        }
        else {
          this.pageService.modelDto.bia.biaBusinessProcess[i] = updatedProcess;
        }
        
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );    
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaBusinessProcess);
  }

  public getHelpText(): void {
    this.helpService.showHelpText('bia-process.html', 'BIA Business Process Help', false);
  }

  impactCategoryName(categoryId: number): string {
    
    if (categoryId == 1) {
      return "Severe";

    } else if (categoryId == 2) {
      return "Moderate";

    } else if (categoryId == 3) {
      return "Minimal";

    }

    return "";
  }
}
