export class BusinessUnitModel {

    public userGroupId: number = 0;
    public unt: string = '';
    public userGroupName: string = '';
    public bu: string = '';
    public isActive: boolean = true;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}
