import { ContentTypeModel } from "@app/_models/content-type-model";
import { UserDocumentTypeModel } from "./user-document-type-model";

export class UserVaccineDocumentModel {
    public documentId: number = 0;
    public userBoosterId: number = 0;
    public userId: number = 0;
    public documentTypeId: number = 0;
    public fileName = '';
    public fileExt = '';
    public contentTypeId: number = 0;
    public createdBy: number = 0;
    public createdDt: Date = new Date();
    public updatedDt: Date = new Date();

    public documentType: UserDocumentTypeModel = new UserDocumentTypeModel();
    public contentType: ContentTypeModel = new ContentTypeModel();
}
