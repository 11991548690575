import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserVaccineModel } from '@app/wellness/_models/user-vaccine-model';
import { ToastService } from 'ng-uikit-pro-standard';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';
import { UserVaccineAdminPageService } from './user-vaccine-admin-page.service';

@Component({
  selector: 'app-user-vaccine-admin',
  templateUrl: './user-vaccine-admin.component.html',
  styleUrls: ['./user-vaccine-admin.component.scss']
})
export class UserVaccineAdminComponent implements OnInit {

  optionsFilterType: any[] = new Array<{ value: string, label: string }>();

  filteredUsers: Array<UserVaccineModel> = new Array<UserVaccineModel>();

  loading = true;

  searchText = '';

  sortDirection = 'ASC';

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private router: Router,
    private toastService: ToastService,
    private pageService: UserVaccineAdminPageService,
    private userVaccinePageService: UserVaccinePageService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  get filterTypeId(): string {
    return this.pageService.filterTypeId;
  }

  set filterTypeId(value: string) {
    this.pageService.filterTypeId = value;
  }

  onNavigateUserVaccine(user: UserVaccineModel) {

    this.userVaccinePageService.vaccine = user;
    this.userVaccinePageService.adminView = true;

    if (user.accommodationTypeId > 0) {
      this.router.navigate(['vaccine'], { queryParams: { viewId: '2' } });

    } else if (user.unvaccinated) {
        this.router.navigate(['vaccine'], { queryParams: { viewId: '3' } });

    } else {
      this.router.navigate(['vaccine'], { queryParams: { viewId: '1' } });
    }
  }

  showDoseDateWarning(user: UserVaccineModel): string {

    if (user.accommodationTypeId > 0) {
      return '';
    }

    const nameId = user.vaccineNameId;

    if (!this.userVaccinePageService.isSecondDoseRequired(nameId)) {
      if (user.doseDt1 != null) {
        const doseDt = new Date(user.doseDt1);
        return this.userVaccinePageService.getDoseDate1Warning(doseDt);
      }
    }

    if (user.doseDt2 != null) {
      const doseDt = new Date(user.doseDt2);
      return this.userVaccinePageService.getDoseDate2Warning(doseDt);
    }

    return '';
  }

  sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'user' && this.sortDirection === 'ASC') {

      this.filteredUsers.sort((a, b) => a.user.fullName.localeCompare(b.user.fullName));

    } else if (columnName === 'user') {

      this.filteredUsers.sort((a, b) => b.user.fullName.localeCompare(a.user.fullName));

    } else if (columnName === 'type' && this.sortDirection === 'ASC') {

      this.filteredUsers.sort((a: UserVaccineModel, b: UserVaccineModel) => {
        const c = a.vaccineName == null ? '' : a.vaccineName.vaccineName;
        const d = b.vaccineName == null ? '' : b.vaccineName.vaccineName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'type') {

      this.filteredUsers.sort((a: UserVaccineModel, b: UserVaccineModel) => {
        const c = a.vaccineName == null ? '' : a.vaccineName.vaccineName;
        const d = b.vaccineName == null ? '' : b.vaccineName.vaccineName;
        return d.localeCompare(c);
      }
      );

    } else if (columnName === 'submit' && this.sortDirection === 'ASC') {

      this.filteredUsers.sort((a, b) => {
        const c = a.createdDt == null ? new Date('01/01/1900') : new Date(a.createdDt);
        const d = b.createdDt == null ? new Date('01/01/1900') : new Date(b.createdDt);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });

    } else if (columnName === 'submit') {

      this.filteredUsers.sort((a, b) => {
        const d = a.createdDt == null ? new Date('01/01/1900') : new Date(a.createdDt);
        const c = b.createdDt == null ? new Date('01/01/1900') : new Date(b.createdDt);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });

    }
  }

  vaccineRecordTypeName(user: UserVaccineModel): string {
    return this.pageService.getVaccineRecordTypeName(user.accommodationTypeId);
  }

  deny(user: UserVaccineModel): void {
    this.updateUserStatus(user, 3);
  }

  approve(user: UserVaccineModel): void {
    this.updateUserStatus(user, 2);
  }

  onDownloadFile(user: UserVaccineModel) {
    this.pageService.downloadFile(user);
  }

  getFileType(user: UserVaccineModel) {
    return this.pageService.getFileType(user);
  }

  updateUserStatus(model: UserVaccineModel, statusId: number) {

    const index = this.filteredUsers.indexOf(model);

    this.pageService.updateUserStatus(model, statusId).subscribe(e => {

      // remove item if pending accommodation list

      if (+this.pageService.filterTypeId === 1 && index >= 0) {
        this.filteredUsers.splice(index, 1);
      }

      if (e.statusId >= 0) {
        this.toastService.success(e.message, null, this.toastMessageOptions)
      } else {
        this.toastService.error(e.message, null, this.toastMessageOptions)
      }

    });
  }

  private getUsers() {

    this.optionsFilterType.push({ value: 1, label: 'Pending Accommodations' });
    this.optionsFilterType.push({ value: 2, label: 'Approved Accommodations' });
    this.optionsFilterType.push({ value: 6, label: 'Denied Accommodations' });
    this.optionsFilterType.push({ value: 3, label: 'All Accommodations' });
    this.optionsFilterType.push({ value: 7, label: 'Missing Vaccine Card Upload' });
    this.optionsFilterType.push({ value: 4, label: 'Complete Vaccine Cards' });
    this.optionsFilterType.push({ value: 8, label: 'Unvacinated' });
    this.optionsFilterType.push({ value: 9, label: 'Booster Cards' });
    this.optionsFilterType.push({ value: 5, label: 'All Accommodations and Complete Vaccine Cards' });

    this.pageService.getUserVaccineAdminDto().subscribe(userList => {

      this.filteredUsers = userList;
      this.filterUserList();

      this.loading = false;
    })

  }

  filterUserList() {

    let filterTypeId = +this.pageService.filterTypeId;

    this.filteredUsers = new Array<UserVaccineModel>();

    this.pageService.users.forEach(user => {

      // Pending Accommodations

      if (filterTypeId === 1 && user.accommodationTypeId > 0 && user.statusId === 1) {

        this.filteredUsers.push(user);

        // Approved Accommodations 

      } else if (filterTypeId === 2 && user.accommodationTypeId > 0 && user.statusId === 2) {

        this.filteredUsers.push(user);

        // Denied Accommodations 

      } else if (filterTypeId === 6 && user.accommodationTypeId > 0 && user.statusId === 3) {

        this.filteredUsers.push(user);

        // All Accommodations

      } else if (filterTypeId === 3 && user.accommodationTypeId > 0) {

        this.filteredUsers.push(user);

        // All Vaccine Cards

      } else if (filterTypeId === 4 && user.vaccineNameId > 0 && user.document.documentId > 0) {

        this.filteredUsers.push(user);

        // Missing Vaccine Card Upload

      } else if (filterTypeId === 7 && user.vaccineNameId > 0 && user.document.documentId == 0) {

        this.filteredUsers.push(user);

        // All Accommodations and Complete Vaccine Cards

      } else if (filterTypeId === 5 && user.document.documentId > 0) {

        this.filteredUsers.push(user);

        // Unvaccinated

      } else if (filterTypeId === 8 && user.unvaccinated) {

        this.filteredUsers.push(user);

        // Booster Cards

      } else if (filterTypeId === 9 && user.vaccineNameId > 0 && user.boosters.length > 0) {

        this.filteredUsers.push(user);

      } 

    });


    if (this.searchText.length > 0) {
      this.filteredUsers = this.filteredUsers.filter(userVaccine =>
        (userVaccine.user.fullName.toLowerCase().indexOf(this.searchText) >= 0) ||
        (userVaccine.status.userVaccineStatusNm.toLowerCase().indexOf(this.searchText) >= 0)
      );
    }

    this.filteredUsers.sort((a, b) => a.user.fullName.localeCompare(b.user.fullName));
  }

}
