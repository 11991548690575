import { Component, OnInit } from '@angular/core';
import { AppContactModel } from '@app/_models/app-contact-model';
import { IAppStepListComponent } from '@app/_models/iappstep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { AppEditStepListService } from '../app-edit/app-edit-step-list.service';
import { AppListPageService } from '../app-list/app-list-page.service';
import { AppContactEditComponent } from '../app-contact-edit/app-contact-edit.component';
import { AppContactListSortService } from './app-contact-list-sort.service';

import { ActivatedRoute } from '@angular/router';
import { LookupService } from '@app/_services/lookup.service';

@Component({
  selector: 'app-app-contact-list',
  templateUrl: './app-contact-list.component.html',
  styleUrls: ['./app-contact-list.component.scss'],
  providers: [ AppEditStepListService, AppContactListSortService ]
})
export class AppContactListComponent implements OnInit, IAppStepListComponent {

  pageIndex = 3;
  isDirty = false;
  saving = false;

  loading = false;
  appTitle = '';
  appId = 0;

  public listItems: Array<AppContactModel> = new Array<AppContactModel>();

  constructor(
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private modalService: MDBModalService,
    private sortService: AppContactListSortService,
    public stepService: AppEditStepListService,
    public pageService: AppListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    if (this.onAddContact == null) {
      this.pageService.modelDto.application.appContacts = new Array<AppContactModel>();
    }

    this.listItems = this.pageService.modelDto.application.appContacts;

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.appId = params.appId;
        console.log(this.appId);
      });
  }

  private reloadSection(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.appId != undefined) {

        const appId = +params.appId;
        this.loading = true;

        this.pageService.getApplication(appId).subscribe(result => {

          this.appTitle = this.pageService.modelDto.application.appName;
          this.loading = false;

          if (result) {
            this.loadPage();
          }
        });
      }
    });
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(2);
  }

  onCurrentStep(): void {
    this.pageService.onSetViewType(3);
  }

  onNextStep(): void {
    this.pageService.onSetViewType(4);
  }

  onAddContact() {
    this.onOpenContact(new AppContactModel(), -100);
  }

  onOpenContact(contact: AppContactModel, i: number): void {

    const mf = this.modalService.show(AppContactEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: contact,
        employeeList: this.lookupService.activeEmployees
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedContact: AppContactModel) => {
        if (i == -100) {
          this.pageService.modelDto.application.appContacts.push(updatedContact);
        }
        else {
          this.pageService.modelDto.application.appContacts[i] = updatedContact;
        }
        this.isDirty = true;
        //this.stepService.onSaveForm();
      }
    );

    this.listItems = this.pageService.modelDto.application.appContacts;
  }

  onDeleteContact(contact: AppContactModel, index: number) {

    this.pageService.confirmDeleteItem('point to contact').subscribe(result => {

      if (result) {

        this.pageService.deleteAppContact(contact).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.application.appContacts.splice(index, 1);
          }
        })
      }
    });
    //this.reloadSection();
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.application.appContacts);
  }
}
