import { Injectable } from '@angular/core';
import { IAppStepFormComponent } from '@app/_models/iappstep-component';
import { ToastService } from 'ng-uikit-pro-standard';
import { AppListPageService } from '../app-list/app-list-page.service';

@Injectable()
export class AppEditStepFormService {

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  public stepComponent!: IAppStepFormComponent;

  constructor(private toastService: ToastService,
    private pageService: AppListPageService) { }

  public setComponent(stepComponent: IAppStepFormComponent) {
    this.stepComponent = stepComponent;
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(this.stepComponent.pageIndex-1);
  }

  onNextStep(): void {
    this.saveForm(true, false);
  }

  onFinishStep(): void {
    this.saveForm(false, true);
  }

  onSaveCloseForm(): void {
    this.saveForm(false, true);
    this.closeForm();
  }

  onSaveForm(): void {
    this.saveForm(false, false);
  }

  public onCloseForm(): void {

    if (this.stepComponent.applicationForm.dirty) {
      this.pageService.confirmSaveChanges().subscribe(result => {
        if (result) {
          this.onSaveCloseForm();
          return;
        } else {
          this.closeForm();
        }
      });
    } else {
      this.closeForm();
    }    

  }

  private closeForm(): void {
    this.pageService.resetModel();
    this.pageService.navigateBack();
  }

  public saveForm(
    nextStep: boolean,
    navigateBack: boolean): void {

    if (this.stepComponent.saving) {
      return;
    }

    this.stepComponent.isSubmitted = true;

    if (!this.stepComponent.applicationForm.valid) {
      return;
    }

    let lastStep = (this.stepComponent.pageIndex == AppListPageService.STEP_COUNT);

    if (nextStep) {

      if (lastStep) {

        this.pageService.modelDto.application.stepId = 0;
        this.stepComponent.applicationForm.markAsDirty;

      } else if (this.pageService.modelDto.application.stepId < this.stepComponent.pageIndex) {

        this.pageService.modelDto.application.stepId = this.stepComponent.pageIndex;
        this.stepComponent.applicationForm.markAsDirty;
      }
    } else {
      this.pageService.modelDto.application.stepId = 0;
      this.stepComponent.applicationForm.markAsDirty;
    }

    this.stepComponent.loadDto();
    
    if (!this.stepComponent.applicationForm.dirty) {
      if (nextStep && !lastStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex+1);
      }      
      return;
    }
        
    this.stepComponent.saving = true;

    console.log("Application step form save start");

    let saveResult = false;

    this.pageService.saveApplication().subscribe(e => {

      if (e.statusId >= 0) {

        saveResult = true;

      } else {

        this.toastService.error(e.message, 'Application Save Error', this.toastMessageOptions);
      }

      this.stepComponent.saving = false;

      if (!saveResult) {
        return;
      }

      console.log("Application step form save end");

      if (lastStep) {
        this.toastService.success('The Application has been completed.', 'Application Complete', this.toastMessageOptions);
        this.pageService.navigateBack();
      }

      if (nextStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex+1);
      }

      else if (navigateBack) {
        this.pageService.navigateBack();
      }

      this.toastService.success('The Application section has been save.', 'Application', this.toastMessageOptions);
    })
  }
}
