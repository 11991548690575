<form [formGroup]="vaccineForm" class="md-form">

    <div class='wrap alt pb-0'>
        <div class='container'>

            <mdb-card class="mb-5">
                <mdb-card-body>

                    <!--Title-->

                    <mdb-card-title>
                        <h3>
                            {{pageTitle}}
                        </h3>
                    </mdb-card-title>

                    <div *ngIf="saving" class='row desktop-show'>
                        <div class='col'>
                            <div class="spinner-border text-info segal-spinner" role="status">                                
                            </div>
                        </div>
                    </div>


                    <!-- Not Vaccinated Checkbox -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <mdb-checkbox formControlName="unvaccinatedId" (change)="onUnvaccinatedChange($event)">{{checkBoxText}}</mdb-checkbox>
                                <div class="spinner-border text-info segal-spinner mobile-show" role="status" *ngIf="saving">                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Date Added -->
                    <!-- <div class="row" *ngIf='!isNew()'>
                        <div class="col-12 row-35">
                            Submitted: {{pageService.vaccine.createdDt | date}}
                        </div>
                    </div> -->

                    <!--SAVE Button-->
                    <div class="row" *ngIf='isNew() && enableSave && !pageService.adminView'>
                        <div class="col-12 col-lg-4">
                            <button mdbBtn type="button" style="padding-left: 15px;" class="btn-segal" (click)="onSave()"> SAVE </button>
                        </div>
                    </div>

              

                    <!--Back Button-->
                    <div class="row" *ngIf='pageService.adminView'>
                        <div class="col-12" style="padding-top: 10px;">
                            <div class="md-form">
                                <button mdbBtn type="button" class="btn-segal" (click)="pageService.navigateBack()">
                                    <i class="fas fa-chevron-left" aria-hidden="true"></i> Administration
                                </button>
                            </div>
                        </div>
                    </div>


                </mdb-card-body>
            </mdb-card>

        </div>
    </div>

</form>
