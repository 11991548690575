<form [formGroup]="docForm" (ngSubmit)="onSubmit()">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDocument.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}}</h4>
    </div>



    <div class="modal-body">
      <div class="container">
        <!-- error messages -->
        <div class="row">
          <div class="col-12">
            <div class="alert alert-danger" *ngIf="isSubmitted && !docForm.valid">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Please fix the issues below</span>
              <ul>
                <li *ngIf="docForm.get('documentName')?.errors?.required">
                  Please select a file.
                </li>
              </ul>
            </div>
          </div>
        </div>


        <!-- iManage Document properties -->
        <div class="row" *ngIf="fileDropped">
          <div class="col-12 col-lg-6">
            <div class="md-form">
              <input mdbInput type="text" class="form-control" formControlName="uploadedDate" readonly />
              <label>Date Uploaded</label>
            </div>
          </div>
        </div>



        <!-- document name -->
        <div class="row" *ngIf="fileDropped">
          <div class="col-12 col-lg-12">
            <div class="md-form">
              <input type="text" mdbInput class="form-control" formControlName="documentName" readonly>
              <label for="file">File Name</label>
            </div>
          </div>
        </div>

        <!-- ngx-dropzone -->
        <div class="row" *ngIf="isNewDocument">
          <div class="col-12">
            <div class="md-form">
              <ngx-dropzone (change)="onDropFile($event);">
                <ngx-dropzone-label>Drag and drop a file here or click to browse</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>
        </div>


      </div>
    </div>




    <div class="modal-footer">
      <button type="button" mdbBtn class="waves-light btn-segal" aria-label="Close" (click)="onCancel()"
        mdbWavesEffect>Cancel
      </button>
      <button type="submit" mdbBtn class="relative waves-light btn-segal" mdbWavesEffect>Save</button>
    </div>


  </div>
</form>