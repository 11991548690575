import { BusinessUnitModel } from "./business-unit-model";
import { ApplicationModel } from "./application-model";

import { AppContactTypeModel } from "./app-contact-type-model";
import { AppVendorsModel } from "./app-vendors-model";
import { AppTiersModel } from "./app-tiers-model";

import { AppAvailableViaModel } from "./app-availablevia-model";
import { AppCategoryModel } from "./app-category-model";
import { AppItSupportTypeModel } from "./app-itsupporttype-model";
import { AppLocationsModel } from "./app-locations-model";
import { AppSftwCategoryModel } from "./app-sftwcategory-model";

import { EmployeeModel } from "./employee-model";

export class ApplicationDto {
  public application: ApplicationModel = new ApplicationModel();

  public contactTypes: Array<AppContactTypeModel> = new Array<AppContactTypeModel>();
  public appTiers: Array<AppTiersModel> = new Array<AppTiersModel>();
  public appVendors: Array<AppVendorsModel> = new Array<AppVendorsModel>();
  public businessUnits: Array<BusinessUnitModel> = new Array<BusinessUnitModel>();

  public appSftwCategory: Array<AppSftwCategoryModel> = new Array<AppSftwCategoryModel>();
  public appCategory: Array<AppCategoryModel> = new Array<AppCategoryModel>();
  public appAvailableVia: Array<AppAvailableViaModel> = new Array<AppAvailableViaModel>();
  public appItSupportTypes: Array<AppItSupportTypeModel> = new Array<AppItSupportTypeModel>();
  public appLocations: Array<AppLocationsModel> = new Array<AppLocationsModel>();

  public employees: Array<EmployeeModel> = new Array<EmployeeModel>();
}
