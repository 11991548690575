<form [formGroup]="requestForm">

    <div class='wrap alt pb-0'>
        <div class='container'>

            <mdb-card class="mb-5">
                <mdb-card-body>

                    <!--Title-->

                    <mdb-card-title>
                        <h3>
                            {{pageTitle}}
                        </h3>
                    </mdb-card-title>

                    <div class='row'>
                        <div class='col'>

                            <div class="alert alert-danger" *ngIf="isSubmitted && (requestForm?.invalid)">

                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Accommodation
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>

                                <ul>
                                    <li *ngIf="requestForm?.get('typeId')?.errors?.required">
                                        Missing Accommodation Type.
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="saving" class='row desktop-show'>
                        <div class='col'>
                            <div class="spinner-border text-info segal-spinner" role="status">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <b>Step 1.</b> Select Type
                        </div>
                    </div>

                    <!--Accommodation Type-->
                    <div class="row">
                        <div class="col-12 col-lg-6 row-30">
                            <div class="md-form">
                                <mdb-select formControlName="typeId" [options]="optionsType" placeholder="Please Select"
                                    (selected)="onTypeChange($event)"
                                    [ngClass]="{'form-invalid':isSubmitted && requestForm.get('typeId')?.errors?.required}"
                                    class="form-control-mdb">
                                </mdb-select>
                                <label>Accommodation</label>
                                <mdb-error *ngIf="isSubmitted && requestForm.get('typeId')?.errors?.required">
                                    Please select type of accommodation
                                </mdb-error>
                                <div *ngIf="saving" class="spinner-border text-info segal-spinner mobile-show" role="status">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12">
                            <b>Step 2.</b> Download Form
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12 row-35">
                            <a href="../../../assets/{{templateName}}">
                                <i class="fa fa-file-pdf" aria-hidden="true"></i>
                                {{typeName}}
                            </a>
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12 col-lg-4">
                            &nbsp;
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12">
                            <b>Step 3.</b> Upload Completed Form
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll && !showFileUpload'>
                        <div class="col-12 row-25">
                            <button mdbBtn type="button" class="btn-segal-green" (click)="onUploadForm()"> UPLOAD COMPLETED
                                FORM
                            </button>
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll && showFileUpload'>
                        <div class="col-12 row-35">


                            Form Uploaded: {{pageService.vaccine.createdDt | date}}

                            <a href="javascript:void(0)" (click)="onDownloadFile()">
                                <i [ngClass]="getFileType()" aria-hidden="true" title="View Document"></i>
                                {{pageService.vaccine.document.fileName}}
                            </a>

                        </div>
                    </div>



                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12 col-lg-4">
                            &nbsp;
                        </div>
                    </div>

                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12">
                            <b>Step 4.</b> Status of Request
                        </div>
                    </div>

                    <!--Status-->
                    <div class="row" *ngIf='showFormAll'>
                        <div class="col-12 col-lg-4 row-30">
                            <div class="md-form">
                                <mdb-select formControlName="statusId" [options]="statusOptions"
                                    class="form-control-mdb">
                                </mdb-select>
                                <label>Status</label>
                            </div>
                        </div>
                    </div>

                     <!--Back Button-->
                     <div class="row" *ngIf='pageService.adminView'>
                        <div class="col-12 row-30">
                            <div class="md-form">
                                <button mdbBtn type="button" class="btn-segal" (click)="pageService.navigateBack()">
                                    <i class="fas fa-chevron-left" aria-hidden="true"></i> Administration
                                </button>
                            </div>
                        </div>
                    </div>

                </mdb-card-body>
            </mdb-card>

        </div>
    </div>

</form>