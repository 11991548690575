import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';

@Component({
  selector: 'app-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.scss']
})
export class DocumentManagementComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isSubmitted = false;
  isNewDocument = false;
  fileDropped = false;

  modalTitle!: string;

  docForm!: FormGroup;

  files: File[] = [];

  uploadedDocument: Subject<boolean> = new Subject<boolean>();

  constructor(
    public pageService: UserVaccinePageService,
    public modalRefDocument: MDBModalRef,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    this.docForm = this.formBuilder.group({
      documentName: ['', [Validators.required]],
      uploadedDate: [Date]
    });

    this.isNewDocument = this.pageService.vaccine.document.documentId === 0;

    const uploadedOn = this.pageService.vaccine.document.createdDt == null ? null : formatDate(this.pageService.vaccine.document.createdDt, 'MMM d, y', 'en');
    this.uploadedDate.setValue(uploadedOn);

    if (this.pageService.vaccine.document.fileName) {
      this.fileName.setValue(this.pageService.vaccine.document.fileName);
    }
  }

  get fileName(): any {
    return this.docForm.get('documentName');
  }

  get uploadedDate(): any {
    return this.docForm.get('uploadedDate');
  }

  onCancel() {
    this.uploadedDocument.next(false);
    this.modalRefDocument.hide();
  }


  public onSubmit() {

    this.isSubmitted = true;

    if (!this.docForm.valid) {
      return;
    }

    this.uploadedDocument.next(true);
    this.modalRefDocument.hide();
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onDropFile(event: any) {
    console.log(event);

    this.files.splice(0, 0, ...event.addedFiles);

    const fileName = this.files[0].name.replace("[\\\\/:*?\"<>|]", "_");

    this.pageService.vaccine.document.fileName = fileName;
    this.pageService.vaccine.document.documentTypeId = 1;

    const extension = fileName.split('.').pop();

    if (extension != undefined) {
      this.pageService.vaccine.document.fileExt = extension;
    }

    const uploadDate =  formatDate(new Date(), 'MMM d, y', 'en');

    this.uploadedDate.setValue(uploadDate);
    this.fileName.setValue( this.pageService.vaccine.document.fileName);

    this.pageService.vaccine.file = this.files[0];
    this.files = [];

    this.isNewDocument = false;
    this.fileDropped = true;
  }
}
