import { Component, OnInit } from '@angular/core';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaResourceEditComponent } from '../bia-resource-edit/bia-resource-edit.component';
import { BiaResourceListSortService } from './bia-resource-list-sort.service';

@Component({
  selector: 'app-bia-resource-list',
  templateUrl: './bia-resource-list.component.html',
  styleUrls: ['./bia-resource-list.component.scss'],
  providers: [ BIAEditStepListService, BiaResourceListSortService ]
})
export class BiaResourceListComponent implements OnInit, IBIAStepListComponent {

  isDirty = false;
  pageIndex = 4;

  saving = false;

  public listItems: Array<BIAResourceModel> = new Array<BIAResourceModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaResourceListSortService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaResources;
  }

  onAddItem() {
    let newItem = new BIAResourceModel();
    newItem.sortOrder = this.nextSortOrder();
    
    this.onOpenItem(newItem, -100);
  }

  onDeleteItem(item: BIAResourceModel, index: number) {

    this.pageService.confirmDeleteItem('system resource').subscribe(result => {

      if (result) {

        this.pageService.deleteBIAResource(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.bia.biaResources.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIAResourceModel, i: number): void {

    const mf = this.modalService.show(BiaResourceEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIAResourceModel) => {
        if (i == -100) {
          this.pageService.modelDto.bia.biaResources.push(updatedItem);
        }
        else {
          this.pageService.modelDto.bia.biaResources[i] = updatedItem;
        }

        this.listItems = this.pageService.modelDto.bia.biaResources;
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  private nextSortOrder(): number {

    let maxNo = 0;

    if (this.pageService.modelDto.bia.biaResources != null) {
      this.pageService.modelDto.bia.biaResources.forEach(item =>{
        if (item.sortOrder > maxNo) {
          maxNo = item.sortOrder;
        }
      });
    };

    return maxNo + 1;
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaResources);
  }
  
}
