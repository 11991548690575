<form [formGroup]="applicationForm" class="md-form">

  <div class='row'>
    <div class="col-12 col-lg-12">
      <div class="alert alert-danger" *ngIf="isSubmitted && (applicationForm?.invalid)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>
          Application details could not be saved. Please fix the errors below.
        </span>

        <ul>
          <li *ngIf="applicationForm?.get('appVendor')?.errors?.required">
            Missing the vendor of the application.
          </li>
          <li *ngIf="applicationForm?.get('appBu')?.errors?.required">
            Missing the business unit of application.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <!--Application Vendor-->
    <div class="col-12 col-lg-4">
      <div class="md-form">
        <mdb-select formControlName="appVendor" [options]="this.pageService.optionsAppVendor"
                    placeholder="Please Select"
                    [ngClass]="{'form-invalid':isSubmitted && applicationForm.get('appVendor')?.errors?.required}"
                    class="form-control-mdb">
        </mdb-select>
        <label>Application Vendor</label>
        <mdb-error *ngIf="isSubmitted && applicationForm.get('appVendor')?.errors?.required">
          Please select Application Vendor
        </mdb-error>
      </div>
    </div>

    <!--Application Tier-->
    <div class="col-12 col-lg-3">
      <div class="md-form">
        <mdb-select formControlName="appTier" [options]="this.pageService.optionsAppTier"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>Application Tier</label>
      </div>
    </div>
  </div>

  <div class="row">
    <!--Application Business Unit-->
    <div class="col-12 col-lg-4">
      <div class="md-form">
        <mdb-select formControlName="appBu" [options]="this.pageService.optionsAppBus"
                    placeholder="Please Select" (selected)="onAppBuChange()" class="form-control-mdb">
        </mdb-select>
        <label>Business Unit</label>
        <mdb-error *ngIf="isSubmitted && applicationForm.get('appBu')?.errors?.required">
          Please select Business Unit
        </mdb-error>
      </div>
    </div>

    <!--Software Category-->
    <div class="col-12 col-lg-3">
      <div class="md-form">
        <mdb-select formControlName="sftwCategory" [options]="this.pageService.optionsAppSftwCategory"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>Software Category</label>
      </div>
    </div>

    <!--Application Category-->
    <div class="col-12 col-lg-5">
      <div class="md-form">
        <mdb-select formControlName="appCategory" [options]="this.pageService.optionsAppCategory"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>Application Category</label>
      </div>
    </div>
  </div>

  <div class="row">
    <!--Available Via-->
    <div class="col-12 col-lg-4">
      <div class="md-form">
        <mdb-select formControlName="availableVia" [options]="this.pageService.optionsAppAvailableVia"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>Available Via</label>
      </div>
    </div>

    <!--Local or Cloud-->
    <div class="col-12 col-lg-3">
      <div class="md-form">
        <mdb-select formControlName="localCloud" [options]="this.pageService.optionsAppLoc"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>Local or Cloud</label>
      </div>
    </div>

    <!--IT Support Type-->
    <div class="col-12 col-lg-5">
      <div class="md-form">
        <mdb-select formControlName="itSupportType" [options]="this.pageService.optionsAppSupportType"
                    placeholder="Please Select" class="form-control-mdb">
        </mdb-select>
        <label>IT Support Type</label>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12">
      <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next" (click)="stepService.onNextStep()"
              mdbWavesEffect>
        NEXT
      </button>
      <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish" (click)="stepService.onSaveCloseForm()"
              mdbWavesEffect>
        FINISH LATER
      </button>
      <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save" (click)="stepService.onSaveForm()"
              mdbWavesEffect>
        SAVE
      </button>
      <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close" (click)="stepService.onCloseForm()"
              mdbWavesEffect>
        CLOSE
      </button>
    </div>
  </div>

</form>

