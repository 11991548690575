import { Injectable } from '@angular/core';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaRecoveryListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIAResourceModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
 
    if (columnName === 'sortOrder' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.sortOrder - b.sortOrder);

    } else if (columnName === 'sortOrder') {

      items.sort((a, b) => b.sortOrder - a.sortOrder);

    }

    else if (columnName === 'recoveryPriority' && this.sortDirection === 'ASC') {

      items.sort((a, b) => {
        const c = a.recoveryPriority == null ? '' : a.recoveryPriority.recoveryPriority;
        const d = b.recoveryPriority == null ? '' : b.recoveryPriority.recoveryPriority;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'recoveryPriority') {

      items.sort((a, b) => {
        const c = a.recoveryPriority == null ? '' : a.recoveryPriority.recoveryPriority;
        const d = b.recoveryPriority == null ? '' : b.recoveryPriority.recoveryPriority;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'resourceName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.resourceName.localeCompare(b.resourceName));

    } else if (columnName === 'resourceName') {

      items.sort((a, b) => b.resourceName.localeCompare(a.resourceName));

    }

    else if (columnName === 'rto' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.rto - b.rto);

    } else if (columnName === 'rto') {

      items.sort((a, b) => b.rto - a.rto);

    }
  }
}

