import { Injectable } from '@angular/core';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaDowntimeListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIABusinessProcessModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'processName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.processName.localeCompare(b.processName));

    } else if (columnName === 'processName') {

      items.sort((a, b) => b.processName.localeCompare(a.processName));

    }

    else if (columnName === 'mtdEstimate' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.mtdEstimate - b.mtdEstimate);

    } else if (columnName === 'mtdEstimate') {

      items.sort((a, b) => b.mtdEstimate - a.mtdEstimate);

    }

    else if (columnName === 'rtoEstimate' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.rtoEstimate - b.rtoEstimate);

    } else if (columnName === 'rtoEstimate') {

      items.sort((a, b) => b.rtoEstimate - a.rtoEstimate);

    }

    else if (columnName === 'rpoEstimate' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.rpoEstimate - b.rpoEstimate);

    } else if (columnName === 'rpoEstimate') {

      items.sort((a, b) => b.rpoEstimate - a.rpoEstimate);

    }
  }
}


