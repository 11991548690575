import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeModel } from '@app/_models/employee-model';
import { UserDtoJS } from '@app/_models/user-dto';
import { APP_CONFIG } from '@app/_services/config.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: UserDtoJS = new UserDtoJS();
  private _admin = false;

  public cached = false;

  constructor(
    private http: HttpClient) { }

  public get isAdmin(): boolean {
    return this._admin;
  }

  public get user(): UserDtoJS {
    if (this._user == null) {
      console.log("UserService: Missing user");
      return new UserDtoJS();
    }

    return this._user;
  }

  public set user(model: UserDtoJS) {
    this._user = model;
    this._admin = (model.userRole == null) ? false : model.userRole.roleTypeId == 1;
  }

  public isIT(user: EmployeeModel): boolean {
    if (user == null) {
      return false;
    }

    if (user.businessUnit == null) {
      return false;
    }

    return (user.businessUnit.toLowerCase() == 'information technology');
  }

  public GetUser(): Observable<UserDtoJS> {

    let requestURL = APP_CONFIG.apiDomain + '/datawarehouse/employee';
    //let requestURL = APP_CONFIG.apiDomain + '/DataWarehouse/employee/netLogin/dhan';

    return this.http
      .get<UserDtoJS>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public GetEmployeeList(): Observable<Array<EmployeeModel>> {
    return this.http
      .get<Array<EmployeeModel>>(
        APP_CONFIG.apiDomain + '/datawarehouse/Employees'
      )
      .pipe(
        e => e,
        publishReplay(1),
        refCount()
      );
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }

}
