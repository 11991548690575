import { ApplicationModel } from "@app/_models/application-model";
import { BIAContactTypeModel } from "./bia-contact-type-model";
import { BIAModel } from "./bia-model";
import { BIARecoveryPriorityModel } from "./bia-recovery-priority-model";
import { BIAResourceCategoryModel } from "./bia-resource-category-model";

export class BIADto {
    public bia: BIAModel = new BIAModel();
    public applications: Array<ApplicationModel> = new Array<ApplicationModel>();
    public recoveryPriorities: Array<BIARecoveryPriorityModel> = new Array<BIARecoveryPriorityModel>();
    public contactTypes: Array<BIAContactTypeModel> = new Array<BIAContactTypeModel>();
    public resourceCategories: Array<BIAResourceCategoryModel> = new Array<BIAResourceCategoryModel>();
}