export class BIADependencyModel {

    public dependencyId: number = 0;
    public biaId: number = 0;

    public applicationName: string = '';
    public applicationDesc: string = '';
    public backupPlan: boolean = true;
    public downtimeDesc: string = '';
    public dataAgeDesc: string = '';
    public otherRequirement: boolean = false;

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}

