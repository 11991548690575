<form [formGroup]="applicationForm" class="md-form">

  <div class="row">
    <!--Comments-->
    <div class="col-12 col-lg-12">
      <div class="md-form">
        <textarea type="text" placeholder="Enter ..."
                  class="md-textarea form-control auto-height" formControlName="appComments"
                  mdbInput style="min-height: 143px;"></textarea>
        <label>Comments</label>
      </div>
    </div>
  </div>

  <div class="row" style="padding-top: 25px;">
    <div class="col-12">
      <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
              (click)="stepService.onPriorStep()" mdbWavesEffect>
        PRIOR
      </button>
      <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Finish"
              (click)="stepService.onSaveCloseForm()" mdbWavesEffect>
        FINISH
      </button>
      <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
              (click)="stepService.onSaveCloseForm()" mdbWavesEffect>
        SAVE & FINISH
      </button>
      <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
              (click)="stepService.onCloseForm()" mdbWavesEffect>
        CLOSE
      </button>
    </div>
  </div>

</form>

