<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('functionName')?.errors?.required">
                                        Missing the function performed by the application.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Name -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="functionName" class="form-control"
                                    maxlength="500" />
                                <label>Function performed by the Application</label>
                            </div>
                        </div>
                    </div>

                    <!-- MEF and ESA -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="mefEsaSupport" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Which MEFs or ESAs does it support</label>
                            </div>
                        </div>
                    </div>

                    <!-- Deadline -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="deadlineDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Identify any Critical deadlines / time periods in the system Cycle</label>
                            </div>
                        </div>
                    </div>

                    <!-- Dependency -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="dependencyDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Critical Dependencies or resources needed</label>
                            </div>
                        </div>
                    </div>

                    <!-- Dependent On -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="dependentDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Identify any functions or systems that are dependent on this function</label>
                            </div>
                        </div>
                    </div>


                    <!-- Critical Time -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="criticalTimeDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Critical time of day / month / year / project</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>


        </app-spinner>
    </div>
</form>