<div class="row">

    <div class="col-12">

        <div class="table-responsive table-clickable">

            <table [small]="true" mdbTable>

                <thead>
                    <tr>
                        <th>Priority</th>
                        <th>Name</th>
                        <th>RTO (Hours)</th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor="let item of listItems; let i=index" (click)="onOpenItem(item, i)">

                        <td>
                            {{item.recoveryPriority == null ? '* Click to Set *' :
                            item.recoveryPriority.recoveryPriority}}
                        </td>
                        <td>{{item.resourceName}}</td>
                        <td>{{item.rto}}</td>
                    </tr>
                </tbody>

                <tfoot *ngIf="listItems?.length === 0">
                    <tr>
                        <td class="text-center no-results" colspan="5">No System Resources Available</td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>

</div>

<div class="row" style="padding-top: 25px;">
    <div class="col-12">
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
        <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>NEXT</button>
        <button type="button" mdbBtn class="btn-segal float-left" aria-label="Help" (click)="getHelpText()"
            mdbWavesEffect>HELP
            <mdb-icon fas icon="question-circle" class="ml-1"></mdb-icon>
        </button>
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>FINISH LATER</button>
        <!-- <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
            (click)="stepService.onSaveForm()" mdbWavesEffect>SAVE</button> -->
        <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
    </div>
</div>