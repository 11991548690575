<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('changeDesc')?.errors?.required">
                                        Missing the description of the change.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <!-- Change Description -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="changeDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Description of the Change</label>
                            </div>
                        </div>
                    </div>


                    <!-- Estimated Impact -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="impactDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Estimated Impact</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>

        </app-spinner>
    </div>
</form>