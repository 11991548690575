import { Injectable } from '@angular/core';
import { BIABusinessChangeModel } from '@app/bia/_models/bia-business-change-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BIABusinessChangeListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIABusinessChangeModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'changeDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.changeDesc.localeCompare(b.changeDesc));

    } else if (columnName === 'changeDesc') {

      items.sort((a, b) => b.changeDesc.localeCompare(a.changeDesc));

    }

    else if (columnName === 'impactDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.impactDesc.localeCompare(b.impactDesc));

    } else if (columnName === 'impactDesc') {

      items.sort((a, b) => b.impactDesc.localeCompare(a.impactDesc));

    }
  }
}
