<div *ngIf="loading" class="spinner-border text-info segal-spinner full-page" role="status"></div>

<div *ngIf="!loading && showVaccineCard()">
    <app-vaccine-card #vaccineCard></app-vaccine-card>
</div>

<div *ngIf="!loading && showAccommodation()">
    <app-accommodation #accommodation></app-accommodation>
</div>

<div *ngIf="!loading && showUnvaccinated()">
    <app-unvaccinated #unvaccinated></app-unvaccinated>
</div>

<app-copyright [loading]=loading></app-copyright>
