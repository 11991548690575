import { AppContactTypeModel } from "./app-contact-type-model";
import { EmployeeModel } from "./employee-model";

export class AppContactModel {
    public appContactId: number = 0;
    public appId: number = 0;
    public appName: string = '';
    public contactTypeId: number = 0;
    public empId: string = '';
    public contactEmail: string = '';
    public isActive: boolean = true;
    public sortOrder: number = 0;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

    public employee: EmployeeModel = new EmployeeModel();
    public contactType: AppContactTypeModel = new AppContactTypeModel();
}
