import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { LookupValue } from '@app/_models/lookup-value';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-recovery-edit',
  templateUrl: './bia-recovery-edit.component.html',
  styleUrls: ['./bia-recovery-edit.component.scss']
})
export class BiaRecoveryEditComponent implements OnInit {

  paramItem: BIAResourceModel = new BIAResourceModel();
  onUpdateItem: Subject<BIAResourceModel> = new Subject<BIAResourceModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Recovery Priority';
  typeLabel = 'Type';

  biaForm!: FormGroup;

  optionsPriority: Array<any> = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      priorityType: ['', Validators.required],
      resourceName: [''],
      rto: ['', Validators.required],
      rtoDesc: [''],
    });

    this.loadPriorityList();
    this.loadPage();
  }

  get priorityType(): any {
    return this.biaForm.get('priorityType');
  }

  get resourceName(): any {
    return this.biaForm.get('resourceName');
  }

  get rto(): any {
    return this.biaForm.get('rto');
  }

  get rtoDesc(): any {
    return this.biaForm.get('rtoDesc');
  }

  loadPriorityList(): void {
    this.pageService.modelDto.recoveryPriorities.forEach(item => {
      this.optionsPriority.push(new LookupValue(item.recoveryPriorityId.toString(), item.recoveryPriority))
    });
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    if (this.paramItem.recoveryPriorityId != null) {
      this.priorityType.setValue(this.paramItem.recoveryPriorityId.toString());
    }
    
    this.resourceName.setValue(this.paramItem.resourceName);
    this.rto.setValue(this.paramItem.rto);
    this.rtoDesc.setValue(this.paramItem.rtodesc);
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.recoveryPriorityId = +this.priorityType.value;
    this.paramItem.rto = +this.rto.value;
    this.paramItem.rtodesc = this.rtoDesc.value;

    this.pageService.modelDto.recoveryPriorities.forEach(item => {
      if (item.recoveryPriorityId == this.paramItem.recoveryPriorityId) {
        this.paramItem.recoveryPriority = item;
      }
    });

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}

