import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LogoutComponent } from './_components/logout/logout.component';
import { AppListComponent } from './_components/app-list/app-list.component';
import { AppEditComponent } from './_components/app-edit/app-edit.component';
import { AppDocumentListComponent } from './_components/app-document-list/app-document-list.component';
import { BiaListComponent } from './bia/_components/bia-list/bia-list.component';
import { UserRoleListComponent } from './_components/user-role-list/user-role-list.component';
import { BiaDocumentListComponent } from './bia/_components/bia-document-list/bia-document-list.component';
import { AdminGuard } from './_services/admin.guard';
import { BiaEditComponent } from './bia/_components/bia-edit/bia-edit.component';

const routes: Routes = [
  {
    path: 'home',
    component: BiaListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bia',
    component: BiaEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bia-list',
    component: BiaListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bia-document-list',
    component: BiaDocumentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'user-role-list',
    component: UserRoleListComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  {
    path: 'app-list',
    component: AppListComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  {
    path: 'app-edit',
    component: AppEditComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  {
    path: 'app-document-list',
    component: AppDocumentListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },

  {
    path: 'implicit/callback',
    component: BiaListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'auth',
    component: MsalRedirectComponent
  },

  {
    // Needed for hash routing
    path: 'error',
    component: BiaListComponent,
  },
  {
    // Needed for hash routing
    path: 'state',
    component: BiaListComponent,
  },
  {
    // Needed for hash routing
    path: 'code',
    component: BiaListComponent,
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
