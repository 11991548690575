import { Injectable } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { BIABackupStrategyModel } from '@app/bia/_models/bia-backup-strategy-model';
import { IBIAStepComponent } from '@app/_models/ibiastep-component';
import { BIABusinessChangeModel } from '@app/bia/_models/bia-business-change-model';
import { BIABusinessFunctionModel } from '@app/bia/_models/bia-business-function-model';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { BIAContactModel } from '@app/bia/_models/bia-contact-model';
import { BIADependencyModel } from '@app/bia/_models/bia-dependency-model';
import { BIADto } from '@app/bia/_models/bia-dto';
import { BIAModel } from '@app/bia/_models/bia-model';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { BIAService } from '@app/bia/_services/bia.service';
import { ConfirmModalComponent } from '@app/_components/confirm-modal/confirm-modal.component';
import { UserService } from '@app/_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class BiaListPageService {

  public static STEP_COUNT = 13;

  private _cachedList = false;
  private _listByUser = true;

  private _modelList = new Array<BIAModel>();
  private _modelDto: BIADto = new BIADto();

  private _showStep: Array<boolean> = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);
  private _showForm: Array<boolean> = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);
  private _steps: Array<IBIAStepComponent> = new Array<IBIAStepComponent>(BiaListPageService.STEP_COUNT);

  public viewTypeId = 1;

  public editMode = false;
  public stepMode = false;
  public viewMode = false;

  constructor(
    private biaService: BIAService,
    private userService: UserService,
    private modalService: MDBModalService,
    private location: Location) {
  }

  public get modelDto(): BIADto {
    return this._modelDto;
  }

  public get modelList(): Array<BIAModel> {
    return this._modelList;
  }

  public registerStep(index: number, step: IBIAStepComponent): void {
    this._steps[index - 1] = step;
    this._steps[index - 1].loadPage();
  }

  public resetModel() {

    this._modelDto = new BIADto();

    this.viewTypeId = 1;

    this._showStep = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);
    this._showForm = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);

    this.editMode = false;
    this.stepMode = false;
    this.viewMode = false;
  }

  public setEditMode(): void {
    this.stepMode = false;
    this.editMode = true;
    this.viewMode = false;

    this.showAllSteps();
  }

  public setStepMode(): void {
    this.stepMode = true;
    this.editMode = false;
    this.viewMode = false;

    this.hideAllSteps();

    this.onSetViewType(this._modelDto.bia.stepId);
  }

  public showAllSteps() {
    this._showStep = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(true);
    this._showForm = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(true);
  }

  public hideAllSteps() {
    this._showStep = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);
    this._showForm = new Array<boolean>(BiaListPageService.STEP_COUNT).fill(false);
  }

  public onSetViewType(viewTypeId: number) {

    this.viewTypeId = viewTypeId;

    for (let index = 0; index < this._showForm.length; index++) {
      this._showForm[index] = (viewTypeId - 1 == index);
      this._showStep[index] = (index <= this.modelDto.bia.stepId - 1);
    }

    this._showStep[viewTypeId - 1] = true;

    if (this._steps[viewTypeId - 1] != null) {
      this._steps[viewTypeId - 1].loadPage();
    }
  }

  public showStepLabel(stepId: number) {
    return this._showStep[stepId - 1];
  }

  public showForm(stepId: number) {
    return this._showForm[stepId - 1];
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getBia(biaId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      this.biaService.getBIA(biaId).subscribe((biaDto) => {

        this._modelDto = biaDto;

        observer.next(true);
      });

    });

    return result;
  }

  public isListByUser(): boolean {
    return this._listByUser;
  }

  public setListByUser() {
    this._listByUser = true;
    this._cachedList = false;
  }

  public setListAll() {
    this._listByUser = false;
    this._cachedList = false;
  }

  public getList(): Observable<ServiceResultModel> {

    if (this._listByUser) {
      return this.getListByUser();
    }

    return this.getListAll();
  }

  public userFullName(): string {
    return this.userService.user.employee.fullName;
  }

  public isAdministrator(): boolean {
    return this.userService.isAdmin;
  }

  private getListAll(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.biaService.getBIAList().subscribe((list) => {

          this._modelList = list;
          this._cachedList = true;

          this.buildListFields();

          observer.next(this.getDataServiceResult(10, '', null));
        },
          (error: string) => {
            observer.next(this.getDataServiceResult(-10, error, null));
          }
        );

      }

    });

    return result;
  }

  private getListByUser(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.userService.GetUser().subscribe(user => {

          this.userService.user = user;

          this.biaService.getBIAListByUser(user.employee.empId).subscribe((list) => {

            this._modelList = list;
            this._cachedList = true;

            this.buildListFields();

            observer.next(this.getDataServiceResult(10, '', null));
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        });

      }

    });

    return result;
  }

  private buildListFields() {

    this._modelList.forEach(item => {

      if (item.biaBusinessProcess.length > 0) {

        let appList = '';
        let buList = '';
        let biaContactList = '';
        
        item.biaBusinessProcess.forEach(p => {

          let contactList = '';
          
          if (p.application != null) {

            let appName = p.application.appName
            appList = (appList.length == 0) ? appName : appList + ', ' + appName;

            if (p.application.businessUnit != null) {

              let group = p.application.businessUnit.userGroupName;
              buList = (buList.length == 0) ? group : buList + ', ' + group;
            }

            if (p.application.appContacts != null) {

              p.application.appContacts.forEach(c => {

                if (c.employee != null) {
                  contactList = (contactList.length == 0) ? c.employee.fullName : contactList + ', ' + c.employee.fullName;
                }
                
              });

              p.contactList = contactList;

            }            
          }
          
          biaContactList = (biaContactList.length == 0) ? contactList : biaContactList + ', ' + contactList
        })

        item.biaApplicationList = appList;
        item.biaBusinessUnitList = buList;
        item.biaContactList = biaContactList;
      }
    });
  }

  public saveBia(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.saveBIA(this._modelDto.bia).subscribe(b => {

        this._modelDto.bia = b;

        this.refeshList();

        observer.next(this.getDataServiceResult(100, '', b));
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-100, error, null));
        }
      );

    });

    return result;
  }

  private refeshList() {
    this._cachedList = false;
    this.getList();
  }

  public deleteBia(biaItem: BIAModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.deleteBIA(biaItem).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(200, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-200, 'API booster delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public deleteBIAProcess(model: BIABusinessProcessModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'process');
  }

  public deleteBIAResource(model: BIAResourceModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'resource');
  }

  public deleteBIAContact(model: BIAContactModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'contact');
  }

  public deleteBIABusinessFunction(model: BIABusinessFunctionModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'function');
  }

  public deleteBiaBackupStrategy(model: BIABackupStrategyModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'backup');
  }

  public deleteBIABusinessChange(model: BIABusinessChangeModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'change');
  }

  public deleteBIADependency(model: BIADependencyModel): Observable<ServiceResultModel> {
    return this.deleteBIAItem(model, 'dependency');
  }

  private deleteBIAItem(biaItem: any, biaItemType: string): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.deleteBIAItem(biaItem, biaItemType).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(200, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-200, 'API ' + biaItemType + ' delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public confirmSaveChanges(): Observable<boolean> {
    return this.confirmPrompt('Changes have been made. Would you like to save your changes?');
  }

  public confirmDeleteBia(): Observable<boolean> {
    return this.confirmPrompt('Are you sure you want to remove this business impact analysis? ');
  }

  public confirmDeleteItem(itemType: string): Observable<boolean> {
    return this.confirmPrompt('Are you sure you want to remove this ' + itemType + '? ');
  }

  private confirmPrompt(message: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let modal = this.modalService.show(ConfirmModalComponent, {
        class: 'modal-md',
        data: {
          title: 'Please Confirm',
          body: message
        },
      });

      modal.content.dialogResult.subscribe(
        (dialogResult: DialogResult) => {
          if (dialogResult.action.toLowerCase() === 'confirm') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }
      );

    });

    return result;
  }

  private getDataServiceResult(codeId: number, error: string, result: any): ServiceResultModel {

    let typeName = 'Business impact analysis';

    if (codeId == -10) { return new ServiceResultModel(codeId, typeName + ' list retrieval error: ' + error, result) };
    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 10) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully retrieved', result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }

}
