import { Component, OnInit } from '@angular/core';
import { BIABusinessFunctionModel } from '@app/bia/_models/bia-business-function-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaBusinessFunctionEditComponent } from '../bia-business-function-edit/bia-business-function-edit.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaBusinessFunctionListSortService } from './bia-business-function-list-sort.service';

@Component({
  selector: 'app-bia-business-function-list',
  templateUrl: './bia-business-function-list.component.html',
  styleUrls: ['./bia-business-function-list.component.scss'],
  providers: [ BIAEditStepListService, BiaBusinessFunctionListSortService ]
})
export class BiaBusinessFunctionListComponent implements OnInit, IBIAStepListComponent {

  isDirty = false;
  pageIndex = 7;

  saving = false;

  public listItems: Array<BIABusinessFunctionModel> = new Array<BIABusinessFunctionModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaBusinessFunctionListSortService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaBusinessFunction;
  }

  onAddItem() {
    this.onOpenItem(new BIABusinessFunctionModel(), -100);
  }

  onDeleteItem(item: BIABusinessFunctionModel, index: number) {

    this.pageService.confirmDeleteItem('critical business process').subscribe(result => {

      if (result) {

        this.pageService.deleteBIABusinessFunction(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.bia.biaBusinessFunction.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIABusinessFunctionModel, i: number): void {

    const mf = this.modalService.show(BiaBusinessFunctionEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIABusinessFunctionModel) => {
        if (i == -100) {
          this.pageService.modelDto.bia.biaBusinessFunction.push(updatedItem);
        }
        else {
          this.pageService.modelDto.bia.biaBusinessFunction[i] = updatedItem;
        }
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaBusinessFunction);
  }
}
