import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccommodationComponent } from '../accommodation/accommodation.component';
import { VaccineCardComponent } from '../vaccine-card/vaccine-card.component';
import { UnvaccinatedComponent } from '../unvaccinated/unvaccinated.component';
import { BoosterCardComponent } from '../booster-card/booster-card.component';

@Component({
  selector: 'app-user-vaccine',
  templateUrl: './user-vaccine.component.html',
  styleUrls: ['./user-vaccine.component.scss']
})
export class UserVaccineComponent implements OnInit {

  @ViewChild('vaccineCard')
  vaccineCard!: VaccineCardComponent;

  @ViewChild('booster')
  booster!: BoosterCardComponent;

  @ViewChild('accommodation')
  accommodation!: AccommodationComponent;

  @ViewChild('unvaccinated')
  unvaccinated!: UnvaccinatedComponent;

  viewTypeId = 1;
  loading = false;
  loggedIn = false;

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {
      if (params.viewId != undefined) {
        this.viewTypeId = +params.viewId;
      }
    });
  }

  showVaccineCard(): boolean {
    return this.viewTypeId == 1;
  }

  showAccommodation(): boolean {
    return this.viewTypeId == 2;
  }

  showUnvaccinated(): boolean {
    return this.viewTypeId == 3;
  }

}