import { Injectable } from '@angular/core';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaResourceListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIAResourceModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'resourceTypeName1' && this.sortDirection === 'ASC') {

      items.sort((a, b) => {
        const c = a.resourceCategory == null ? '' : a.resourceCategory.resourceTypeName;
        const d = b.resourceCategory == null ? '' : b.resourceCategory.resourceTypeName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'resourceTypeName1') {

      items.sort((a, b) => {
        const c = a.resourceCategory == null ? '' : a.resourceCategory.resourceTypeName;
        const d = b.resourceCategory == null ? '' : b.resourceCategory.resourceTypeName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'resourceName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.resourceName.localeCompare(b.resourceName));

    } else if (columnName === 'resourceName') {

      items.sort((a, b) => b.resourceName.localeCompare(a.resourceName));

    }

    else if (columnName === 'resourceTypeName2' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.resourceTypeName.localeCompare(b.resourceTypeName));

    } else if (columnName === 'resourceTypeName2') {

      items.sort((a, b) => b.resourceTypeName.localeCompare(a.resourceTypeName));

    }

    else if (columnName === 'resourceDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.resourceDesc.localeCompare(b.resourceDesc));

    } else if (columnName === 'resourceDesc') {

      items.sort((a, b) => b.resourceDesc.localeCompare(a.resourceDesc));

    }

  }
}


