import { Injectable, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ConfigService, IAppConfig } from './config.service';
import { environment } from '@environments/environment';

export function preInitServiceFactory(
  initService: PreInitService,
  injector: Injector,
) {
  return () =>
    new Promise(resolve =>
      injector
        .get(LOCATION_INITIALIZED, Promise.resolve(undefined))
        .then(() => resolve(initService.onInit())),
    );
}

@Injectable({
  providedIn: 'root'
})
export class PreInitService {
  constructor(private httpBackend: HttpBackend, private configService: ConfigService) { }

  onInit(): Promise<boolean>{
    const http = new HttpClient(this.httpBackend);

    return http.get<IAppConfig>(environment.configFileName)
      .toPromise()
      .then(config => this.configService.setConfig(config))
      .then(() => console.log('config.json loaded'))
      .then(_ => true)
      .catch(error => {
        console.error('Error loading application configuration', error);
        return Promise.resolve(false);
      });
  }
}
