import { Injectable } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

import { IAppStepComponent } from '@app/_models/iappstep-component';



import { AppContactModel } from '@app/_models/app-contact-model';

import { ApplicationDto } from '@app/_models/application-dto';
import { ApplicationModel } from '@app/_models/application-model';

import { ApplicationService } from '@app/_services/application.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { UserService } from '@app/_services/user.service';

import { LookupValue } from '@app/_models/lookup-value';
import { EmployeeModel } from '@app/_models/employee-model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppListPageService {

  public static STEP_COUNT = 4;

  private _cachedList = false;
  private _listByUser = true;

  private _modelList = new Array<ApplicationModel>();
  private _modelDto: ApplicationDto = new ApplicationDto();

  public _showStep: Array<boolean> = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);
  public _showForm: Array<boolean> = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);
  public _steps: Array<IAppStepComponent> = new Array<IAppStepComponent>(AppListPageService.STEP_COUNT);

  public viewTypeId = 1;

  public editMode = false;
  public stepMode = false;
  public viewMode = false;

  public filteredUser = new EmployeeModel();

  public optionsAppVendor: Array<any> = new Array<any>();
  public optionsAppTier: Array<any> = new Array<any>();
  public optionsAppBus: Array<any> = new Array<any>();

  public optionsAppAvailableVia: Array<any> = new Array<any>();
  public optionsAppCategory: Array<any> = new Array<any>();
  public optionsAppSftwCategory: Array<any> = new Array<any>();
  public optionsAppSupportType: Array<any> = new Array<any>();
  public optionsAppLoc: Array<any> = new Array<any>();

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private userService: UserService,
    private modalService: MDBModalService,
    private location: Location) {
  }

  public get modelDto(): ApplicationDto {
    return this._modelDto;
  }

  public get modelList(): Array<ApplicationModel> {
    return this._modelList;
  }

  public registerStep(index: number, step: IAppStepComponent): void {
    this._steps[index - 1] = step;
    this._steps[index - 1].loadPage();
  }

  public resetModel() {
    this._modelDto = new ApplicationDto();

    this.viewTypeId = 1;

    this._showStep = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);
    this._showForm = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);

    this.editMode = false;
    this.stepMode = false;
    this.viewMode = false;
  }

  public setEditMode(): void {
    this.stepMode = false;
    this.editMode = true;
    this.viewMode = false;

    this.showAllSteps();
  }

  public setStepMode(): void {
    this.stepMode = true;
    this.editMode = false;
    this.viewMode = false;

    this.hideAllSteps();

    this.onSetViewType(this._modelDto.application.stepId);
  }

  public showAllSteps() {
    this._showStep = new Array<boolean>(AppListPageService.STEP_COUNT).fill(true);
    this._showForm = new Array<boolean>(AppListPageService.STEP_COUNT).fill(true);
  }

  public hideAllSteps() {
    this._showStep = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);
    this._showForm = new Array<boolean>(AppListPageService.STEP_COUNT).fill(false);
  }

  public onSetViewType(viewTypeId: number) {

    this.viewTypeId = viewTypeId;

    for (let index = 0; index < this._showForm.length; index++) {
      this._showForm[index] = (viewTypeId - 1 == index);
      this._showStep[index] = (index <= this.modelDto.application.stepId - 1);
    }

    this._showStep[viewTypeId - 1] = true;

    if (this._steps[viewTypeId - 1] != null) {
      this._steps[viewTypeId - 1].loadPage();
    }
  }

  public showStepLabel(stepId: number) {
    return this._showStep[stepId - 1];
  }

  public showForm(stepId: number) {
    return this._showForm[stepId - 1];
  }

  public navigateBack(): void {
    //this.location.back();
    this.router.navigate(['app-list']);
  }

  public loadAppVendorList(): void {
    this.modelDto.appVendors.forEach(item => {
      this.optionsAppVendor.push(new LookupValue(item.appVendorId.toString(), item.appVendor))
    });
  }

  public loadAppTierList(): void {
    this.modelDto.appTiers.forEach(item => {
      this.optionsAppTier.push(new LookupValue(item.tierId.toString(), item.tierDesc))
    });
  }

  public loadAppBUsList(): void {
    this.modelDto.businessUnits.forEach(item => {
      this.optionsAppBus.push(new LookupValue(item.unt.toString(), item.userGroupName))
    });
  }

  public loadAppSftwCategoryList(): void {
    this.modelDto.appSftwCategory.forEach(item => {
      this.optionsAppSftwCategory.push(new LookupValue(item.appScatId.toString(), item.sftwCategoryName))
    });
  }

  public loadAppCategoryList(): void {
    this.modelDto.appCategory.forEach(item => {
      this.optionsAppCategory.push(new LookupValue(item.appCatId.toString(), item.categoryName))
    });
  }

  public loadAppAvailableViaList(): void {
    this.modelDto.appAvailableVia.forEach(item => {
      this.optionsAppAvailableVia.push(new LookupValue(item.availableViaId.toString(), item.availableVia))
    });
  }

  public loadAppItSupportTypeList(): void {
    this.modelDto.appItSupportTypes.forEach(item => {
      this.optionsAppSupportType.push(new LookupValue(item.appSupTypeId.toString(), item.itSupportType))
    });
  }

  public loadAppLocationsList(): void {
    this.modelDto.appLocations.forEach(item => {
      this.optionsAppLoc.push(new LookupValue(item.appLocId.toString(), item.localCloud))
    });
  }

  public getApplication(appId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      this.applicationService.getApplication(appId).subscribe((ApplicationDto) => {

        this._modelDto = ApplicationDto;

        observer.next(true);
      });

    });

    return result;
  }

  public isListByUser(): boolean {
    return this._listByUser;
  }

  public setListByUser() {
    this._listByUser = true;
    this._cachedList = false;
  }

  public setListAll() {
    this._listByUser = false;
    this._cachedList = false;
  }

  public getList(): Observable<ServiceResultModel> {

    if (this._listByUser) {
      return this.getListAll();
      //return this.getListByUser();
    }

    return this.getListAll();
  }

  public userFullName(): string {
    return this.userService.user.employee.fullName;
  }

  public isAdministrator(): boolean {
    return this.userService.isAdmin;
  }

  public getListAll(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.applicationService.getApplicationList().subscribe((list) => {

          this._modelList = list;
          this._cachedList = true;

          this.buildListFields();

          observer.next(this.getDataServiceResult(10, '', null));
        },
          (error: string) => {
            observer.next(this.getDataServiceResult(-10, error, null));
          }
        );

      }

    });

    return result;
  }

  private getListByUser(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.userService.GetUser().subscribe(user => {

          //this.filteredUser = user;

          //this.filteredUser = this.userService.user;

          //this.applicationService.getAppListByUser(user.empId).subscribe((list) => {

          //  this._modelList = list;
          //  this._cachedList = true;

          //  this.buildListFields();

          //  observer.next(this.getDataServiceResult(10, '', null));
          //},
          //  (error: string) => {
          //    observer.next(this.getDataServiceResult(-10, error, null));
          //  }
          //);

        });

      }

    });

    return result;
  }

  public buildListFields() {

    this._modelList.forEach(item => {

      if (item.appContacts.length > 0) {

        let ownerList = '';
        let contactList = '';

        item.appContacts.forEach(c => {

          if (c.employee != null && c.employee.fullName != null) {
            if ((c.contactTypeId == 10 || c.contactTypeId == 11)) {
              ownerList = (ownerList.length == 0) ? c.employee.fullName : ownerList + ', ' + c.employee.fullName;
            } else {
              contactList = (contactList.length == 0) ? c.employee.fullName : contactList + ', ' + c.employee.fullName;
            }
          }
        });

        item.appOwnerList = ownerList;
        item.appContactList = contactList;
      }
    });
  }

  public saveApplication(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.applicationService.saveApplication(this._modelDto.application).subscribe(a => {

        this._modelDto.application = a;

        this.refeshList();

        observer.next(this.getDataServiceResult(100, '', a));
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-100, error, null));
        }
      );

    });

    return result;
  }

  public refeshList() {
    this._cachedList = false;
    this.getList();
  }

  public deleteApplication(applicationItem: ApplicationModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.applicationService.deleteApplication(applicationItem).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(200, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-200, 'API booster delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public deleteAppContact(model: AppContactModel): Observable<ServiceResultModel> {
    return this.deleteAppItem(model, 'contact');
  }

  public deleteAppItem(appItem: any, appItemType: string): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.applicationService.deleteAppItem(appItem, appItemType).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(200, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-200, 'API ' + appItemType + ' delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public confirmSaveChanges(): Observable<boolean> {
    return this.confirmPrompt('Changes have been made. Would you like to save your changes?');
  }

  public confirmDeleteApp(): Observable<boolean> {
    return this.confirmPrompt('Are you sure you want to remove this application? ');
  }

  public confirmDeleteItem(itemType: string): Observable<boolean> {
    return this.confirmPrompt('Are you sure you want to remove this ' + itemType + '? ');
  }

  public confirmPrompt(message: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let modal = this.modalService.show(ConfirmModalComponent, {
        class: 'modal-md',
        data: {
          title: 'Please Confirm',
          body: message
        },
      });

      modal.content.dialogResult.subscribe(
        (dialogResult: DialogResult) => {
          if (dialogResult.action.toLowerCase() === 'confirm') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }
      );

    });

    return result;
  }

  public getDataServiceResult(codeId: number, error: string, result: any): ServiceResultModel {

    let typeName = 'Application Inventory';

    if (codeId == -10) { return new ServiceResultModel(codeId, typeName + ' list retrieval error: ' + error, result) };
    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 10) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully retrieved', result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }


}
