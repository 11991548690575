import { Component, OnInit } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { MultipleChoiceOption } from '@app/_models/multiple-choice-option-model';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  title!: string;
  body!: string;

  selectedItem!: MultipleChoiceOption;

  confirmAction: any = { label: 'Yes', Enabled: true  };
  cancelAction: any = { label: 'No', Enabled: true };

  multipleChoiceOptions: Array<MultipleChoiceOption> = new Array<MultipleChoiceOption>();

  dialogResult: Subject<DialogResult> = new Subject<DialogResult>();
  showMultipleChoiceOptions = false;

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit() {

    if (this.confirmAction.Enabled === undefined) {
      this.confirmAction.Enabled = true;
    }

    if (this.cancelAction.Enabled === undefined) {
      this.cancelAction.Enabled = true;
    }

    this.checkRequired();
    if (this.multipleChoiceOptions.length !== 0) {

      this.showMultipleChoiceOptions = true;
      
      let option = this.multipleChoiceOptions.find(r => r.id === 1);

      if (option !== undefined) {
        this.selectedItem = option;
      }      
    }
  }

  checkRequired() {
    if (!this.title || !this.body) {
      throw new TypeError('A title and body are required');
    }
  }

  onChoiceChange(item: MultipleChoiceOption) {
    this.selectedItem = item;
  }

  onConfirm(event: any) {
    const r: DialogResult = new DialogResult();
    r.action = 'confirm';

    if (this.showMultipleChoiceOptions) {
      r.multipleChoiceResult = this.selectedItem;
    }

    this.dialogResult.next(r);
    this.modalRef.hide();
  }

  onCancel(event: any) {
    const r: DialogResult = new DialogResult();
    r.action = 'cancel';
    this.dialogResult.next(r);
    this.modalRef.hide();
  }
}
