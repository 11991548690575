export class ServiceResultModel {

    public statusId: number = 0;
    public message: string = '';
    public result: any;

    constructor(statusId: number, message: string, result: any) {
        this.statusId = statusId;
        this.message = message;
        this.result = result;
    }
}
