import { UserScreenModel } from "./user-screen-model";

export class OfficeScreenModel {

    public screenOfficeId: number = 0;
    public officeId: string = '0';
    public officeNm: string = '';
    public questionSetId: number = 0;

    public userScreen: UserScreenModel = new UserScreenModel()
}
