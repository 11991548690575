import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAppStepComponent } from '@app/_models/iappstep-component';
import { AppBasicInfoComponent } from '../app-basic-info/app-basic-info.component';
import { AppAdvanceInfoComponent } from '../app-advance-info/app-advance-info.component';
import { AppContactListComponent } from '../app-contact-list/app-contact-list.component';
import { AppCommentsComponent } from '../app-comments/app-comments.component';
import { AppListPageService } from '../app-list/app-list-page.service';

@Component({
  selector: 'app-app-edit',
  templateUrl: './app-edit.component.html',
  styleUrls: ['./app-edit.component.scss']
})
export class AppEditComponent implements OnInit, AfterViewInit  {

  @ViewChild('appBasicInfo')
  appBasicInfo!: AppBasicInfoComponent;

  @ViewChild('appAdvanceInfo')
  appAdvanceInfo!: AppAdvanceInfoComponent;

  @ViewChild('appContact')
  appContact!: AppContactListComponent;

  @ViewChild('appComment')
  appComment!: AppCommentsComponent;

  loading = false;

  appTitle = ''

  toastMessageOptions = { positionClass: 'md-toast-top-center' };
  
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    public pageService: AppListPageService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {

      if (params.appId != undefined) {

        const appId = +params.appId;
        this.loading = true;

        this.pageService.resetModel();
      
        this.pageService.getApplication(appId).subscribe(result => {

          if (result) {               
            
            this.appTitle = (this.pageService.modelDto.application.appId == 0) ? 'New Application' : this.pageService.modelDto.application.appName;

            if (this.pageService.modelDto.application.stepId > 0) {
              this.pageService.setStepMode();
            } else {
              this.pageService.setEditMode();
            }
          }
          
          this.loading = false; 
        });
      }
    });  

  }

  ngAfterViewInit() {
    // this.loadAllSteps();    
  }  

  onShowDocuments(): void {
    this.router.navigate(['app-document-list'], { queryParams: { appId: this.pageService.modelDto.application.appId } });
  }

  onNavigateList(): void {
    this.router.navigate(['app-list']);
  }

  private loadAllSteps(): void {
    for (let index = 1; index <= AppListPageService.STEP_COUNT; index++) {
      this.loadStep(index);
      
    }
  }

  private loadStep(index: number): void {
    let step = this.activeStep(index);

    if (step == undefined){
      return;
    }

    step.loadPage();
  }

  private activeStep(index: number): IAppStepComponent {

    switch (index) {
      case 1: return this.appBasicInfo;
      case 2: return this.appAdvanceInfo;
      case 3: return this.appContact;
      case 4: return this.appComment;
    }

    return this.appBasicInfo;
  }
  
}
