
<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Business
                                    Process Details
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('processName')?.errors?.required">
                                        Missing the business process name.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <!-- Name -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="processName"
                                    maxlength="100" />
                                <label>Name</label>
                            </div>
                        </div>
                    </div>

                    <!-- Application -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <mdb-select formControlName="processApplication" [options]="optionsApplication"
                                    placeholder="Please Select" class="form-control-mdb">
                                </mdb-select>
                                <label>Application<span *ngIf="'isSubmitted === false'"> *</span></label>
                            </div>
                        </div>
                    </div>

                    <!-- Impact Category -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <mdb-select formControlName="impactCategory" [options]="optionsImpact"
                                    placeholder="Please Select" class="form-control-mdb">
                                </mdb-select>
                                <label>Outage Impact Category<span *ngIf="'isSubmitted === false'"> *</span></label>
                            </div>
                        </div>
                    </div>


                    <!-- Description -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" formControlName="processDesc" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Outage Impact Description</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close"
                    (click)="onCancel()" mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>

            
        </app-spinner>
    </div>
</form>

