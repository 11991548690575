import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '@app/_services/config.service';
import { UserVaccineAdminDto } from '../_models/user-vaccine-admin-dto';
import { UserVaccineModel } from '../_models/user-vaccine-model';

@Injectable({
  providedIn: 'root'
})
export class UserVaccineAdminService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  public getVaccineAdminList(): Observable<UserVaccineAdminDto> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccineadmin/list';

    return this.http
      .get<UserVaccineAdminDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }  

  public updateVaccineStatus(model: UserVaccineModel): Observable<UserVaccineModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/uservaccineadmin/status';

    return this.http.post<UserVaccineModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public getBoosterFile(boosterId: number, fileName: string): void{

    this.downloadBoosterFile(boosterId)
      .subscribe((blob) => {
        var a = document.createElement("a");
        a.target = '_blank';

        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        a.click();
      });
  }

  public getVaccineFile(vaccineId: number, fileName: string): void{

    this.downloadVaccineFile(vaccineId)
      .subscribe((blob) => {
        var a = document.createElement("a");
        a.target = '_blank';

        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        a.click();
      });
  }

  private downloadBoosterFile(boosterId: number): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccineadmin/download2/' + boosterId.toString();

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private downloadVaccineFile(vaccineId: number): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccineadmin/download/' + vaccineId.toString();

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}

