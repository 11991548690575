import { Injectable } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { BiaDocumentService } from '@app/bia/_services/bia-document.service';
import { BiaFileUploadComponent } from '../bia-file-upload/bia-file-upload.component';
import { BIADocumentDto } from '@app/bia/_models/bia-document-dto';
import { BIADocumentModel } from '@app/bia/_models/bia-document-model';
import { ConfirmModalComponent } from '@app/_components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class BiaDocumentPageService {

  public biaId: number = 0;

  private _modelList = new Array<BIADocumentModel>();
  private _modelDto = new BIADocumentDto();

  private _cachedList = false;

  constructor(
    private biaService: BiaDocumentService,
    private modalService: MDBModalService,
    private location: Location) {
  }

  public get modelDto(): BIADocumentDto {
    return this._modelDto;
  }

  public get modelList(): Array<BIADocumentModel> {
    return this._modelList;
  }

  public resetModel() {
    this._modelDto = new BIADocumentDto();
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getList(biaId: number): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList && biaId == this.biaId) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.biaService.getDocumentList(biaId).subscribe((list) => {

          this._modelList = list;
          this._cachedList = true;

          this.biaId = biaId;

          observer.next(this.getDataServiceResult(10, '', null));
        },
          (error: string) => {
            observer.next(this.getDataServiceResult(-10, error, null));
          }
        );
      }
    });

    return result;
  }

  public getDocument(documentId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      this.biaService.getDocument(documentId).subscribe((biaDto) => {

        this._modelDto = biaDto;
        observer.next(true);
      });

    });

    return result;
  }

  public addDocument(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      var formData = new FormData();
      var docJson = JSON.stringify(this._modelDto.document);

      formData.append(this._modelDto.file.name, this._modelDto.file);
      formData.append(docJson, docJson);

      this.biaService.addDocument(formData).subscribe((doc) => {

        if (doc) {
          this._modelDto.document = doc;

          this.refeshList().subscribe(refreshResult => {

            if (refreshResult.result >= 0) {
              observer.next(this.getDataServiceResult(100, '', doc));
            }
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        } else {
          observer.next(this.getDataServiceResult(-100, 'Error add document to BIA - document was not returned by API', null));
        }

      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-100, error, null));
        }
      );

    });

    return result;
  }

  public updateDocument(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.updateDocument(this._modelDto.document).subscribe(doc => {

        if (doc) {
          this._modelDto.document = doc;

          this.refeshList().subscribe(refreshResult => {

            if (refreshResult.result >= 0) {
              observer.next(this.getDataServiceResult(200, '', doc));
            }
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        } else {
          observer.next(this.getDataServiceResult(-200, 'Error updating document for BIA - document was not returned by API', null));
        }

      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public refeshList(): Observable<ServiceResultModel> {
    this._cachedList = false;
    return this.getList(this.biaId);
  }

  public deleteDocument(biaItem: BIADocumentModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.delete(biaItem).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(300, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-300, 'API BIA document delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-300, error, null));
        }
      );

    });

    return result;
  }

  public confirmDeleteDocument(): Observable<boolean> {
    return this.confirmDelete('Are you sure you want to remove this document? ');
  }

  private confirmDelete(message: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let modal = this.modalService.show(ConfirmModalComponent, {
        class: 'modal-md',
        data: {
          title: 'Please Confirm',
          body: message
        },
      });

      modal.content.dialogResult.subscribe(
        (dialogResult: DialogResult) => {
          if (dialogResult.action.toLowerCase() === 'confirm') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }
      );

    });

    return result;
  }

  public onFileEdit(documentId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      const modalFormHeader = 'Upload BIA File';

      this.biaService.getDocument(documentId).subscribe(newDto => {

        newDto.document.biaId = this.biaId;

        const ud = this.modalService.show(BiaFileUploadComponent, {
          class: 'modal-md',
          data: {
            modalTitle: modalFormHeader,
            paramItem: newDto
          },
        });

        ud.content.onUpdateItem.subscribe(
          (dtoItem: BIADocumentDto) => {
            this._modelDto = dtoItem;
            observer.next(true);
          }
        );
      });
    });

    return result;
  }

  public download(documentId: number, fileName: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      this.biaService.download(documentId)
        .subscribe((blob) => {

          observer.next(true);
          
          var a = document.createElement("a");
          a.target = '_blank';

          a.href = URL.createObjectURL(blob);
          a.download = fileName;

          a.click();          
        });

    });

    return result;
  }

  private getDataServiceResult(codeId: number, error: string, result: any): ServiceResultModel {

    let typeName = 'Business impact analysis';

    if (codeId == -10) { return new ServiceResultModel(codeId, typeName + ' list retrieval error: ' + error, result) };
    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' add submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -300) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 10) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully retrieved', result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully added", result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 300) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }

}
