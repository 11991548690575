import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentFileTypeService {

  constructor() { }

  getType(extension: string): string {
    if (extension == null) {
      return 'far fa-file-alt document-icon';
    }

    switch (extension.toLowerCase()) {
      case 'pdf':
      case 'adobe acrobat reader':
        return 'far fa-file-pdf document-icon-pdf fa-lg';
      case 'xlsx':
      case 'xls':
        return 'far fa-file-excel document-icon-excel fa-lg';
      case 'docx':
      case 'doc':
      case 'word 2007':
        return 'far fa-file-word document-icon-word fa-lg';
      case 'pptx':
      case 'ppt':
      case 'powerpoint 2007':
      case 'microsoft powerpoint':
        return 'far fa-file-powerpoint document-icon-powerpoint fa-lg';
      default:
        return 'far fa-file-alt document-icon fa-lg';
    }
  }
}
