import { Component, OnInit } from '@angular/core';
import { BIABusinessChangeModel } from '@app/bia/_models/bia-business-change-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaBusinessChangeEditComponent } from '../bia-business-change-edit/bia-business-change-edit.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BIABusinessChangeListSortService } from './bia-business-change-list-sort.service';

@Component({
   selector: 'app-bia-business-change-list',
   templateUrl: './bia-business-change-list.component.html',
   styleUrls: ['./bia-business-change-list.component.scss'],
   providers: [ BIAEditStepListService, BIABusinessChangeListSortService ]
})
export class BiaBusinessChangeListComponent implements OnInit, IBIAStepListComponent {

  pageIndex = 10;
  isDirty = false;

  saving = false;

  public listItems: Array<BIABusinessChangeModel> = new Array<BIABusinessChangeModel>();

  constructor(
    private modalService: MDBModalService,
    public stepService: BIAEditStepListService,
    private sortService: BIABusinessChangeListSortService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaBusinessChange;
  }

  onAddItem() {
    this.onOpenItem(new BIABusinessChangeModel(), -100);
  }

  onDeleteItem(item: BIABusinessChangeModel, index: number) {

    this.pageService.confirmDeleteItem('business change').subscribe(result => {

      if (result) {

        this.pageService.deleteBIABusinessChange(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.listItems.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIABusinessChangeModel, i: number): void {

    const mf = this.modalService.show(BiaBusinessChangeEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIABusinessChangeModel) => {
        if (i == -100) {
          this.listItems.push(updatedItem);
        }
        else {
          this.listItems[i] = updatedItem;
        }
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaBusinessChange);
  }

}

