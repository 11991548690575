import { Injectable } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Location } from '@angular/common';
import { AppDocumentService } from '@app/_services/app-document.service';
import { AppDocumentModel } from '@app/_models/app-document-model';
import { AppDocumentDto } from '@app/_models/app-document-dto';
import { AppFileUploadComponent } from '../app-file-upload/app-file-upload.component';

@Injectable({
  providedIn: 'root'
})
export class AppDocumentPageService {

  public appId: number = 0;

  private _modelList = new Array<AppDocumentModel>();
  private _modelDto = new AppDocumentDto();

  private _cachedList = false;

  constructor(
    private appService: AppDocumentService,
    private modalService: MDBModalService,
    private location: Location) {
  }

  public get modelDto(): AppDocumentDto {
    return this._modelDto;
  }

  public get modelList(): Array<AppDocumentModel> {
    return this._modelList;
  }

  public resetModel() {
    this._modelDto = new AppDocumentDto();
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getList(appId: number): Observable<ServiceResultModel> {
  
    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList && appId == this.appId) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.appService.getDocumentList(appId).subscribe((list) => {

          this._modelList = list;
          this._cachedList = true;

          this.appId = appId;

          observer.next(this.getDataServiceResult(10, '', null));
        },
          (error: string) => {
            observer.next(this.getDataServiceResult(-10, error, null));
          }
        );
      }
    });

    return result;
  }

  public getDocument(documentId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      this.appService.getDocument(documentId).subscribe((AppDto) => {

        this._modelDto = AppDto;
        observer.next(true);
      });

    });

    return result;
  }

  public addDocument(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      var formData = new FormData();
      var docJson = JSON.stringify(this._modelDto.appdocument);

      formData.append(this._modelDto.file.name, this._modelDto.file);
      formData.append(docJson, docJson);

      this.appService.addDocument(formData).subscribe((doc) => {

        if (doc) {
          this._modelDto.appdocument = doc;

          this.refeshList().subscribe(refreshResult => {

            if (refreshResult.result >= 0) {
              observer.next(this.getDataServiceResult(100, '', doc));
            }
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        } else {
          observer.next(this.getDataServiceResult(-100, 'Error add document to App - document was not returned by API', null));
        }

      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-100, error, null));
        }
      );

    });

    return result;
  }

  public updateDocument(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.appService.updateDocument(this._modelDto.appdocument).subscribe(doc => {

        if (doc) {
          this._modelDto.appdocument = doc;

          this.refeshList().subscribe(refreshResult => {
            
            if (refreshResult.result >= 0) {
              observer.next(this.getDataServiceResult(200, '', doc));
            }
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        } else {
          observer.next(this.getDataServiceResult(-200, 'Error updating document for App - document was not returned by API', null));
        }

      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-200, error, null));
        }
      );

    });

    return result;
  }

  public refeshList(): Observable<ServiceResultModel> {
    this._cachedList = false;
    return this.getList(this.appId);
  }

  public deleteDocument(appItem: AppDocumentModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.appService.delete(appItem).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(300, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-300, 'API App document delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-300, error, null));
        }
      );

    });

    return result;
  }

  public confirmDeleteDocument(): Observable<boolean> {
    return this.confirmDelete('Are you sure you want to remove this document? ');
  }

  private confirmDelete(message: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let modal = this.modalService.show(ConfirmModalComponent, {
        class: 'modal-md',
        data: {
          title: 'Please Confirm',
          body: message
        },
      });

      modal.content.dialogResult.subscribe(
        (dialogResult: DialogResult) => {
          if (dialogResult.action.toLowerCase() === 'confirm') {

          } else {
            observer.next(false);
          }
        }
      );

    });

    return result;
  }

  public onFileEdit(documentId: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      const modalFormHeader = 'Upload App File';

      this.appService.getDocument(documentId).subscribe(newDto => {

        newDto.appdocument.appId = this.appId;

        const ud = this.modalService.show(AppFileUploadComponent, {
          class: 'modal-md',
          data: {
            modalTitle: modalFormHeader,
            paramItem: newDto
          },
        });

        ud.content.onUpdateItem.subscribe(
          (dtoItem: AppDocumentDto) => {
            this._modelDto = dtoItem;
            observer.next(true);
          }
        );
      });
    });

    return result;
  }

  public download(documentId: number, fileName: string): void {

    this.appService.download(documentId)
      .subscribe((blob) => {
        var a = document.createElement("a");
        a.target = '_blank';

        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        a.click();
      });
  }

  private getDataServiceResult(codeId: number, error: string, result: any): ServiceResultModel {

    let typeName = 'Business impact analysis';

    if (codeId == -10) { return new ServiceResultModel(codeId, typeName + ' list retrieval error: ' + error, result) };
    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' add submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -300) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 10) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully retrieved', result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully added", result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 300) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }

}
