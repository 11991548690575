import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IBIAStepComponent, IBIAStepFormComponent } from '@app/_models/ibiastep-component';
import { BIAEditStepFormService } from '../bia-edit/bia-edit-step-form.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-additional-info',
  templateUrl: './bia-additional-info.component.html',
  styleUrls: ['./bia-additional-info.component.scss'],
  providers: [ BIAEditStepFormService ]
})
export class BiaAdditionalInfoComponent implements OnInit, IBIAStepComponent, IBIAStepFormComponent {

  pageIndex = 13;
  isSubmitted = false;  
  saving = false;

  biaForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,
    public stepService: BIAEditStepFormService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      biaCherwellId: [''],
      biaUserSurvey: [''],
      biaAdditionalInfo: ['']
    });  

    this.stepService.setComponent(this);
    this.loadPage();
  }

  public loadPage(): void {
    this.biaCherwellId.setValue(this.pageService.modelDto.bia.cherwellTicketId);
    this.biaUserSurvey.setValue(this.pageService.modelDto.bia.userSurveyUrl);
    this.biaAdditionalInfo.setValue(this.pageService.modelDto.bia.additionalInfo);
  }

  get biaCherwellId(): any {
    return this.biaForm.get('biaCherwellId');
  } 

  get biaUserSurvey(): any {
    return this.biaForm.get('biaUserSurvey');
  } 

  get biaAdditionalInfo(): any {
    return this.biaForm.get('biaAdditionalInfo');
  }  

  public loadDto(): void {
    this.pageService.modelDto.bia.cherwellTicketId = this.biaCherwellId.value;
    this.pageService.modelDto.bia.userSurveyUrl = this.biaUserSurvey.value;
    this.pageService.modelDto.bia.additionalInfo = this.biaAdditionalInfo.value;
  }

  // onFinishForm(): void {
  //   this.pageService.saveForm(this, true);
  //   this.pageService.navigateBack();
  // }

  // onPriorStep(): void {
  //   this.pageService.onSetViewType(this.pageIndex-1);
  // }

  // onNextStep(): void {  
  //   this.onSaveCloseForm();
  // }

  // onSaveForm(): void {
  //   this.pageService.saveForm(this, false);
  // }

  // onSaveCloseForm(): void {
  //   this.pageService.saveForm(this, false);
  //   this.pageService.navigateBack();
  // }

  // onCloseForm(): void {
  //   this.pageService.resetModel();
  //   this.pageService.navigateBack();
  // }

  // private saveItem(complete: boolean) {

  //   if (this.saving) {
  //    return; 
  //   }

  //   this.isSubmitted = true;

  //   if (!this.biaForm.valid) {
  //     return;
  //   }

  //   if (!this.biaForm.dirty) {
  //     return;
  //   }
  
  //   this.loadDto();

  //   if (complete) {
  //     this.pageService.modelDto.bia.stepId = 0;
  //   }    

  //   this.saving = true;

  //   console.log("Additional Information save start");

  //   let saveResult = false;

  //   this.pageService.saveBia().subscribe(e => {

  //     if (e.statusId >= 0) {

  //       saveResult = true;
        
  //     } else {

  //       this.toastService.error(e.message, null, this.toastMessageOptions);
  //     }

  //     this.saving = false;

  //     console.log("Additional Information save end");
  //   })
  // }  

}
