import { Injectable } from '@angular/core';
import { BIABusinessFunctionModel } from '@app/bia/_models/bia-business-function-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaBusinessFunctionListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, list: BIABusinessFunctionModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'functionName' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.functionName.localeCompare(b.functionName));

    } else if (columnName === 'functionName') {

      list.sort((a, b) => b.functionName.localeCompare(a.functionName));

    }

    else if (columnName === 'mefEsaSupport' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.mefEsaSupport.localeCompare(b.mefEsaSupport));

    } else if (columnName === 'mefEsaSupport') {

      list.sort((a, b) => b.mefEsaSupport.localeCompare(a.mefEsaSupport));

    }

    else if (columnName === 'deadlineDesc' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.deadlineDesc.localeCompare(b.deadlineDesc));

    } else if (columnName === 'deadlineDesc') {

      list.sort((a, b) => b.deadlineDesc.localeCompare(a.deadlineDesc));

    }
    
    else if (columnName === 'dependencyDesc' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.dependencyDesc.localeCompare(b.dependencyDesc));

    } else if (columnName === 'dependencyDesc') {

      list.sort((a, b) => b.dependencyDesc.localeCompare(a.dependencyDesc));

    }
    
    else if (columnName === 'dependentDesc' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.dependentDesc.localeCompare(b.dependentDesc));

    } else if (columnName === 'dependentDesc') {

      list.sort((a, b) => b.dependentDesc.localeCompare(a.dependentDesc));

    }
    
    else if (columnName === 'criticalTimeDesc' && this.sortDirection === 'ASC') {

      list.sort((a, b) => a.criticalTimeDesc.localeCompare(b.criticalTimeDesc));

    } else if (columnName === 'criticalTimeDesc') {

      list.sort((a, b) => b.criticalTimeDesc.localeCompare(a.criticalTimeDesc));

    }    
  }
}

