import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { EmployeeModel } from '@app/_models/employee-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { OfficeScreenModel } from '@app/wellness/_models/office-screen-model';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';
import { WellnessLookupService } from '@app/wellness/_services/wellness-lookup.service';
import { UserScreenDto } from '@app/wellness/_models/user-screen-dto';
import { UserScreenService } from '@app/wellness/_services/user-screen.service';
import { WellnessUserService } from '@app/wellness/_services/wellness-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserScreenPageService {

  private _screening = new OfficeScreenModel();
  private _officeId = '';

  loading = true;
  loggedIn = false;

  constructor(
    private location: Location,
    private authService: MsalService,
    private userService: WellnessUserService,
    private screenService: UserScreenService,
    private vaccinePageService: UserVaccinePageService,
    private lookupService: WellnessLookupService) {

  }

  public load(): Observable<Boolean> {

    const result: Observable<Boolean> = new Observable((observer) => {

      if (this.userService.cached) {

        observer.next(true);

      } else {

        const accounts = this.authService.instance.getAllAccounts();

        if (accounts.length > 0) {

          this.loggedIn = true;

          this.userService.GetUser().subscribe(e => {

            this.userService.user = e;

            this.screenService.getOffices().subscribe(v => {

              console.log("User Screening Offices Found");

              this.userService.screenDto = v;

              this.userService.cached = true;

              this.setDefaultUserOfficeModel();

              // SET UP VACCINE SERVICE AS SAME TIME
              this.vaccinePageService.vaccine = this.userService.vaccineDto.vaccine;
              this.lookupService.lookupModel = this.userService.vaccineDto.lookup;
              
              this.loading = false;

              observer.next(true);
            });
          });


        } else {
          observer.next(false);
        }

      }

    });

    return result;
  }
  public get officeId(): string {
    return this._officeId;
  }

  public set officeId(value: string) {
    this._officeId = value;
    this.setOfficeModel();
  }

  public get model(): UserScreenDto {
    if (this.userService.screenDto == null) {
      console.log("UserScreenPageService: Missing Dto model");
      this.userService.screenDto = new UserScreenDto();
    }

    return this.userService.screenDto;
  }

  public get screening(): OfficeScreenModel {
    if (this._screening == null) {
      console.log("UserScreenPageService: Missing user screen model");
      this._screening = new OfficeScreenModel();
    }

    return this._screening
  }

  private setOfficeModel(): void {

    this.userService.screenDto.offices.forEach((o) => {

      if (o.officeId === this._officeId) {
        this._screening = o;
      }
    });
  }

  private setDefaultUserOfficeModel(): void {

    this.officeId = '0';

    this.userService.screenDto.offices.forEach((o) => {

      if (o.officeNm === this.userService.user.office) {
        this.officeId = o.officeId;
        return;
      }

    });

    console.log("setDefaultUserOfficeModel: Missing office Id");
  }

  public get dataWarehouseOnline(): boolean {
    return (this.userService.screenDto.vaccineDto.vaccine.userId > 0);
  }

  public get user(): EmployeeModel {
    return this.userService.user;
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getOfficeOptions(): Array<any> {

    let result = new Array<any>();

    result.push({ value: '0', label: '--- Please Select an Office ---' });

    this.userService.screenDto.offices.forEach((o) => {
      result.push({
        value: o.officeId,
        label: o.officeNm,
      });
    });

    return result;
  }

  public saveScreening(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.screenService.saveScreening(this._screening.userScreen).subscribe(v => {

        this._screening.userScreen = v;
        observer.next(this.getSaveResult(100, '', v));
      },
        (error: string) => {
          observer.next(this.getSaveResult(-100, error, null));
        }
      );

    });

    return result;
  }

  private getSaveResult(codeId: number, error: string, result: any): ServiceResultModel {

    if (codeId == -100) { return new ServiceResultModel(codeId, 'Daily screening submission error: ' + error, result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The daily screening has been successfully submitted', result) };

    return new ServiceResultModel(0, '', null);
  }
}
