import { Component, OnInit } from '@angular/core';
import { MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { UserScreenPageService } from './user-screen-page.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DialogResult } from '@app/_models/dialog-result-model';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from '@app/_components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-user-screen',
  templateUrl: './user-screen.component.html',
  styleUrls: ['./user-screen.component.scss']
})
export class UserScreenComponent implements OnInit {

  pageTitle = 'Daily Screening';
  saveText = 'A daily screening was successfully submitted for this office.” to “A daily screening was successfully submitted for the <office name> office on <date>”';
  flagText = '';
  screenDateLabel = '';

  screenForm!: FormGroup;
  questionText: Array<string> = ['', '', '', '', ''];

  optionsOffice: any[] = new Array<{ value: string, label: string }>();

  enableSave = false;
  saving = false;
  isNew = false;
  isFlagForHR = false;
  questionCount = 5;

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(private formBuilder: FormBuilder,
    private toastService: ToastService,
    public modalService: MDBModalService,
    public pageService: UserScreenPageService) { }

  ngOnInit(): void {

    this.screenForm = this.formBuilder.group({
      officeId: [''],
      question1: [],
      question2: [],
      question3: [],
      question4: [],
      question5: [],
      verificationId: []
    });

    this.pageService.load().subscribe(officesFound => {

      if (officesFound) {

        this.optionsOffice = this.pageService.getOfficeOptions();
        this.officeId.setValue(this.pageService.officeId);

        const datepipe: DatePipe = new DatePipe('en-US')
        const dateName = datepipe.transform(new Date(), 'MMMM d, y');

        if (dateName != null) {
          this.screenDateLabel = 'for ' + dateName;
        }

        if (!this.pageService.dataWarehouseOnline) {
          this.setDataWarehouseOffline();
        }

        this.loadOfficePage();
      }
    });
  }

  public loadOfficePage(): void {

    if (!this.pageService.dataWarehouseOnline) {
      return;
    }

    this.isFlagForHR = false;
    this.isNew = this.pageService.screening.userScreen.userScreenId === 0;

    if (this.showQuestionList()) {

      this.loadQuestions();

      if (this.isNew) {

        this.setPageEdit();

      } else {

        this.loadResponses();
      }
    }

  }

  private loadResponses(): void {

    let index = 0;

    this.pageService.screening.userScreen.questions.forEach(q => {
      let fieldNm = 'question' + (index++ + 1).toString();
      this.screenForm.get(fieldNm)?.setValue(q.responseId);

      if (q.responseId) {
        this.isFlagForHR = true;
      }
    });

    this.screenForm.get('verificationId')?.setValue(true);

    this.setSaveMessage();

    this.setPageReadOnly();
  }

  private loadQuestions(): void {

    let index = 0;

    this.screenForm.get('question1')?.setValue('');
    this.screenForm.get('question2')?.setValue('');
    this.screenForm.get('question3')?.setValue('');
    this.screenForm.get('question4')?.setValue('');
    this.screenForm.get('question5')?.setValue('');

    this.screenForm.get('verificationId')?.setValue('');

    this.pageService.screening.userScreen.questions.forEach(q => {
      this.questionText[index++] = q.questionText;
    });

    this.questionCount = this.pageService.screening.userScreen.questions.length;
  }

  public showQuestionList(): boolean {
    return this.pageService.officeId != '0';
  }

  public showQuestion(questionNo: number): boolean {
    return this.showQuestionList() && questionNo <= this.questionCount;
  }

  public showFootnoteNY(): boolean {
    return this.showQuestionList() && this.pageService.officeId == '10' && 
      (this.pageService.screening.questionSetId == 5 || this.pageService.screening.questionSetId == 26);
  }

  get loading(): boolean {
    return this.pageService.loading;
  }

  get officeId(): any {
    return this.screenForm.get('officeId');
  }

  public onOfficeChange() {
    this.pageService.officeId = this.officeId.value;
    this.loadOfficePage();
  }

  public onResponseChange() {
    this.enableSave = this.questionsComplete();
  }

  public onVerifyChange() {
    this.enableSave = this.questionsComplete();
  }

  private questionsComplete(): boolean {

    if (this.showQuestion(1) && this.screenForm.get('question1')?.value === '') {
      return false;
    }

    if (this.showQuestion(2) && this.screenForm.get('question2')?.value === '') {
      return false;
    }

    if (this.showQuestion(3) && this.screenForm.get('question3')?.value === '') {
      return false;
    }

    if (this.showQuestion(4) && this.screenForm.get('question4')?.value === '') {
      return false;
    }

    if (this.showQuestion(5) && this.screenForm.get('question5')?.value === '') {
      return false;
    }

    if (this.screenForm.get('verificationId')?.value === '') {
      return false;
    }

    if (!this.screenForm.get('verificationId')?.value) {
      return false;
    }

    return true;
  }

  private setDataWarehouseOffline(): void {
    this.screenForm.get('officeId')?.disable();
    this.setPageReadOnly();
  }

  private setPageEdit() {

    if (this.showQuestion(1)) { 
      this.screenForm.get('question1')?.enable(); 
    }

    if (this.showQuestion(2)) {
      this.screenForm.get('question2')?.enable();
    }

    if (this.showQuestion(3)) {
      this.screenForm.get('question3')?.enable();
    }

    if (this.showQuestion(4)) {
      this.screenForm.get('question4')?.enable();
    }

    if (this.showQuestion(5)) {
      this.screenForm.get('question5')?.enable();
    }

    this.screenForm.get('verificationId')?.enable();

    this.enableSave = false;
  }

  private setPageReadOnly() {

    if (this.showQuestion(1)) { 
      this.screenForm.get('question1')?.disable(); 
    }

    if (this.showQuestion(2)) {
      this.screenForm.get('question2')?.disable();
    }

    if (this.showQuestion(3)) {
      this.screenForm.get('question3')?.disable();
    }

    if (this.showQuestion(4)) {
      this.screenForm.get('question4')?.disable();
    }

    if (this.showQuestion(5)) {
      this.screenForm.get('question5')?.disable();
    }

    this.screenForm.get('verificationId')?.disable();
  }

  private setSaveMessage(): void {

    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.pageService.screening.userScreen.createdDt, 'M/d/yy');
    let officeNm = this.pageService.screening.officeNm;

    this.saveText = `A daily screening was successfully submitted for the ${officeNm} office on ${formattedDate}. You are approved to go to the office.`;
  }

  confirmSaveWithYes(): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let promptForSave = false;
      let index = 0;

      this.pageService.screening.userScreen.questions.forEach(q => {
        let responseId = this.screenForm.get('question' + (index++ + 1).toString())?.value;
        if (responseId) {
          promptForSave = true;
        }
      });

      if (promptForSave) {

        const msg = 'Once submitted, answers may not be changed. Are you sure you want to submit this daily screening? ';

        let modal = this.modalService.show(ConfirmModalComponent, {
          class: 'modal-md',
          data: {
            title: 'Please Confirm',
            body: msg
            // confirmAction: { label: 'Yes' },
            // cancelAction: { label: 'No' },
          },
        });

        modal.content.dialogResult.subscribe(
          (dialogResult: DialogResult) => {
            if (dialogResult.action.toLowerCase() === 'confirm') {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        );
      } else {
        observer.next(true);
      }
    });

    return result;
  }
  public onSave() {

    if (this.saving) {
      return;
    }

    this.confirmSaveWithYes().subscribe(promptResult => {

      if (!promptResult) {
        return;
      }

      let index = 0;

      this.pageService.screening.userScreen.questions.forEach(q => {
        q.responseId = this.screenForm.get('question' + (index++ + 1).toString())?.value;
      });

      this.saving = true;

      console.log("daily screening save start");

      this.pageService.saveScreening().subscribe(e => {

        if (e.statusId >= 0) {

          this.toastService.success(e.message, null, this.toastMessageOptions)

          this.loadOfficePage();

        } else {

          this.toastService.error(e.message, null, this.toastMessageOptions)
        }

        this.saving = false;

        console.log("daily screening save end");
      })
    })
  }
}
