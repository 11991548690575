<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="this.closeForm()">
            <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title">{{this.title}}</h4>
    </div>
    <div class="modal-body" [innerHtml]="this.bodyHtml"></div>
    <div class="modal-footer">
        <button mdbBtn type="button" color="primary" [outline]="true" class="float-right" (click)="this.closeForm()">
            Close
        </button>
    </div>
</div>