<div class='wrap alt pb-0'>
    <div class='container'>


        <div class='row'>
            <div class='col' style="margin-bottom: 1.5rem; font-size: 0.8rem;">
                <a class='breadcrumb-title' (click)="onNavigateList()">
                    <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> BIA Listing
                </a>
                <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> {{biaTitle}}
            </div>
        </div>

        <div class='row'>
            <div class="col-12 col-md-8">
                <ul class="tabs segal-plan">
                    <li><a (click)="onNavigateBIA()">Business Impact Analysis</a></li>
                    <li class="active"><a (click)="$event.stopPropagation()">Documentation</a></li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-12">



                <mdb-card class="mb-5">

                    <mdb-card-body>


                        <mdb-card-title>
                            <h3>
                                BIA Documents
                                <button mdbBtn type="button" class="btn-segal float-right" (click)="onNavigateAdd()">
                                    <i class="fa fa-plus" aria-hidden="true"></i> Add
                                </button>
                            </h3>
                        </mdb-card-title>



                        <div *ngIf="loading || fileLoading" class='row'>
                            <div class='col'>
                                <div class="spinner-border text-info segal-spinner" role="status">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!loading" class="row">
                            <div class="col">
                                <div class="table-responsive table-clickable">
                                    <table mdbTable>

                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Uploaded</th>
                                                <th>Description</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr mdbTableCol *ngFor="let bia of pageService.modelList"
                                                (click)="onNavigateEdit(bia)">

                                                <!-- NAME -->
                                                <td>{{bia.fileName}}</td>

                                                <!-- TYPE -->
                                                <td>{{bia.documentType == null ? '' : bia.documentType.documentTypeNm}}
                                                </td>

                                                <!-- DATE -->
                                                <td>{{bia.createdOn | date}}</td>

                                                <!-- DESCRIPTION -->
                                                <td>{{bia.documentDesc}}</td>

                                                <!-- DOWNLOAD -->
                                                <td class="text-right"
                                                    (click)="onDownload(bia); $event.stopPropagation()"
                                                    title="Download Document">
                                                    <mdb-icon fas icon="download"></mdb-icon>
                                                </td>

                                                <!-- DELETE -->
                                                <td class="text-right">
                                                    <button mdbBtn type="button" class="icon-btn"
                                                        (click)="onDelete(bia); $event.stopPropagation()">
                                                        <i class="fa fa-trash" aria-hidden="true" title="Remove"></i>
                                                    </button>
                                                </td>

                                            </tr>
                                        </tbody>

                                        <tfoot *ngIf="pageService.modelList.length === 0">
                                            <tr>
                                                <td class="text-center no-results" colspan="6">
                                                    No Documents Found
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                            </div>
                        </div>

                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>



    </div>
</div>