<div class='wrap alt pb-0'>
  <div class='container'>

    <mdb-card class="mb-5">
      <mdb-card-body>

        <mdb-card-title>
          <h3>
            Applications Inventory
            <button mdbBtn type="button" class="btn-segal float-right"
                    (click)="onNavigateAppAdd()">
              <i class="fa fa-plus" aria-hidden="true"></i> Add
            </button>
          </h3>
        </mdb-card-title>

        <div *ngIf="!loading" class="row search-row">
          <div class="col-md-6 md-form dashboard-search">
            <mdb-icon fas icon="search" class="prefix fa"></mdb-icon>
            <input type="text" class="form-control z-depth-1" [(ngModel)]="searchText" (input)="onFilterList()"
                   placeholder="Search Keyword">
          </div>
        </div>

        <div *ngIf="loading" class="row loading-row">
          <div>
            <div class="spinner-border text-info segal-spinner full-page" role="status"></div>
          </div>
        </div>

        <div *ngIf="!loading" class="row">
          <div class="col">
            <div class="table-responsive table-clickable">
              <table mdbTable id="tableMain" #tableEl="mdbTable" class="z-depth-1">

                <thead>
                  <tr>
                    <th><a (click)="sortBy('appId')">ID</a></th>
                    <th><a (click)="sortBy('appName')">App Name</a></th>
                    <th><a (click)="sortBy('buName')">Business Unit</a></th>
                    <th><a (click)="sortBy('appOwnerId')">App Owner ID</a></th>
                    <th><a (click)="sortBy('itTechContact')">App Tech Contact</a></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr mdbTableCol *ngFor="let applicationsList of filteredList" (click)="onNavigateApplication(applicationsList)">

                    <!-- AppID -->
                    <td>
                      {{applicationsList.appId}}
                    </td>

                    <!-- AppName -->
                    <td>{{applicationsList.appName}}</td>

                    <!-- BUSINESS UNIT -->
                    <td>
                      {{applicationsList.businessUnit.userGroupName}}
                    </td>

                    <!-- AppOwnerID -->
                    <!--<td>{{applicationsList.appOwnerId}}</td>-->
                    <td>{{applicationsList.appOwnerList}}</td>

                    <!-- ItTechContact -->
                    <!--<td>{{applicationsList.itTechContact}}</td>-->
                    <td>{{applicationsList.appContactList}}</td>

                    <td class="text-right">
                      <button mdbBtn type="button" class="icon-btn" (click)="onDeleteApp(applicationsList); $event.stopPropagation()">
                        <i class="fa fa-trash" aria-hidden="true" title="Remove"></i>
                      </button>
                    </td>

                  </tr>
                </tbody>

                <tfoot class="grey lighten-5 w-100">
                  <tr>
                    <td *ngIf="pageService.modelList.length === 0" class="text-center no-results" colspan="6">
                      No Application Found
                    </td>
                  </tr>
                </tfoot>

              </table>

            </div>
          </div>
        </div>

      </mdb-card-body>
    </mdb-card>

  </div>
</div>
