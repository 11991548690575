import { BIADocumentTypeModel } from "./bia-document-type-model";

export class BIADocumentModel {

    public documentId: number = 0;
    public biaId: number = 0;
    public documentTypeId: number = 0;
    public documentDesc: string = '';
    public fileName: string = '';
    public fileExt: string = '';

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
    
    public documentType: BIADocumentTypeModel = new BIADocumentTypeModel();
}
