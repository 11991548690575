export class BIABusinessChangeModel {
    
    public changeId: number = 0;
    public biaId: number = 0;

    public changeDesc: string = '';
    public impactDesc: string = '';

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}
