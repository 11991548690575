<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{this.title}}</h4>
  </div>

  <div class="modal-body" [innerHtml]="this.body"> </div>

  <div *ngIf="showMultipleChoiceOptions">
    <div *ngFor="let choiceOption of multipleChoiceOptions; let i = index">
      <div class="form-check">
        <div *ngIf="i === 0">
          <input type="radio" class="form-check-input with-gap" 
              id="{{'choiceOption-' + i}}" 
              name="groupChoice" 
              (change)="onChoiceChange(choiceOption)"
              checked
              mdbInput
          >
          <label class="form-check-label" for="{{'choiceOption-' + i}}">{{choiceOption.label}}</label>
        </div>
        <div *ngIf="i > 0">
          <input type="radio" class="form-check-input with-gap" 
              id="{{'choiceOption-' + i}}" 
              name="groupChoice" 
              (change)="onChoiceChange(choiceOption)"
              mdbInput
          >
          <label class="form-check-label" for="{{'choiceOption-' + i}}">{{choiceOption.label}}</label>
        </div>
      </div>
    </div>
    <br>
  </div>

  <div class="modal-footer">
    <div *ngIf = 'cancelAction.Enabled'>
      <button mdbBtn type="button" class="float-right btn-segal" (click)="this.onCancel($event)">
          {{this.cancelAction.label}}
      </button>
  </div>

  <div *ngIf = 'confirmAction.Enabled'>
    <button mdbBtn type="button" class="float-right btn-segal" (click)="this.onConfirm($event)">
       {{this.confirmAction.label}}
    </button>
  </div>
    
  </div>
</div>   
   
  
