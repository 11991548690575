<form [formGroup]="biaForm" class="md-form">

    <div class="row">
        <!--Cherwell ID-->
        <div class="col-12 col-lg-4">
            <div class="md-form">
                <input mdbInput type="text" class="form-control" formControlName="biaCherwellId" maxlength="50" />
                <label>Cherwell Change Request #</label>
            </div>
        </div>
        <!--User Survey Link-->
        <div class="col-12 col-lg-8">
            <div class="md-form">
                <input mdbInput type="text" class="form-control" formControlName="biaUserSurvey" maxlength="250" />
                <label>User Survey Link</label>
            </div>
        </div>
    </div>

    <!--Additional Information-->
    <div class="row">
        <div class="col-12">
            <div class="md-form">
                <textarea type="text" placeholder="Enter ..." class="md-textarea form-control auto-height"
                    formControlName="biaAdditionalInfo" mdbInput style="min-height: 143px;"></textarea>
                <label>Additional Information</label>
            </div>
        </div>
    </div>

    <div class="row" style="padding-top: 25px;">
        <div class="col-12">
            <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
                (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
            <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Finish"
                (click)="stepService.onFinishStep()" mdbWavesEffect>FINISH</button>
            <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
                (click)="stepService.onSaveForm()" mdbWavesEffect>SAVE</button>
            <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
                (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
        </div>
    </div>

</form>