<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('applicationName')?.errors?.required">
                                        Missing the application name.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Name -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="applicationName" class="form-control"
                                    maxlength="500" />
                                <label>Software/ Application/System</label>
                            </div>
                        </div>
                    </div>

                    <!-- Description -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="applicationDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>Description</label>
                            </div>
                        </div>
                    </div>
                    
                     <!-- Backup Plan -->
                     <div class="row">
                        <div class="col-lg-12">
                            <div class="md-form">
                                <mdb-checkbox formControlName="backupPlan">Is There a Documented Backup Plan?</mdb-checkbox>
                            </div>
                        </div>
                    </div>

                    <!-- System Downtime -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="downtimeDesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;"></textarea>
                                <label>System Downtime</label>
                            </div>
                        </div>
                    </div>

                    <!-- Age of Data -->
                    <div class="row">

                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="dataAgeDesc" class="form-control"
                                    maxlength="500" />
                                <label>Age of Data</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>

        </app-spinner>
    </div>
</form>
