export class BIABusinessFunctionModel {
    
    public functionId: number = 0;
    public biaId: number = 0;

    public functionName: string = '';
    public mefEsaSupport: string = '';
    public deadlineDesc: string = '';
    public dependencyDesc: string = '';
    public dependentDesc: string = '';
    public criticalTimeDesc: string = '';

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}
