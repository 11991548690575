import { EmployeeModel } from "@app/_models/employee-model";
import { UserRoleType } from "./user-role-type-model ";

export class UserRoleModel {
    
    public userRoleId: number = 0;
    public roleTypeId: number = 0;
    public empId: string = '';

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

    public roleType: UserRoleType = new UserRoleType();
    public employee: EmployeeModel = new EmployeeModel();
}
