import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { LookupValue } from '@app/_models/lookup-value';
import { BIADocumentDto } from '@app/bia/_models/bia-document-dto';

@Component({
  selector: 'app-bia-file-upload',
  templateUrl: './bia-file-upload.component.html',
  styleUrls: ['./bia-file-upload.component.scss']
})
export class BiaFileUploadComponent implements OnInit {

  paramItem: BIADocumentDto = new BIADocumentDto();
  onUpdateItem: Subject<BIADocumentDto> = new Subject<BIADocumentDto>();

  isSubmitted = false;
  isNewDocument = false;
  fileDropped = false;

  modalTitle!: string;

  docForm!: FormGroup;

  optionsDocumentType: Array<any> = new Array<any>();

  files: File[] = [];
  file!: File;

  constructor(
    public modalRefDocument: MDBModalRef,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    this.docForm = this.formBuilder.group({
      documentName: ['', [Validators.required]],
      documentDesc: [''],
      documentTypeId: ['', [Validators.required]],
      uploadedDate: [Date]
    });

    let document = this.paramItem.document;

    this.isNewDocument = document.documentId == 0;
    this.fileDropped = !this.isNewDocument;

    const uploadedOn = document.createdOn == null ? null : formatDate(document.createdOn, 'MMM d, y', 'en');
    this.uploadedDate.setValue(uploadedOn);

    if (document.fileName) {
      this.documentName.setValue(document.fileName);
    }

    if (document.documentTypeId > 0) {
      this.documentType.setValue(document.documentTypeId.toString());
    }
    
    this.documentDesc.setValue(document.documentDesc);

    this.paramItem.documentTypes.forEach(item => {
      this.optionsDocumentType.push(new LookupValue(item.documentTypeId.toString(), item.documentTypeNm))
    });
  }

  get documentName(): any {
    return this.docForm.get('documentName');
  }

  get documentType(): any {
    return this.docForm.get('documentTypeId');
  }

  get documentDesc(): any {
    return this.docForm.get('documentDesc');
  }

  get uploadedDate(): any {
    return this.docForm.get('uploadedDate');
  }

  onCancel() {
    this.modalRefDocument.hide();
  }


  public onSubmit() {

    this.isSubmitted = true;

    if (!this.docForm.valid) {
      return;
    }

    let document = this.paramItem.document;

    if (this.fileDropped) {

      const extension = this.documentName.value.split('.').pop();

      if (extension != undefined) {
        document.fileExt = extension;
      }

      document.fileName = this.documentName.value;

      this.paramItem.file = this.file;
    }
     
    document.documentDesc = this.documentDesc.value;
    document.documentTypeId = +this.documentType.value;

    this.paramItem.documentTypes.forEach(item => {
      if (item.documentTypeId == document.documentTypeId) {
        document.documentType = item;
      }
    });
   
    this.onUpdateItem.next(this.paramItem);

    this.modalRefDocument.hide();
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onDropFile(event: any) {

    console.log(event);

    this.files.splice(0, 0, ...event.addedFiles);

    if (this.files.length > 0) {

      this.file = this.files[0];

      const fileName = this.file.name.replace("[\\\\/:*?\"<>|]", "_");

      const uploadDate = formatDate(new Date(), 'MMM d, y', 'en');

      this.uploadedDate.setValue(uploadDate);

      this.documentName.setValue(fileName);

      this.files = [];

      this.fileDropped = true;
    }
  }
}
