
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark">
    <mdb-navbar-brand>&nbsp;</mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateBia()">Business Impact Analysis</a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateApplication()">Application Inventory</a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect (click)="navigateAdmin()">Administration</a>
        </li>

        <li *ngIf="isAdmin()" class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect href="{{reportUrl}}" target="_blank">
              Reports
          </a>
      </li>
        
      </ul>
    </links>
</mdb-navbar>
