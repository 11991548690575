import { AppContactModel } from "@app/_models/app-contact-model";
import { BIABackupStrategyModel } from "./bia-backup-strategy-model";
import { BIABusinessChangeModel } from "./bia-business-change-model";
import { BIABusinessFunctionModel } from "./bia-business-function-model";
import { BIABusinessProcessModel } from "./bia-business-process-model";
import { BIAContactModel } from "./bia-contact-model";
import { BIADependencyModel } from "./bia-dependency-model";
import { BIAResourceModel } from "./bia-resource-model";

export class BIAModel {

    public biaId: number = 0;
    public biaName: string = '';
    public overview: string = '';
    public additionalInfo: string = '';
    public cherwellTicketId: string = '';
    public userSurveyUrl: string = '';
    public stepId: number = 1;
    public isActive: boolean = true;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

    public biaApplicationList: string = '';
    public biaBusinessUnitList: string = '';
    public biaContactList: string = '';

    public biaBusinessProcess: Array<BIABusinessProcessModel> = new Array<BIABusinessProcessModel>();
    public biaResources: Array<BIAResourceModel> = new Array<BIAResourceModel>();
    public biaContacts: Array<BIAContactModel> = new Array<BIAContactModel>();
    public biaDependency: Array<BIADependencyModel> = new Array<BIADependencyModel>();
    public biaOtherDependency: Array<BIADependencyModel> = new Array<BIADependencyModel>();
    public biaBusinessFunction: Array<BIABusinessFunctionModel> = new Array<BIABusinessFunctionModel>();
    public biaBusinessChange: Array<BIABusinessChangeModel> = new Array<BIABusinessChangeModel>();
    public biaBackupStrategy: Array<BIABackupStrategyModel> = new Array<BIABackupStrategyModel>();
    public biaAppContacts: Array<AppContactModel>  = new Array<AppContactModel>();
}
