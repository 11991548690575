import { Component, Input, OnInit } from '@angular/core';
import { BIADependencyModel } from '@app/bia/_models/bia-dependency-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaDependencyEditComponent } from '../bia-dependency-edit/bia-dependency-edit.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaDependencyListSortService } from './bia-dependency-list-sort.service';

@Component({
  selector: 'app-bia-dependency-list',
  templateUrl: './bia-dependency-list.component.html',
  styleUrls: ['./bia-dependency-list.component.scss'],
  providers: [ BIAEditStepListService, BiaDependencyListSortService ]
})
export class BiaDependencyListComponent implements OnInit, IBIAStepListComponent {

  @Input() isOther: boolean = false;

  pageIndex = 8;
  isDirty = false;

  saving = false;

  public listItems: Array<BIADependencyModel> = new Array<BIADependencyModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaDependencyListSortService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    if (this.isOther) {
      this.pageIndex = 9;
    }

    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);    
  }

  public loadPage(): void {
    this.listItems = (this.isOther) ? this.pageService.modelDto.bia.biaOtherDependency : this.pageService.modelDto.bia.biaDependency;
  }

  showOtherText(): string {
    return (this.isOther) ? 'Other ' : '';
  }

  onAddItem() {
    let model = new BIADependencyModel();
    model.otherRequirement = this.isOther;
    this.onOpenItem(model, -100);
  }

  onDeleteItem(item: BIADependencyModel, index: number) {

    this.pageService.confirmDeleteItem('application dependency').subscribe(result => {

      if (result) {

        this.pageService.deleteBIADependency(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.listItems.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIADependencyModel, i: number): void {

    const mf = this.modalService.show(BiaDependencyEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIADependencyModel) => {
        if (i == -100) {
          this.listItems.push(updatedItem);
        }
        else {
          this.listItems[i] = updatedItem;
        }
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    let items = (this.isOther) ? this.pageService.modelDto.bia.biaOtherDependency : this.pageService.modelDto.bia.biaDependency;
    this.sortService.sortBy(columnName, items);
  }
}
