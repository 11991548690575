<form [formGroup]="vaccineForm" class="md-form">

    <div class='wrap alt pb-0'>
        <div class='container'>

            <div class="row">
                <div class="col-12">

                    <mdb-card class="mb-5">
                        <mdb-card-body>

                            <!--Title-->

                            <mdb-card-title>
                                <h3>
                                    {{pageTitle}}
                                </h3>
                            </mdb-card-title>

                            <div class='row'>
                                <div class='col'>

                                    <div class="alert alert-danger" *ngIf="isSubmitted && (vaccineForm?.invalid)">

                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Vaccination
                                            card
                                            details could
                                            not be saved. Please
                                            fix the errors below.</span>

                                        <ul>
                                            <li *ngIf="vaccineForm?.get('vaccineNameId')?.errors?.required">
                                                Missing the name of the vaccine.
                                            </li>
                                            <li *ngIf="vaccineForm?.get('doseDt1')?.errors?.required">
                                                Missing the date of the first dosage.
                                            </li>
                                            <li *ngIf="vaccineForm?.get('doseDt2')?.errors?.required">
                                                Missing the date of the second dosage.
                                            </li>
                                            <li *ngIf="vaccineForm?.errors?.dates">
                                                First dosage date must be before second dosage date.
                                            </li>
                                            <li *ngIf="vaccineForm?.errors?.futureDate">
                                                Dosage date must be today or earlier
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="saving" class='row desktop-show'>
                                <div class='col'>
                                    <div class="spinner-border text-info segal-spinner" role="status">
                                    </div>
                                </div>
                            </div>

                            <!--Help Message-->
                            <div class="row" *ngIf="showSecondDose">
                                <div class="col-6" style="padding-bottom: 30px;">
                                    <b>Disaster Recovery Impact Applications List</b> – please keep these applications' information up to date ... ...
                                </div>
                            </div>

                            <!--Vaccine Name-->
                            <div class="row">
                                <div class="col-6">
                                    <div class="md-form">
                                        <mdb-select formControlName="vaccineNameId" [options]="optionsVaccineName"
                                            placeholder="Please Select" (selected)="onVaccineChange()"
                                            [ngClass]="{'form-invalid':isSubmitted && vaccineForm.get('vaccineNameId')?.errors?.required}"
                                            class="form-control-mdb">
                                        </mdb-select>
                                        <label>Vaccine Name</label>
                                        <mdb-error *ngIf="isSubmitted && vaccineForm.get('vaccineNameId')?.errors?.required">
                                          Please select Vaccine name
                                        </mdb-error>
                                        <div class="spinner-border text-info segal-spinner mobile-show" role="status"
                                            *ngIf="saving">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--First Dose-->
                            <div class="row">
                                <div class="col-6">
                                    <div class="md-form">
                                        <mdb-date-picker [inline]="true" formControlName="doseDt1"
                                            [options]="datePickerOptions" [placeholder]="'Select date'"
                                            (dateChanged)="onDoseDate1Change($event)"
                                            [ngClass]="{'form-invalid':isSubmitted && vaccineForm?.get('doseDt1')?.errors?.required}">
                                        </mdb-date-picker>
                                        <label class="{{doseDate1ShortClass}}">{{doseDate1Name}}</label>
                                        <mdb-error *ngIf="isSubmitted && vaccineForm?.get('doseDt1')?.errors?.required">
                                            Please select date of first dosage</mdb-error>
                                    </div>
                                </div>

                            </div>

                            <!--Second Dose-->
                            <div class="row" *ngIf="showSecondDose">
                                <div class="col-6">
                                    <div class="md-form">
                                        <mdb-date-picker [inline]="true" formControlName="doseDt2"
                                            [options]="datePickerOptions" [placeholder]="'Select date'"
                                            (dateChanged)="onDoseDate2Change($event)"
                                            [ngClass]="{'form-invalid':isSubmitted && vaccineForm?.get('doseDt2')?.errors?.required}">
                                        </mdb-date-picker>
                                        <label class="{{doseDate2ShortClass}}">{{doseDate2Name}}</label>
                                        <mdb-error *ngIf="isSubmitted && vaccineForm?.get('doseDt2')?.errors?.required">
                                            Please select date of second dosage</mdb-error>
                                    </div>
                                </div>
                            </div>


                            <!--Status-->
                            <div class="row" *ngIf='showFileUpload'>
                                <div class="col-6">
                                    <div class="md-form">
                                        <mdb-select formControlName="statusId" [options]="statusOptions"
                                            class="form-control-mdb">
                                        </mdb-select>
                                        <label>Status</label>
                                    </div>
                                </div>
                            </div>


                            <div class="row" *ngIf='!showFileUpload && !pageService.adminView'>
                                <div class="col-6">
                                    <button mdbBtn type="button" class="btn-segal-green" (click)="onUploadCard()">
                                        Upload Image</button>
                                    <button mdbBtn type="button" style="padding-left: 15px;" class="btn-segal"
                                        (click)="onSaveCard()"> SAVE </button>
                                </div>
                            </div>

                            <div *ngIf='showFileUpload'>
                                <div class="col-6" style="padding-left:25px;">
                                    <a href="javascript:void(0)" (click)="onDownloadFile()">
                                        <i [ngClass]="getFileType()" aria-hidden="true" title="View Document"></i>
                                        {{pageService.vaccine.document.fileName}}
                                    </a>
                                    <!-- ({{userService.vaccine.createdDt | date}}) -->
                                </div>
                            </div>

                            <!--Back Button-->
                            <div class="row" *ngIf='pageService.adminView'>
                                <div class="col-6" style="padding-top: 10px;">
                                    <div class="md-form">
                                        <button mdbBtn type="button" class="btn-segal"
                                            (click)="pageService.navigateBack()">
                                            <i class="fas fa-chevron-left" aria-hidden="true"></i> Administration
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf='showDoseDate1WarningText '>
                                <div class="col-6" style="padding-top:10px; padding-left:25px; color:red">
                                    {{doseDate1WarningText }}
                                </div>
                            </div>

                            <div class="row" *ngIf='showDoseDate2WarningText '>
                                <div class="col-6" style="padding-top:10px; padding-left:25px; color:red">
                                    {{doseDate2WarningText }}
                                </div>
                            </div>

                            <!-- <div class="row" *ngIf='showFileUpload' style="margin-top: 15px;">
                        <div class="col-12">                            
                            Status: <span class="badge rounded-pill bg-success text-white">Success</span>
                        </div>
                    </div> -->

                        </mdb-card-body>
                    </mdb-card>
                </div>



                <div class="col-12 col-lg-6" *ngIf="showBooster">

                    <div *ngIf="showFileUpload">
                        <app-booster-card #booster></app-booster-card>
                    </div>

                </div>


            </div>

        </div>
    </div>

</form>
