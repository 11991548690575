import { Component, OnInit } from '@angular/core';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaContactEditComponent } from '../bia-contact-edit/bia-contact-edit.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaContactListSortService } from './bia-contact-list-sort.service';
import { BIAContactModel } from '@app/bia/_models/bia-contact-model';
import { LookupService } from '@app/_services/lookup.service';

@Component({
  selector: 'app-bia-contact-list',
  templateUrl: './bia-contact-list.component.html',
  styleUrls: ['./bia-contact-list.component.scss'],
  providers: [ BIAEditStepListService, BiaContactListSortService ]
})
export class BiaContactListComponent implements OnInit, IBIAStepListComponent {

  pageIndex = 6;
  isDirty = false;

  saving = false;

  public listItems: Array<BIAContactModel> = new Array<BIAContactModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaContactListSortService,
    private lookupService: LookupService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaContacts;
  }

  onAddItem() {
    this.onOpenItem(new BIAContactModel(), -100);
  }

  onDeleteItem(item: BIAContactModel, index: number) {

    this.pageService.confirmDeleteItem('contact').subscribe(result => {

      if (result) {

        this.pageService.deleteBIAContact(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.bia.biaContacts.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIAContactModel, i: number): void {

    const mf = this.modalService.show(BiaContactEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem,
        employeeList: this.lookupService.activeEmployees
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIAContactModel) => {
        if (i == -100) {
          this.pageService.modelDto.bia.biaContacts.push(updatedItem);
        }
        else {
          this.pageService.modelDto.bia.biaContacts[i] = updatedItem;
        }
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaContacts);
  }

}
