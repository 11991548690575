<div class="row">

    <div class="col-12">

        <div class="table-responsive table-clickable">

            <table [small]="true" mdbTable>

                <thead>
                  <tr>
                    <!--<th><a (click)="sortBy('functionName')">Function Performed by the Application</a></th>
                    <th><a (click)="sortBy('mefEsaSupport')">Which MEFs or ESAs does it support</a></th>
                    <th><a (click)="sortBy('deadlineDesc')">Identify any Critical deadlines / time periods in the system Cycle</a></th>
                    <th><a (click)="sortBy('dependencyDesc')">Critical Dependencies or resources needed</a></th>
                    <th><a (click)="sortBy('dependentDesc')">Identify any functions or systems that are dependent on this function</a></th>
                    <th><a (click)="sortBy('criticalTimeDesc')">Critical time of day / month / year / project</a></th>-->
                    <th>Function<br />Performed by<br />the Application</th>
                    <th>Which MEFs<br />or ESAs does<br />it support</th>
                    <th>Identify any<br />Critical deadlines<br />/ time periods<br />in the system Cycle</th>
                    <th>Critical<br />Dependencies<br />or resources<br />needed</th>
                    <th>Identify any<br />functions or<br />systems that<br />are dependent<br />on this function</th>
                    <th>Critical time<br />of day / month<br />/ year / project</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor="let item of listItems; let i=index" (click)="onOpenItem(item, i)">

                        <td>{{item.functionName}}</td>
                        <td>{{item.mefEsaSupport}}</td>
                        <td>{{item.deadlineDesc}}</td>
                        <td>{{item.dependencyDesc}}</td>
                        <td>{{item.dependentDesc}}</td>
                        <td>{{item.criticalTimeDesc}}</td>
                        <td>
                            <button mdbBtn type="button" class="icon-btn"
                                (click)="onDeleteItem(item, i);$event.stopPropagation()">
                                <i class="fa fa-trash" aria-hidden="true" title="Delete Critical Process"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>

                <tfoot *ngIf="listItems?.length === 0">
                    <tr>
                        <td class="text-center no-results" colspan="7">No Critical Business Processes Available</td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>

</div>

<div class="row" style="padding-top: 25px;">
    <div class="col-12">
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
        <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>NEXT</button>
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>FINISH LATER</button>
        <button *ngIf="!pageService.viewMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Add Process"
            style="padding-right: 20px;" (click)="onAddItem()"> <i class="fa fa-plus" aria-hidden="true"></i> ADD
            PROCESS</button>
        <!-- <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
            (click)="stepService.onSaveForm()" mdbWavesEffect>SAVE</button> -->
        <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
    </div>
</div>
