import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BIADocumentModel } from '@app/bia/_models/bia-document-model';
import { DocumentFileTypeService } from '@app/_services/document-file-type.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaDocumentPageService } from './bia-document-page.service';

@Component({
  selector: 'app-bia-document-list',
  templateUrl: './bia-document-list.component.html',
  styleUrls: ['./bia-document-list.component.scss']
})
export class BiaDocumentListComponent implements OnInit {

  loading = true;
  fileLoading = false;

  sortDirection = 'ASC';
  biaTitle = '';

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private toastService: ToastService,
    private fileTypeService: DocumentFileTypeService,    
    private router: Router,
    private route: ActivatedRoute,
    private pageServiceBia: BiaListPageService,
    public pageService: BiaDocumentPageService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {

      if (params.biaId != undefined) {

        const biaId = +params.biaId;
        this.loading = true;

        this.pageService.getList(biaId).subscribe(result => {

          this.biaTitle = this.pageServiceBia.modelDto.bia.biaName;

          this.loading = false;

          if (result.statusId < 0) {
            this.toastService.error(result.message, 'Document List Error', this.toastMessageOptions);
          }
        })
      }
    });
  }

  getFileType(extension: string) {
    return this.fileTypeService.getType(extension);
  }

  onNavigateList(): void {
    this.router.navigate(['bia-list']);
  }

  onNavigateEdit(document: BIADocumentModel) {
    this.pageService.resetModel();

    this.pageService.onFileEdit(document.documentId).subscribe(result => {
      if (result) {        
        this.pageService.updateDocument().subscribe(saveResult => {
          if (saveResult.statusId < 0) {
            this.toastService.error(saveResult.message, 'Document Update Error', this.toastMessageOptions);
          }
        });
      }
    });
  }

  onNavigateAdd() {
    this.pageService.resetModel();
        
    this.pageService.onFileEdit(0).subscribe(result => {      
      if (result) {
        this.fileLoading = true;
        this.pageService.addDocument().subscribe(saveResult => {
          if (saveResult.statusId < 0) {
            this.toastService.error(saveResult.message, 'Document Add Error', this.toastMessageOptions);
          }
          this.fileLoading = false;
        });
      }
    });
  }

  onNavigateBIA() {
    this.pageService.resetModel();
    this.router.navigate(['bia'], { queryParams: { biaId: this.pageService.biaId } });
  }

  onDownload(document: BIADocumentModel) {

    let fileName = document.fileName.trim();
    this.fileLoading = true;

    this.pageService.download(document.documentId, fileName).subscribe(() => {
      this.fileLoading = false;
    });

  }

  onDelete(document: BIADocumentModel) {

    if (document === null) {
      return;
    }

    const doc = this.pageService.modelList.find(
      (a) => a.documentId === document.documentId
    );

    if (doc == null) {
      console.log(
        `Unable to find document with id ${document.documentId.toString()}`
      );
      return;
    }

    const index = this.pageService.modelList.indexOf(doc, 0);

    if (index < 0) {
      console.log(`Unable to find document with index ${index}`);
      return;
    }

    this.pageService.confirmDeleteDocument().subscribe(result => {

      if (result) {

        this.pageService.deleteDocument(doc).subscribe(deleteResult => {

          if (deleteResult.statusId < 0) {
            this.toastService.error(deleteResult.message, 'Document Delete Error', this.toastMessageOptions);
            return;
          }
          this.pageService.modelList.splice(index, 1);
        })
      }
    });
  }

  sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    
    if (columnName === 'NAME' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.fileName.localeCompare(b.fileName));

    } else if (columnName === 'NAME') {

      this.pageService.modelList.sort((a, b) => b.fileName.localeCompare(a.fileName));

    }

    else if (columnName === 'DESC' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.documentDesc.localeCompare(b.documentDesc));

    } else if (columnName === 'DESC') {

      this.pageService.modelList.sort((a, b) => b.documentDesc.localeCompare(a.documentDesc));

    }

    else if (columnName === 'TYPE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.documentType == null ? '' : a.documentType.documentTypeNm;
        const d = b.documentType == null ? '' : b.documentType.documentTypeNm;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'TYPE') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.documentType == null ? '' : a.documentType.documentTypeNm;
        const d = b.documentType == null ? '' : b.documentType.documentTypeNm;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'DATE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });

    } else if (columnName === 'DATE') {

      this.pageService.modelList.sort((b, a) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });
    }

  }
}

