import { Injectable } from '@angular/core';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaProcessListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIABusinessProcessModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'processName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.processName.localeCompare(b.processName));

    } else if (columnName === 'processName') {

      items.sort((a, b) => b.processName.localeCompare(a.processName));

    }

    else if (columnName === 'appName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => {
        const c = a.application == null ? '' : a.application.appName;
        const d = b.application == null ? '' : b.application.appName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'appName') {

      items.sort((a, b) => {
        const c = a.application == null ? '' : a.application.appName;
        const d = b.application == null ? '' : b.application.appName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'processDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.processDesc.localeCompare(b.processDesc));

    } else if (columnName === 'processDesc') {

      items.sort((a, b) => b.processDesc.localeCompare(a.processDesc));

    }
  }
}


