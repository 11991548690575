import { AppContactModel } from "./app-contact-model";
import { BusinessUnitModel } from "./business-unit-model";

export class ApplicationModel {
    public appId: number = 0;

    public appName: string = '';
    public appShortDesc: string = '';
    public appLongDesc: string = '';
    public appComments: string = '';

    public appVendorId: number = 0;
    public appVersion: string = '';
    public appTierId: number = 0;

    public appBuid: number = 615;
    public appOwnerId: string = '';
    public itTechContact: string = '';

    public ituseOnlyApp: boolean = true;
    public installInstruction: string = '';

    public sftwCategory: number = 0;
    public appCategory: number = 0;
    public availableVia: number = 0;
    public localCloud: number = 0;
    public itSupportType: number = 0;

    public appLastUpdateDate: Date = new Date();

    public isActive: boolean = true;
    public deactiveDate: Date = new Date();
    public deactiveReason: string = '';

    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

    public stepId: number = 1;

    public appOwnerList: string = '';
    public appContactList: string = '';

    public appContacts: Array<AppContactModel> = new Array<AppContactModel>();
    public businessUnit: BusinessUnitModel = new BusinessUnitModel();
}
