import { Component, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationModel } from '@app/_models/application-model';
import { AppContactModel } from '@app/_models/app-contact-model';
import { ToastService } from 'ng-uikit-pro-standard';
import { AppListPageService } from './app-list-page.service';
import { AppListSortService } from './app-list-sort.service';
import { LookupService } from '@app/_services/lookup.service';

@Component({
    selector: 'app-app-list',
    templateUrl: './app-list.component.html',
    styleUrls: ['./app-list.component.scss'],
    providers: [AppListSortService]
})
export class AppListComponent implements OnInit, AfterViewInit {

  loading = true;
  sortDirection = 'ASC';

  searchText: string = '';
  filteredList: Array<ApplicationModel> = new Array<ApplicationModel>();

  public appsList: Array<ApplicationModel> = new Array<ApplicationModel>();
  public contactsList: Array<AppContactModel> = new Array<AppContactModel>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private toastService: ToastService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private sortService: AppListSortService,
    private lookupService: LookupService,
    public pageService: AppListPageService) { }

  ngOnInit() {

    this.getApplicationList();
  }

  public getApplicationList() {

    this.pageService.getList().subscribe(result => {

      if (result.statusId < 0) {
        this.toastService.error(result.message, 'Application List Error', this.toastMessageOptions);
      }

      this.appsList = this.pageService.modelList;
      this.filteredList = this.appsList;

      this.lookupService.SetEmployeeList();

      this.loading = false;
    })

  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onFilterList(): void {

    let srchText = this.searchText.toUpperCase();

    if (srchText.length == 0) {

      this.filteredList = this.pageService.modelList;

    } else {

      this.filteredList = this.pageService.modelList.filter(
        (item) =>
        (
          item.appName.toUpperCase().includes(srchText)
          || ((item.appShortDesc != null) ? item.appShortDesc.toUpperCase().includes(srchText) : false)
          || ((item.businessUnit.userGroupName != null) ? item.businessUnit.userGroupName.toUpperCase().includes(srchText) : false)
          || ((item.appContactList != null) ? item.appContactList.toUpperCase().includes(srchText) : false)
        )
      );

    }
  }

  onNavigateApplication(applicationsList: ApplicationModel) {
    this.router.navigate(['app-edit'], { queryParams: { appId: applicationsList.appId.toString() } });
  }

  onNavigateAppAdd() {
    this.router.navigate(['app-edit'], { queryParams: { appId: '0' } });
  }

  onDeleteApp(argApplication: ApplicationModel) {

    if (argApplication === null) {
      return;
    }

    const application = this.pageService.modelList.find(
      (a) => a.appId === argApplication.appId
    );

    if (application == null) {
      console.log(
        `Unable to find application with name ${argApplication.appName}`
      );
      return;
    }

    const index = this.pageService.modelList.indexOf(application, 0);

    if (index < 0) {
      console.log(`Unable to find application with index ${index}`);
      return;
    }

    this.pageService.confirmDeleteApp().subscribe(result => {

      if (result) {

        this.pageService.deleteApplication(application).subscribe(deleteResult => {

          if (deleteResult.statusId < 0) {
            this.toastService.error(deleteResult.message, 'Application Delete Error', this.toastMessageOptions);
            return;
          }

          this.pageService.modelList.splice(index, 1);
        })
      }
    });
  }


  sortBy(columnName: string) {
    this.sortService.sortBy(columnName);
  }

}
