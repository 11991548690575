<div class="row">

    <div class="col-12">

        <div class="table-responsive table-clickable">

            <table [small]="true" mdbTable>

                <thead>
                  <tr>
                    <!--<th><a (click)="sortBy('applicationName')">Software/ Application/System</a></th>
                    <th><a (click)="sortBy('applicationDesc')">Description</a></th>
                    <th><a (click)="sortBy('backupPlan')">Is There a Documented Backup Plan? (Y/N)</a></th>
                    <th><a (click)="sortBy('downtimeDesc')">System Downtime</a></th>
                    <th><a (click)="sortBy('dataAgeDesc')">Age of Data</a></th>-->

                    <th>Software/ <br />Application/System</th>
                    <th>Description</th>
                    <th>Is There a Documented <br />Backup Plan? (Y/N)</th>
                    <th>System Downtime</th>
                    <th>Age of Data</th>



                    <th></th>
                  </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor="let item of listItems; let i=index" (click)="onOpenItem(item, i)">

                        <td>{{item.applicationName}}</td>
                        <td>{{item.applicationDesc}}</td>
                        <td>{{item.backupPlan ? 'Yes' : 'No'}}</td>
                        <td>{{item.downtimeDesc}}</td>
                        <td>{{item.dataAgeDesc}}</td>
                        <td>
                            <button mdbBtn type="button" class="icon-btn"
                                (click)="onDeleteItem(item, i);$event.stopPropagation()">
                                <i class="fa fa-trash" aria-hidden="true" title="Delete Application Dependency"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>

                <tfoot *ngIf="listItems?.length === 0">
                    <tr>
                        <td class="text-center no-results" colspan="6">No {{showOtherText()}}Application Dependencies are Available</td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>

</div>

<div class="row" style="padding-top: 25px;">
    <div class="col-12">
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
        <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>NEXT</button>
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>FINISH LATER</button>
        <button *ngIf="!pageService.viewMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Add Application Dependency"
            style="padding-right: 20px;" (click)="onAddItem()"> <i class="fa fa-plus" aria-hidden="true"></i> ADD
            {{showOtherText().toUpperCase()}}DEPENDENCY</button>
        <!-- <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
            (click)="stepService.onSaveForm()" mdbWavesEffect>SAVE</button> -->
        <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
    </div>
</div>
