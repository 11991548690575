import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from './config.service';
import { AppDocumentModel } from '@app/_models/app-document-model';
import { AppDocumentDto } from '@app/_models/app-document-dto';

@Injectable({
  providedIn: 'root'
})
export class AppDocumentService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  public getDocument(documentId: number): Observable<AppDocumentDto> {

    let requestURL = APP_CONFIG.apiDomain + '/appdocument/' + documentId.toString();

    return this.http
      .get<AppDocumentDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getDocumentList(appId: number): Observable<Array<AppDocumentModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/appdocument/list/' + appId.toString();

    return this.http
      .get<Array<AppDocumentModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public addDocument(formData: FormData): Observable<AppDocumentModel> {

    const requestURL = APP_CONFIG.apiDomain + '/appdocument/add';

    return this.http.post<AppDocumentModel>(requestURL, formData).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public updateDocument(model: AppDocumentModel): Observable<AppDocumentModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/appdocument/update';

    return this.http.post<AppDocumentModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public delete(model: AppDocumentModel): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/appdocument/delete';

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }
    
  public download(documentId: number): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/appdocument/download/' + documentId.toString();

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}

