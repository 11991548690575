import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-help-page-modal',
    templateUrl: './help-page-modal.component.html',
    styleUrls: ['./help-page-modal.component.scss']
})
export class HelpPageModalComponent implements OnInit {

    title!: string;
    bodyHtml!: string;

    constructor(public modalRef: MDBModalRef) {}

    ngOnInit(): void {

    }

    closeForm() {
        this.modalRef.hide();
    }
}
