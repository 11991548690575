import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { EmployeeModel } from '@app/_models/employee-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { WellnessLookupService } from '@app/wellness/_services/wellness-lookup.service';
import { Observable } from 'rxjs';
import { DocumentFileTypeService } from '@app/_services/document-file-type.service';
import { UserBoosterModel } from '@app/wellness/_models/user-booster-model';
import { UserVaccineModel } from '@app/wellness/_models/user-vaccine-model';
import { UserVaccineAdminService } from '@app/wellness/_services/user-vaccine-admin.service';
import { UserVaccineService } from '@app/wellness/_services/user-vaccine.service';
import { WellnessUserService } from '@app/wellness/_services/wellness-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserVaccinePageService {

  private _adminView = false;
  private _model = new UserVaccineModel();

  constructor(
    private location: Location,
    private fileTypeService: DocumentFileTypeService,
    private userService: WellnessUserService,
    private vaccineService: UserVaccineService,
    private vaccineAdminService: UserVaccineAdminService,
    private lookupService: WellnessLookupService) { }

  public get vaccine(): UserVaccineModel {
    if (this._model == null) {
      console.log("UserService: Missing vaccine");
      return new UserVaccineModel();
    }

    return this._model;
  }

  public set vaccine(model: UserVaccineModel) {
    
    this._model = model;

    if (this._model.boosters == undefined) {
      this._model.boosters = new Array<UserBoosterModel>();
    }

    this._adminView = false;
  }

  public set adminView(value: boolean) {
    this._adminView = value;
  }

  public get adminView() {
    return this._adminView;
  }

  public get cardUploaded(): boolean {
    return this._model.vaccineNameId > 0 && this._model.isFinal;
  }

  public get cardMissing(): boolean {
    return this._model.vaccineNameId > 0 && !this._model.isFinal;
  }

  public get accommodationUploaded(): boolean {
    return this._model.accommodationTypeId > 0;
  }

  public get cached(): boolean {
    return this.userService.cached;
  }

  public get user(): EmployeeModel {
    return this.userService.user;
  }

  public showOneDose(vaccineNameId: number): boolean {
    return (vaccineNameId === 3);
  }

  public showTwoDoses(vaccineNameId: number): boolean {
    return !this.showOneDose(vaccineNameId);
  }

  public isSecondDoseRequired(vaccineNameId: number): boolean {
    return (vaccineNameId === 1) || (vaccineNameId === 2);
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getStatusOptions(): Array<any> {

    let result = new Array<any>();

    this.lookupService.userVaccineStatusTypes.forEach((s) => {
      result.push({
        value: s.userVaccineStatusId,
        label: s.userVaccineStatusNm,
      });
    });

    return result;
  }

  public getFileType(): string {
    return this.fileTypeService.getType(this._model.document.fileExt);
  }

  public getDoseDate1Warning(doseDate: Date): string {

    const diff = this.calculateDiff(doseDate);

    if (diff > 0 && diff < 14) {

      const diff2 = 14 - diff;
      const dayStr = (diff2 == 1) ? "day" : "days";

      return 'First dose date is ' + diff2.toString() + ' ' + dayStr + ' short of 14 days';
    }

    return '';
  }

  public getDoseDate2Warning(doseDate: Date): string {

    const diff = this.calculateDiff(doseDate);

    if (diff > 0 && diff < 14) {
      const diff2 = 14 - diff;
      const dayStr = (diff2 == 1) ? "day" : "days";

      return 'Second dose date is ' + diff2.toString() + ' ' + dayStr + ' short of 14 days';
    }

    return '';
  }

  public calculateDiff(doseDate: Date) {

    const year1 = doseDate.getFullYear();
    const month1 = doseDate.getMonth();
    const day1 = doseDate.getDate();

    let currentDate = new Date();

    const year2 = currentDate.getFullYear();
    const month2 = currentDate.getMonth();
    const day2 = currentDate.getDate();

    if (doseDate > currentDate) {
      return 0;  // if future date do not show warning message, will be caught on form validation check
    }

    return Math.floor((Date.UTC(year2, month2, day2) - Date.UTC(year1, month1, day1)) / (1000 * 60 * 60 * 24));
  }

  public downloadNewFile(): void { }

  public downloadFile(): void {
    if (this._adminView) {
      this.vaccineAdminService.getVaccineFile(this._model.userVaccineId, this._model.document.fileName);
    } else {
      this.vaccineService.getVaccineFile(this._model.document.fileName);
    }
  }

  public downloadBoosterFile(boosterId: number): void {
    if (this._adminView) {
      this.vaccineAdminService.getBoosterFile(boosterId, this._model.document.fileName);
    } else {
      this.vaccineService.getBoosterFile(boosterId, this._model.document.fileName);
    }
  }
  
  public deleteBooster(boosterId: number): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.vaccineService.deleteBooster(boosterId).subscribe(apiResult => {

        if (apiResult){
          observer.next(this.getSaveResult(3, 300, '', apiResult));
        } else {
          observer.next(this.getSaveResult(3, -300, 'API booster delete failed', null));
        }        
      },
        (error: string) => {
          observer.next(this.getSaveResult(3, -300, error, null));
        }
      );

    });

    return result;
  }

  public saveVaccine(includeFile: boolean): Observable<ServiceResultModel> {

    if (includeFile) {
      return this.saveVaccineFinal();
    }

    return this.saveVaccineWithoutFile();
  }

  public saveVaccineWithoutFile(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      const saveTypeId = (this._model.vaccineNameId > 0) ? 1 : 2;

      this.vaccineService.saveVaccine(this._model).subscribe(v => {

        this.vaccine = v;
        this.userService.vaccineDto.vaccine = v;

        observer.next(this.getSaveResult(saveTypeId, 200, '', v));
      },
        (error: string) => {
          observer.next(this.getSaveResult(saveTypeId, -100, error, null));
        }
      );

    });

    return result;
  }

  public saveVaccineFinal(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      const saveTypeId = (this._model.vaccineNameId > 0) ? 1 : 2;

      var formData = new FormData();
      var docJson = JSON.stringify(this._model);

      formData.append(this._model.file.name, this._model.file);
      formData.append(docJson, docJson);

      this.vaccineService.saveVaccineFinal(formData).subscribe((v) => {
        this.vaccine = v;
        this.userService.vaccineDto.vaccine = v;
        observer.next(this.getSaveResult(saveTypeId, 200, '', v));
      },
        (error: string) => {
          observer.next(this.getSaveResult(saveTypeId, -200, error, null));
        }
      );

    });

    return result;
  }

  public addBooster(model: UserBoosterModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      var formData = new FormData();
      var docJson = JSON.stringify(model);

      formData.append(model.file.name, model.file);
      formData.append(docJson, docJson);

      this.vaccineService.addBooster(formData).subscribe((v) => {
        observer.next(this.getSaveResult(3, 200, '', v));
      },
        (error: string) => {
          observer.next(this.getSaveResult(3, -200, error, null));
        }
      );

    });

    return result;
  }

  private getSaveResult(typeId: number, codeId: number, error: string, result: any): ServiceResultModel {

    let typeName: string; 
    
    if (typeId == 1) {
      typeName = "Vaccine card";

    } else if (typeId == 2) {
      typeName = "Accommodation request";

    } else if (typeId == 3) {
      typeName = "booster vaccine record";

    } else {
      typeName = "???";
    }

    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' submission error: ' + error, result) };
    if (codeId == -300) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully submitted", result) };
    if (codeId == 300) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }
}
