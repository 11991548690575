import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '../../_services/config.service';
import { BIADocumentDto } from '../_models/bia-document-dto';
import { BIADocumentModel } from '../_models/bia-document-model';

@Injectable({
  providedIn: 'root'
})
export class BiaDocumentService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  public getDocument(documentId: number): Observable<BIADocumentDto> {

    let requestURL = APP_CONFIG.apiDomain + '/biadocument/' + documentId.toString();

    return this.http
      .get<BIADocumentDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getDocumentList(biaId: number): Observable<Array<BIADocumentModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/biadocument/list/' + biaId.toString();

    return this.http
      .get<Array<BIADocumentModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public addDocument(formData: FormData): Observable<BIADocumentModel> {

    const requestURL = APP_CONFIG.apiDomain + '/biadocument/add';

    return this.http.post<BIADocumentModel>(requestURL, formData).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public updateDocument(model: BIADocumentModel): Observable<BIADocumentModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/biadocument/update';

    return this.http.post<BIADocumentModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public delete(model: BIADocumentModel): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/biadocument/delete';

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }
    
  public download(documentId: number): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/biadocument/download/' + documentId.toString();

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}

