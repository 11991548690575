import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@app/_services/config.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationDto } from '../_models/application-dto';
import { ApplicationModel } from '../_models/application-model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  public getApplication(appId: number): Observable<ApplicationDto> {

    let requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/' + appId.toString();

    return this.http
      .get<ApplicationDto>(requestURL)
      .pipe((x: any) => {
        return x;
      }, catchError(this.handleError));
  }

  public getApplicationNew(): Observable<ApplicationDto> {

    let requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/new';

    return this.http
      .get<ApplicationDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getApplicationList(): Observable<Array<ApplicationModel>> {

    let requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/list';

    return this.http
      .get<Array<ApplicationModel>>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  //public getAppListByUser(empId: string): Observable<Array<ApplicationModel>> {

  //  let requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/userlist/' + empId;

  //  return this.http
  //    .get<Array<ApplicationModel>>(requestURL)
  //    .pipe(x => {
  //      return x;
  //    }, catchError(this.handleError));
  //}

  public saveApplication(model: ApplicationModel): Observable<ApplicationModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/save';

    return this.http.post<ApplicationModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public deleteApplication(model: ApplicationModel): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/delete';

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public deleteAppItem(model: any, itemRoute: string): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/ApplicationsList/delete-' + itemRoute;

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }

}
