<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('resourceCategory')?.errors?.required">
                                        Missing the recovery priority of the system resource.
                                    </li>
                                    <li *ngIf="biaForm?.get('resourceType')?.errors?.required">
                                        Missing the recovery time objective of the system resource.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Priority -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <mdb-select formControlName="priorityType" [options]="optionsPriority"
                                    placeholder="Please Select" class="form-control-mdb">
                                </mdb-select>
                                <label>Priority</label>
                            </div>
                        </div>
                    </div>

                    <!-- Name -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="resourceName" class="form-control"
                                readonly maxlength="500" />
                                <label>Name</label>
                            </div>
                        </div>
                    </div>

                    <!-- RTO -->
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rto"
                                    maxlength="10" />
                                <label>Recovery Time Objective (Hours)</label>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rtoDesc"
                                    maxlength="1000" />
                                <label>RTO Description</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>


        </app-spinner>
    </div>
</form>
