import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BIABackupStrategyModel } from '@app/bia/_models/bia-backup-strategy-model';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-backup-strategy-edit',
  templateUrl: './bia-backup-strategy-edit.component.html',
  styleUrls: ['./bia-backup-strategy-edit.component.scss']
})
export class BiaBackupStrategyEditComponent implements OnInit {

  paramItem: BIABackupStrategyModel = new BIABackupStrategyModel();
  onUpdateItem: Subject<BIABackupStrategyModel> = new Subject<BIABackupStrategyModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Backup Strategy';

  biaForm!: FormGroup;

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      backupTypeDesc: ['', Validators.required],
      scheduleDesc: [''],
    });

    this.loadPage();
  }

  get backupTypeDesc(): any {
    return this.biaForm.get('backupTypeDesc');
  }

  get scheduleDesc(): any {
    return this.biaForm.get('scheduleDesc');
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.backupTypeDesc.setValue(this.paramItem.backupTypeDesc);
    this.scheduleDesc.setValue(this.paramItem.scheduleDesc);
    
    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.backupTypeDesc = this.backupTypeDesc.value;
    this.paramItem.scheduleDesc = this.scheduleDesc.value;

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}


