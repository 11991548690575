import { Component, OnInit } from '@angular/core';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { IBIAStepComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaRecoveryOrderEditComponent } from '../bia-recovery-order-edit/bia-recovery-order-edit.component';
import { BiaRecoveryOrderListSortService } from './bia-recovery-order-list-sort.service';

@Component({
   selector: 'app-bia-recovery-order-list',
   templateUrl: './bia-recovery-order-list.component.html',
   styleUrls: ['./bia-recovery-order-list.component.scss'],
   providers: [ BIAEditStepListService, BiaRecoveryOrderListSortService ]
})
export class BiaRecoveryOrderListComponent implements OnInit, IBIAStepComponent {

  isDirty = false;
  pageIndex = 12;

  saving = false;

  public listItems: Array<BIAResourceModel> = new Array<BIAResourceModel>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaRecoveryOrderListSortService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {    
    this.listItems = this.pageService.modelDto.bia.biaResources.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  onOpenItem(biaItem: BIAResourceModel, i: number): void {

    const mf = this.modalService.show(BiaRecoveryOrderEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIAResourceModel) => {
        this.pageService.modelDto.bia.biaResources[i] = updatedItem;
        this.pageService.modelDto.bia.biaResources.sort((a, b) => a.sortOrder - b.sortOrder);
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaResources);
  }
}
