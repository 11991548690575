<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('backupTypeDesc')?.errors?.required">
                                        Missing the type of backup.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Backup Type -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="backupTypeDesc" class="form-control"
                                    maxlength="500" />
                                    <label>Backup Type</label>
                            </div>
                        </div>
                    </div>

                    <!-- Backup Schedule Frequency -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="scheduleDesc" class="form-control"
                                    maxlength="500" />
                                    <label>Backup Schedule/Frequency</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>

        </app-spinner>
    </div>
</form>
