import { Injectable } from '@angular/core';
import { BIADependencyModel } from '@app/bia/_models/bia-dependency-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaDependencyListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIADependencyModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';   

    if (columnName === 'applicationName' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.applicationName.localeCompare(b.applicationName));

    } else if (columnName === 'applicationName') {

      items.sort((a, b) => b.applicationName.localeCompare(a.applicationName));

    }

    else if (columnName === 'applicationDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.applicationDesc.localeCompare(b.applicationDesc));

    } else if (columnName === 'applicationDesc') {

      items.sort((a, b) => b.applicationDesc.localeCompare(a.applicationDesc));

    }

    else if (columnName === 'backupPlan' && this.sortDirection === 'ASC') {

      items.sort((a, b) => (a.backupPlan ? 1 : 0) - (b.backupPlan ? 1 : 0));

    } else if (columnName === 'backupPlan') {

      items.sort((a, b) => (b.backupPlan ? 1 : 0) - (a.backupPlan ? 1 : 0));

    }

    else if (columnName === 'downtimeDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.downtimeDesc.localeCompare(b.downtimeDesc));

    } else if (columnName === 'downtimeDesc') {

      items.sort((a, b) => b.downtimeDesc.localeCompare(a.downtimeDesc));

    }
    
    else if (columnName === 'dataAgeDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.dataAgeDesc.localeCompare(b.dataAgeDesc));

    } else if (columnName === 'dataAgeDesc') {

      items.sort((a, b) => b.dataAgeDesc.localeCompare(a.dataAgeDesc));

    }

  }
}

