import { Injectable } from '@angular/core';
import { DialogResult } from '@app/_models/dialog-result-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ConfirmModalComponent } from '@app/_components/confirm-modal/confirm-modal.component';
import { UserRoleService } from '@app/_services/user-role.service';
import { AdministratorDto } from '@app/_models/administrator-dto';
import { UserRoleModel } from '@app/_models/user-role-model';
import { UserRoleEditComponent } from '../user-role-edit/user-role-edit.component';

@Injectable({
  providedIn: 'root'
})
export class UserRolePageService {

  private _modelList = new Array<UserRoleModel>();
  private _modelDto = new AdministratorDto();

  public filteredUsers: Array<UserRoleModel> = new Array<UserRoleModel>();

  private _cachedList = false;

  constructor(
    private biaService: UserRoleService,
    private modalService: MDBModalService,
    private location: Location) {
  }

  public get modelDto(): AdministratorDto {
    return this._modelDto;
  }

  public get modelList(): Array<UserRoleModel> {
    return this._modelList;
  }

  public resetModel() {
    this._modelDto = new AdministratorDto();
    this._modelList = new Array<UserRoleModel>();
  }

  public navigateBack(): void {
    this.location.back();
  }

  public getList(): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      if (this._cachedList) {

        observer.next(this.getDataServiceResult(10, '', null));

      } else {

        this.biaService.getList().subscribe((dto) => {

          this._modelDto = dto;
          this._modelList = dto.userRoles;

          this._cachedList = true;

          observer.next(this.getDataServiceResult(10, '', null));
        },
          (error: string) => {
            observer.next(this.getDataServiceResult(-10, error, null));
          }
        );
      }
    });

    return result;
  }

  public saveUser(model: UserRoleModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.save(model).subscribe((user) => {

        if (user) {
          model = user;

          this.refeshList().subscribe(refreshResult => {

            if (refreshResult.result >= 0) {
              observer.next(this.getDataServiceResult(100, '', user));
            }
          },
            (error: string) => {
              observer.next(this.getDataServiceResult(-10, error, null));
            }
          );

        } else {
          observer.next(this.getDataServiceResult(-100, 'Error saving user role - user role was not returned by API', null));
        }

      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-100, error, null));
        }
      );

    });

    return result;
  }

  public refeshList(): Observable<ServiceResultModel> {
    this._cachedList = false;
    return this.getList();
  }

  public onEditItem(item: UserRoleModel, index: number): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      const ud = this.modalService.show(UserRoleEditComponent, {
        class: 'modal-md',
        data: {
          paramItem: item
        },
      });

      ud.content.onUpdateItem.subscribe(
        (updateItem: UserRoleModel) => {
          this._modelList[index] = updateItem;
          observer.next(true);
        }
      );
    });

    return result;
  }

  public deleteItem(item: UserRoleModel): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      this.biaService.delete(item).subscribe(apiResult => {

        if (apiResult) {
          observer.next(this.getDataServiceResult(300, '', apiResult));
        } else {
          observer.next(this.getDataServiceResult(-300, 'API user role delete failed', null));
        }
      },
        (error: string) => {
          observer.next(this.getDataServiceResult(-300, error, null));
        }
      );

    });

    return result;
  }

  public confirmDeleteUser(): Observable<boolean> {
    return this.confirmDelete('Are you sure you want to remove this user role? ');
  }

  private confirmDelete(message: string): Observable<boolean> {

    const result: Observable<boolean> = new Observable((observer) => {

      let modal = this.modalService.show(ConfirmModalComponent, {
        class: 'modal-md',
        data: {
          title: 'Please Confirm',
          body: message
        },
      });

      modal.content.dialogResult.subscribe(
        (dialogResult: DialogResult) => {
          if (dialogResult.action.toLowerCase() === 'confirm') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }
      );

    });

    return result;
  }

  private getDataServiceResult(codeId: number, error: string, result: any): ServiceResultModel {

    let typeName = 'Business impact analysis';

    if (codeId == -10) { return new ServiceResultModel(codeId, typeName + ' list retrieval error: ' + error, result) };
    if (codeId == -100) { return new ServiceResultModel(codeId, typeName + ' save submission error: ' + error, result) };
    if (codeId == -200) { return new ServiceResultModel(codeId, typeName + ' update submission error: ' + error, result) };
    if (codeId == -300) { return new ServiceResultModel(codeId, typeName + ' delete submission error: ' + error, result) };

    if (codeId == 10) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully retrieved', result) };
    if (codeId == 100) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully saved", result) };
    if (codeId == 200) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + ' has been successfully updated', result) };
    if (codeId == 300) { return new ServiceResultModel(codeId, 'The ' + typeName.toLowerCase() + " has been successfully deleted", result) };

    return new ServiceResultModel(0, '', null);
  }

}

