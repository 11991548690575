import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { EmployeeModel } from '@app/_models/employee-model';
import { UserService } from '@app/_services/user.service';
import { MdbTableDirective, MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

interface ComboBoxItem {
    value: string; label: string;
}

@Component({
    selector: 'app-employee-picker',
    templateUrl: './employee-picker.component.html',
    styleUrls: ['./employee-picker.component.scss']
})
export class EmployeePickerComponent implements OnInit {
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective | undefined;

    elements: Array<any> = new Array<any>();
    filteredElements: Array<any> = new Array<any>();
    selectedElements: Array<ComboBoxItem> = new Array<ComboBoxItem>();
    returnElements: Subject<Array<ComboBoxItem>> = new Subject<Array<ComboBoxItem>>();
    firstElement: ComboBoxItem | undefined;
    preselectedElements: Array<ComboBoxItem> = new Array<ComboBoxItem>();
    excludeElements: Array<any> | undefined;

    searchText = '';
    previous = '';
    lookupValueType: string = '';
    lookupValueLabel: string = '';
    wasClicked = false;
    allowMultipleValues = false;
    loading = true;

    constructor(public modalRef: MDBModalRef,
        private userService: UserService
    ) { }

    @HostListener('input') oninput() {
        this.searchItems();
    }

    ngOnInit(): void {

        if (this.lookupValueLabel === undefined) { this.lookupValueLabel = this.lookupValueType; }

        switch (this.lookupValueType.toLowerCase()) {

            case 'employee-non-it':
                this.getNonITEmployees();
                break;

            case 'employee':
                this.getEmployees();
                break;
        }
    }

    searchItems() {

        if (this.searchText.length === 0) {
            this.filteredElements = this.elements;
        }
        else {
            this.filteredElements =
                this.elements
                    .filter(e =>
                        e.label.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)
                    .sort((a, b) =>
                        a.label.localeCompare(b.label)
                    );
        }

    }

    selectElement(e: any) {
        if (this.selectedElements.findIndex(t => t.value == e.value) == -1) {
            this.selectedElements.push(e);
        }
        if (this.allowMultipleValues === false) {
            this.returnSelectedElements();
        }
    }

    deselectElement(i: number) {
        this.selectedElements.splice(i, 1);
    }

    returnSelectedElements() {

        this.returnElements.next(this.selectedElements);

        this.modalRef.hide();
    }

    onCancel() {
        this.modalRef.hide();
    }

    private getEmployees() {
        this.userService.GetEmployeeList().subscribe((r: EmployeeModel[]) => {

            this.elements = new Array<any>();

            if (this.firstElement) {
                const emp = new EmployeeModel();
                emp.empId = this.firstElement.value;
                emp.fullName = this.firstElement.label;
                this.elements.push({ emp, value: emp.empId, label: emp.fullName });
            }

            r.forEach((employee: EmployeeModel) => {
                this.elements.push({ employee, value: employee.empId, label: employee.fullName });
            });

            this.filteredElements = new Array<any>();

            if (this.preselectedElements !== undefined) {
                this.preselectedElements.forEach(e => {
                    this.selectedElements.push({ value: e.value, label: e.label });
                });
            }

            if (this.excludeElements === undefined) {
                this.filteredElements = this.elements;
            }
            else {

                this.elements.forEach(item => {
                    if (this.excludeElements != undefined) {
                        const i = this.excludeElements.findIndex(e => e.value === item.value);
                        if (i === -1) {
                            this.filteredElements.push(item);
                        }
                    }
                });

            }

            if (this.mdbTable !== undefined) {
                this.mdbTable.setDataSource(this.filteredElements);
                this.previous = this.mdbTable.getDataSource();            
            }

            this.loading = false;

        });
    }

    private getNonITEmployees() {

        this.userService.GetEmployeeList().subscribe((empList: EmployeeModel[]) => {

            this.elements = new Array<any>();

            if (this.firstElement) {
                const emp = new EmployeeModel();
                emp.empId = this.firstElement.value;
                emp.fullName = this.firstElement.label;
                this.elements.push({ emp, value: emp.empId, label: emp.fullName });
            }

            empList.forEach((employee: EmployeeModel) => {
                if (!this.userService.isIT(employee)) {
                    this.elements.push({ employee, value: employee.empId, label: employee.fullName });
                }
            });

            this.filteredElements = new Array<any>();

            if (this.preselectedElements !== undefined) {
                this.preselectedElements.forEach(e => {
                    this.selectedElements.push({ value: e.value, label: e.label });
                });
            }

            if (this.excludeElements === undefined) {
                this.filteredElements = this.elements;
            }
            else {

                this.elements.forEach(item => {
                    if (this.excludeElements != undefined) {
                        const i = this.excludeElements.findIndex(e => e.value === item.value);
                        if (i === -1) {
                            this.filteredElements.push(item);
                        }
                    }
                });

            }

            if (this.mdbTable !== undefined) {
                this.mdbTable.setDataSource(this.filteredElements);
                this.previous = this.mdbTable.getDataSource();
            }

            this.loading = false;
        });
    }
}

