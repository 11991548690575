<div class='wrap alt pb-0'>
    <div class='container'>

        <mdb-card class="mb-5">
            <mdb-card-body>

                <mdb-card-title>
                    <h3>
                        Administration
                    </h3>
                </mdb-card-title>

                <div *ngIf="!loading"  class="row search-row">
                    <div class="col-md-6 dashboard-search">
                        <select class="form-control sort-by z-depth-1" (change)="filterUserList()"
                            [(ngModel)]="filterTypeId">
                            <option *ngFor="let filter of optionsFilterType;" [value]="filter.value">
                                {{filter.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-6 md-form dashboard-search">
                        <mdb-icon fas icon="search" class="prefix fa"></mdb-icon>
                        <input type="text" class="form-control z-depth-1" [(ngModel)]="searchText"
                            (input)="filterUserList()" placeholder="Search Keyword">
                    </div>
                </div>

                <div *ngIf="loading" class="row loading-row">
                    <div>                
                        <div class="spinner-border text-info segal-spinner full-page" role="status"></div>
                    </div>
                </div>
                
                <div *ngIf="!loading" class="row">
                    <div class="col">
                        <div class="table-responsive table-clickable">
                            <table mdbTable>

                                <thead>
                                    <tr>
                                        <th scope="col"></th>

                                        <th>
                                            <a (click)="sortBy('user')">Name</a>
                                        </th>

                                        <th class="desktop-show" scope="col">File</th>

                                        <th class="desktop-show">
                                            <a (click)="sortBy('submit')">Submitted
                                            </a>
                                        </th>

                                        <th class="desktop-show">
                                            <a (click)="sortBy('type')">Type
                                            </a>
                                        </th>                                        
                                        <th class="desktop-show" scope="col">Status</th>
                                        <th class="desktop-show" scope="col">Email</th>
                                        <th class="desktop-show" scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr mdbTableCol *ngFor="let user of filteredUsers; let i=index">


                                        <!-- APPROVE OR DENY -->

                                        <td>
                                            <div *ngIf="user.statusId === 1 && user.accommodationTypeId > 0">
                                                <button mdbBtn type="button" class="icon-btn btn-segal-green"
                                                    (click)="approve(user);$event.stopPropagation()">
                                                    <i class="far fa-thumbs-up" aria-hidden="true"
                                                        title="Approve Accommodation"></i>
                                                </button>

                                                &nbsp;

                                                <button mdbBtn type="button" class="icon-btn btn-segal-orange"
                                                    (click)="deny(user);$event.stopPropagation()">
                                                    <i class="far fa-thumbs-down" aria-hidden="true"
                                                        title="Deny Accommodation"></i>
                                                </button>
                                            </div>
                                        </td>

                                        <!-- EMPLOYEE NAME -->

                                        <td>
                                            <a (click)="onNavigateUserVaccine(user)" href="javascript:void(0)">
                                                {{user.user.fullName}}
                                            </a>  
                                        </td>

                                        <!-- FILE DOWNLOAD -->

                                        <td class="desktop-show">
                                            <a (click)="onDownloadFile(user)">

                                                <i [ngClass]="getFileType(user)" aria-hidden="true"
                                                    title="View Document"></i>

                                            </a>
                                        </td>

                                        <!-- SUBMITTED -->

                                        <td class="desktop-show">
                                            {{user.createdDt | date}}
                                        </td>

                                        <!-- TYPE OF RECORD (VACCINE, ACCOMMODATION) -->

                                        <td class="desktop-show">
                                            {{vaccineRecordTypeName(user)}}
                                        </td>

                                        <!-- STATUS NAME -->

                                        <td class="desktop-show">
                                            {{user.status.userVaccineStatusNm}}

                                            <i *ngIf="showDoseDateWarning(user).length > 0" class="fas fa-exclamation-circle" style="color:#E65300" aria-hidden="true"
                                                    [title]="showDoseDateWarning(user)"></i>
                                        </td>

                                        <!-- EMAIL -->

                                        <td class="desktop-show">
                                            {{user.user.email}}
                                        </td>


                                        <!-- OPEN RECORD -->

                                        <!-- <td style="width: 100%;">
                                            <a (click)="onNavigateUserVaccine(user); $event.stopPropagation()">
                                                <i class="fas fa-edit" style="color:black;"></i>
                                            </a>                                            
                                        </td> -->


                                    </tr>
                                </tbody>

                                <tfoot *ngIf="filteredUsers.length === 0">
                                    <tr>
                                        <td class="text-center no-results desktop-show" colspan="7">No User Records Found</td>
                                        <td class="text-center no-results mobile-show" colspan="2">No User Records Found</td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                </div>

            </mdb-card-body>
        </mdb-card>

    </div>
</div>

<app-copyright [loading]=loading></app-copyright>
  