import { Injectable } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpPageModalComponent } from '@app/_components/help-page-modal/help-page-modal.component';

@Injectable({
    providedIn: 'root'
})
export class HelpPageService {

    public error: any;
    modalRefHelp!: MDBModalRef;
    public fieldHelpValues: any;

    constructor(
        public modalService: MDBModalService,
        public http: HttpClient) { }

    public getFieldHelpValues(helpFileName: string): Observable<boolean> {

        const result: Observable<boolean> = new Observable((observer) => {

            this.http.get('./assets/help/' + helpFileName).subscribe(helpFields => {
                this.fieldHelpValues = helpFields;
                observer.next(true);
            },
            (error) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this.error = error;
                observer.next(false);
            });
        });

        return result;
    }

    public getFieldText(fieldName: string) {
        const fieldHelp = this.fieldHelpValues.find((i: { id: string; }) => i.id === fieldName);

        if (fieldHelp === undefined) {
            return '???';
        }

        return fieldHelp.value;
    }

    public showHelpText(helpFileName: string, helpTitle: string, fullHeight: boolean) {

        this.http.get('./assets/help/' + helpFileName, { responseType: 'text' }).subscribe(helpHtml => {

            const modalOptions = {
                backdrop: true,
                keyboard: true,
                focus: true,
                show: false,
                ignoreBackdropClick: false,
                class: fullHeight ? 'modal-full-height modal-right' : 'modal-side modal-top-right',
                containerClass: 'right',
                animated: true,
                data: {
                    title: helpTitle,
                    bodyHtml: helpHtml
                }
            };

            this.modalRefHelp = this.modalService.show(HelpPageModalComponent, modalOptions);
        });
    }

}
