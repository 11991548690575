import { BIARecoveryPriorityModel } from "./bia-recovery-priority-model";
import { BIAResourceCategoryModel } from "./bia-resource-category-model";

export class BIAResourceModel {

    public resourceId: number = 0;
    public biaId: number = 0;
    
    public resourceName: string = '';        
    public resourceTypeName: string = '';
    public resourceDesc: string = '';
    public impactResourceCatId: number = 0;
    public recoveryPriorityId: number = 0;
    public rto: number = 0;
    public rtodesc: string = '';
    public sortOrder: number = 0;

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

    public recoveryPriority: BIARecoveryPriorityModel = new BIARecoveryPriorityModel();
    public resourceCategory: BIAResourceCategoryModel = new BIAResourceCategoryModel();
}
