
import { Injectable } from '@angular/core';
import { AppContactModel } from '@app/_models/app-contact-model';
import { AppListPageService } from '../app-list/app-list-page.service';

@Injectable()
export class AppContactListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: AppListPageService) { }

  sortBy(columnName: string, list: AppContactModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    
    if (columnName === 'contactType' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.contactType;
        const d = b.contactType == null ? '' : b.contactType.contactType;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'contactType') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.contactType;
        const d = b.contactType == null ? '' : b.contactType.contactType;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'fullName' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'fullName') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return d.localeCompare(c);
      }
      )
    }

    //else if (columnName === 'contactEmail' && this.sortDirection === 'ASC') {

    //  list.sort((a, b) => (a.contactEmail ? 1 : 0) - (b.contactEmail ? 1 : 0));

    //} else if (columnName === 'contactEmail') {

    //  list.sort((a, b) => (b.contactEmail ? 1 : 0) - (a.contactEmail ? 1 : 0));

    //}

    else if (columnName === 'email' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'email') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return d.localeCompare(c);
      }
      )
    }

  }
}

