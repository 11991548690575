import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@app/_services/config.service';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {  

  constructor(private router: Router,
    public userService: UserService) { }

  ngOnInit(): void {
  }

  navigateApplication(): void {
    void this.router.navigate(['app-list']);
  }

  public get reportUrl(): string {
    return APP_CONFIG.reportServerUrl;
  }

  navigateAdmin(): void {
    void this.router.navigate(['user-role-list']); 
  }

  navigateBia(): void {
    void this.router.navigate(['bia-list']); 
  }

  navigateReports(): void {

  }

  public isAdmin(): boolean {
    return this.userService.isAdmin;
  }
}
