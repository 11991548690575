import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { LookupValue } from '@app/_models/lookup-value';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-resource-edit',
  templateUrl: './bia-resource-edit.component.html',
  styleUrls: ['./bia-resource-edit.component.scss']
})
export class BiaResourceEditComponent implements OnInit {

  paramItem: BIAResourceModel = new BIAResourceModel();
  onUpdateItem: Subject<BIAResourceModel> = new Subject<BIAResourceModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'System Resource';
  typeLabel = 'Type';

  biaForm!: FormGroup;

  optionsCategory: Array<any> = new Array<any>();

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      resourceCategory: ['', Validators.required],
      resourceName: ['', Validators.required],
      resourceType: ['', Validators.required],
      resourceDesc: ['', Validators.required],
    });

    this.loadCategoryList();
    this.loadPage();
  }

  get resourceCategory(): any {
    return this.biaForm.get('resourceCategory');
  }

  get resourceName(): any {
    return this.biaForm.get('resourceName');
  }

  get resourceType(): any {
    return this.biaForm.get('resourceType');
  }

  get resourceDesc(): any {
    return this.biaForm.get('resourceDesc');
  }

  public onCategoryChange(event: any) {

    if (event !== undefined) {

      const typeId = +event.value;
      this.setTypeLabel(typeId);     
    }
  }

  setTypeLabel(typeId: number) {

    if (typeId === 1) {
      this.typeLabel = 'Hardware Type'

    } else if (typeId === 2) {
      this.typeLabel = 'Software Type (Platform/OS/Version)';

    }  else if (typeId === 3) {
      this.typeLabel = 'Type of File';

    } else {
      this.typeLabel = 'Type';
    }
  }

  loadCategoryList(): void {
    this.pageService.modelDto.resourceCategories.forEach(item => {
      this.optionsCategory.push(new LookupValue(item.resourceTypeId.toString(), item.resourceTypeName))
    });
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.resourceCategory.setValue(this.paramItem.impactResourceCatId.toString());
    this.resourceName.setValue(this.paramItem.resourceName);
    this.resourceType.setValue(this.paramItem.resourceTypeName);
    this.resourceDesc.setValue(this.paramItem.resourceDesc);

    this.setTypeLabel(+this.paramItem.impactResourceCatId);  

    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.impactResourceCatId = +this.resourceCategory.value;
    this.paramItem.resourceName = this.resourceName.value;
    this.paramItem.resourceTypeName = this.resourceType.value;
    this.paramItem.resourceDesc = this.resourceDesc.value;

    this.pageService.modelDto.resourceCategories.forEach(item => {
      if (item.resourceTypeId == this.paramItem.impactResourceCatId) {
        this.paramItem.resourceCategory = item;
      }
    });

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}

