
import { Injectable } from '@angular/core';
import { BIAContactModel } from '@app/bia/_models/bia-contact-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaContactListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  sortBy(columnName: string, list: BIAContactModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    
    if (columnName === 'pocTypeName' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.pocTypeName;
        const d = b.contactType == null ? '' : b.contactType.pocTypeName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'pocTypeName') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.pocTypeName;
        const d = b.contactType == null ? '' : b.contactType.pocTypeName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'fullName' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'fullName') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'criticalProcess' && this.sortDirection === 'ASC') {

      list.sort((a, b) => (a.criticalProcess ? 1 : 0) - (b.criticalProcess ? 1 : 0));

    } else if (columnName === 'criticalProcess') {

      list.sort((a, b) => (b.criticalProcess ? 1 : 0) - (a.criticalProcess ? 1 : 0));

    }

    else if (columnName === 'email' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'email') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return d.localeCompare(c);
      }
      )
    }

  }
}

