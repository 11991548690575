import { AccommodationTypeModel } from "./accommodation-type-model";
import { ContentTypeModel } from "../../_models/content-type-model";
import { UserDocumentTypeModel } from "./user-document-type-model";
import { UserVaccineStatusTypeModel } from "./user-vaccine-status-type-model";
import { VaccineTypeModel } from "./vaccine-type-model";

export class LookupDto {
    public accommodationTypes: Array<AccommodationTypeModel> = new Array<AccommodationTypeModel>();
    public contentTypes: Array<ContentTypeModel> = new Array<ContentTypeModel>();
    public userDocumentTypes: Array<UserDocumentTypeModel> = new Array<UserDocumentTypeModel>();
    public userVaccineStatusTypes: Array<UserVaccineStatusTypeModel> = new Array<UserVaccineStatusTypeModel>();
    public vaccineTypes: Array<VaccineTypeModel> = new Array<VaccineTypeModel>();
}
