import { APP_INITIALIZER, Injector, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
// HttpClient
import { HttpClientModule } from '@angular/common/http';
//
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { ConfigService } from './_services/config.service';
import { PreInitService, preInitServiceFactory } from './_services/pre-init.service';
import { MDBBootstrapModulesPro, MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';
import { MsalConfigDynamicModule } from './msal-config-dynamic.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NavBarComponent } from './_components/nav-bar/nav-bar.component';
import { WellnessLookupService } from './wellness/_services/wellness-lookup.service';
import { CopyrightComponent } from './_components/copyright/copyright.component';
import { LogoutComponent } from './_components/logout/logout.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { ConfirmModalComponent } from './_components/confirm-modal/confirm-modal.component';
import { FileUploadComponent } from './_components/file-upload/file-upload.component';
import { HelpPageModalComponent } from './_components/help-page-modal/help-page-modal.component';
import { AccommodationComponent } from './wellness/_components/accommodation/accommodation.component';
import { BoosterCardComponent } from './wellness/_components/booster-card/booster-card.component';
import { DocumentManagementComponent } from './wellness/_components/document-management/document-management.component';
import { UnvaccinatedComponent } from './wellness/_components/unvaccinated/unvaccinated.component';
import { UserScreenPageService } from './wellness/_components/user-screen/user-screen-page.service';
import { UserScreenComponent } from './wellness/_components/user-screen/user-screen.component';
import { UserVaccineAdminPageService } from './wellness/_components/user-vaccine-admin/user-vaccine-admin-page.service';
import { UserVaccineAdminComponent } from './wellness/_components/user-vaccine-admin/user-vaccine-admin.component';
import { UserVaccinePageService } from './wellness/_components/user-vaccine/user-vaccine-page.service';
import { UserVaccineComponent } from './wellness/_components/user-vaccine/user-vaccine.component';
import { VaccineCardComponent } from './wellness/_components/vaccine-card/vaccine-card.component';
import { UserScreenService } from './wellness/_services/user-screen.service';
import { UserVaccineAdminService } from './wellness/_services/user-vaccine-admin.service';
import { UserVaccineService } from './wellness/_services/user-vaccine.service';
import { WellnessUserService } from './wellness/_services/wellness-user.service';
import { UserRoleListComponent } from './_components/user-role-list/user-role-list.component';
import { UserRoleEditComponent } from './_components/user-role-edit/user-role-edit.component';
import { EmployeePickerComponent } from './_components/employee-picker/employee-picker.component';
import { UserService } from './_services/user.service';
import { LookupService } from './_services/lookup.service';
import { UserRolePageService } from './_components/user-role-list/user-role-page.service';

//Bia 
import { BiaAdditionalInfoComponent } from './bia/_components/bia-additional-info/bia-additional-info.component';
import { BiaBackupStrategyEditComponent } from './bia/_components/bia-backup-strategy-edit/bia-backup-strategy-edit.component';
import { BiaBackupStrategyListComponent } from './bia/_components/bia-backup-strategy-list/bia-backup-strategy-list.component';
import { BiaBusinessChangeEditComponent } from './bia/_components/bia-business-change-edit/bia-business-change-edit.component';
import { BiaBusinessChangeListComponent } from './bia/_components/bia-business-change-list/bia-business-change-list.component';
import { BiaBusinessFunctionEditComponent } from './bia/_components/bia-business-function-edit/bia-business-function-edit.component';
import { BiaBusinessFunctionListComponent } from './bia/_components/bia-business-function-list/bia-business-function-list.component';
import { BiaContactEditComponent } from './bia/_components/bia-contact-edit/bia-contact-edit.component';
import { BiaContactListComponent } from './bia/_components/bia-contact-list/bia-contact-list.component';
import { BiaDependencyEditComponent } from './bia/_components/bia-dependency-edit/bia-dependency-edit.component';
import { BiaDependencyListComponent } from './bia/_components/bia-dependency-list/bia-dependency-list.component';
import { BiaDocumentListComponent } from './bia/_components/bia-document-list/bia-document-list.component';
import { BiaDowntimeListComponent } from './bia/_components/bia-downtime-list/bia-downtime-list.component';
import { BiaDowntimeComponent } from './bia/_components/bia-downtime/bia-downtime.component';
import { BiaEditComponent } from './bia/_components/bia-edit/bia-edit.component';
import { BiaFileUploadComponent } from './bia/_components/bia-file-upload/bia-file-upload.component';
import { BiaListPageService } from './bia/_components/bia-list/bia-list-page.service';
import { BiaListComponent } from './bia/_components/bia-list/bia-list.component';
import { BiaNameComponent } from './bia/_components/bia-name/bia-name.component';
import { BiaProcessListComponent } from './bia/_components/bia-process-list/bia-process-list.component';
import { BiaProcessComponent } from './bia/_components/bia-process/bia-process.component';
import { BiaRecoveryEditComponent } from './bia/_components/bia-recovery-edit/bia-recovery-edit.component';
import { BiaRecoveryListComponent } from './bia/_components/bia-recovery-list/bia-recovery-list.component';
import { BiaRecoveryOrderEditComponent } from './bia/_components/bia-recovery-order-edit/bia-recovery-order-edit.component';
import { BiaRecoveryOrderListComponent } from './bia/_components/bia-recovery-order-list/bia-recovery-order-list.component';
import { BiaResourceEditComponent } from './bia/_components/bia-resource-edit/bia-resource-edit.component';
import { BiaResourceListComponent } from './bia/_components/bia-resource-list/bia-resource-list.component';

//App Inventory
import { AppListComponent } from './_components/app-list/app-list.component';
import { AppEditComponent } from './_components/app-edit/app-edit.component';
import { AppBasicInfoComponent } from './_components/app-basic-info/app-basic-info.component';
import { AppAdvanceInfoComponent } from './_components/app-advance-info/app-advance-info.component';
import { AppCommentsComponent } from './_components/app-comments/app-comments.component';
import { AppContactListComponent } from './_components/app-contact-list/app-contact-list.component';
import { AppContactEditComponent } from './_components/app-contact-edit/app-contact-edit.component';
import { AppDocumentListComponent } from './_components/app-document-list/app-document-list.component';
import { AppFileUploadComponent } from './_components/app-file-upload/app-file-upload.component';
import { BiaAppContactListComponent } from './bia/_components/bia-app-contact-list/bia-app-contact-list.component';
import { AppListPageService } from './_components/app-list/app-list-page.service';

@NgModule({
  declarations: [
    AppComponent,
    DocumentManagementComponent,
    NavBarComponent,
    VaccineCardComponent,
    AccommodationComponent,
    UserVaccineAdminComponent,
    UserVaccineComponent,
    CopyrightComponent,
    LogoutComponent,
    UnvaccinatedComponent,
    UserScreenComponent,
    ConfirmModalComponent,
    BoosterCardComponent,
    FileUploadComponent,

    AppListComponent,
    AppEditComponent,
    AppBasicInfoComponent,
    AppAdvanceInfoComponent,
    AppCommentsComponent,
    AppContactListComponent,
    AppContactEditComponent,
    AppDocumentListComponent,
    AppFileUploadComponent,

    BiaListComponent,
    BiaEditComponent,
    BiaListComponent,
    BiaEditComponent,
    BiaAdditionalInfoComponent,
    BiaProcessComponent,
    BiaNameComponent,
    BiaProcessListComponent,
    HelpPageModalComponent,
    BiaDowntimeComponent,
    BiaDowntimeListComponent,
    BiaResourceListComponent,
    BiaResourceEditComponent,
    BiaRecoveryListComponent,
    BiaRecoveryEditComponent,
    BiaContactListComponent,
    BiaContactEditComponent,
    BiaBusinessFunctionListComponent,
    BiaBusinessFunctionEditComponent,
    BiaDependencyListComponent,
    BiaDependencyEditComponent,
    BiaBusinessChangeListComponent,
    BiaBusinessChangeEditComponent,
    BiaBackupStrategyListComponent,
    BiaBackupStrategyEditComponent,
    BiaRecoveryOrderListComponent,
    BiaRecoveryOrderEditComponent,
    BiaDocumentListComponent,
    BiaFileUploadComponent,
    UserRoleListComponent,
    UserRoleEditComponent,
    EmployeePickerComponent,
    BiaAppContactListComponent
  ],
  imports: [    
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    MsalConfigDynamicModule.forRoot('/config.json'),
    MDBBootstrapModulesPro.forRoot(),
    NgxDropzoneModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    PreInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: preInitServiceFactory,
      deps: [PreInitService, Injector],
      multi: true
    },    
    ConfigService,
    MDBSpinningPreloader,
    UserService,
    LookupService,
    UserVaccineService,
    UserScreenService,
    UserScreenPageService,    
    WellnessUserService,
    WellnessLookupService,
    UserVaccineAdminService,
    UserVaccinePageService,
    UserVaccineAdminPageService,
    AppListPageService,
    BiaListPageService,
    UserRolePageService
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
