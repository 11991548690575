import { Injectable } from '@angular/core';
import { EmployeeModel } from '@app/_models/employee-model';
import { ServiceResultModel } from '@app/_models/error-code-model';
import { DocumentFileTypeService } from '@app/_services/document-file-type.service';
import { WellnessLookupService } from '@app/wellness/_services/wellness-lookup.service';
import { Observable } from 'rxjs';
import { UserVaccineAdminDto } from '@app/wellness/_models/user-vaccine-admin-dto';
import { UserVaccineModel } from '@app/wellness/_models/user-vaccine-model';
import { UserVaccineAdminService } from '@app/wellness/_services/user-vaccine-admin.service';
import { WellnessUserService } from '@app/wellness/_services/wellness-user.service';

@Injectable({
  providedIn: 'root'
})
export class UserVaccineAdminPageService {

  private _cached = false;
  private _model = new UserVaccineAdminDto();
  private _filterTypeId = '1';

  constructor(
    private vaccineAdminService: UserVaccineAdminService,
    private lookupService: WellnessLookupService,
    private fileTypeService: DocumentFileTypeService,
    private userService: WellnessUserService) { }

  public get users(): Array<UserVaccineModel> {
    if (this._model == null) {
      console.log("UserVaccineAdminPageService: Missing admin DTO model");
      return new Array<UserVaccineModel>();
    }

    return this._model.users;
  }

  public get user(): EmployeeModel {
    return this.userService.user;
  }

  public set filterTypeId(value: string) {
    this._filterTypeId = value;
  }

  public get filterTypeId(): string {
    return this._filterTypeId;
  }

  public getVaccineRecordTypeName(accommodationTypeId: number): string {
    return this.lookupService.GetVaccineRecordTypeName(accommodationTypeId);
  }

  public getUserVaccineAdminDto(): Observable<Array<UserVaccineModel>> {

    const result: Observable<Array<UserVaccineModel>> = new Observable((observer) => {

      if (this._cached) {

        observer.next(this._model.users);

      } else {

        this.vaccineAdminService.getVaccineAdminList().subscribe((userVaccineDto) => {

          this._model = userVaccineDto;
          this._cached = true;

          observer.next(this._model.users);
        });

      }

    });

    return result;
  }

  public downloadFile(model: UserVaccineModel): void {

    this.vaccineAdminService.getVaccineFile(model.userVaccineId, model.document.fileName);

    // this.vaccineService.getVaccineFile(model.userVaccineId).subscribe(blobResult => {      
    //   saveAs(blobResult, model.document.fileName);
    // })
  }

  public getFileType(user: UserVaccineModel): string {
    return this.fileTypeService.getType(user.document.fileExt);
  }

  updateUserStatus(model: UserVaccineModel, statusId: number): Observable<ServiceResultModel> {

    const result: Observable<ServiceResultModel> = new Observable((observer) => {

      model.statusId = statusId;
      model.status = this.lookupService.GetVaccineStatus(statusId);
      model.approvedBy = +this.userService.user.empId;
      model.approvedDt = new Date();

      this.vaccineAdminService.updateVaccineStatus(model).subscribe(v => {

        observer.next(new ServiceResultModel(100, "The user vaccine status has been successfully updated", v));
      },
        (error: string) => {
          observer.next(new ServiceResultModel(-100, 'Vaccine card update error: ' + error, null));
        }
      );
    

    });

    return result;
  }

}
