<mdb-navbar SideClass="navbar navbar-expand-xl navbar-light grey lighten-5 segal-nav" [containerInside]="false">

  <mdb-navbar-brand>
    <a class="navbar-brand" routerLink="/"><img src="assets/segal-logo-2020.svg" height="30" alt="Segal" style /></a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav ml-auto" style="margin-top: 5px;">

      <li *ngIf="loginDisplay" class="nav-item dropdown" dropdown>

        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <mdb-icon fas icon="user"></mdb-icon>
          {{userName}}
          <span class="caret"></span>
        </a>

        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">

          <a *ngIf="!loginDisplay" class="nav-link waves-light" mdbWavesEffect href="#" (click)="login()">
            <mdb-icon fas icon="sign-in-alt"></mdb-icon> Sign In
          </a>
          <a *ngIf="loginDisplay" class="nav-link waves-light" mdbWavesEffect routerLink="/logout"
            routerLinkActive="active">
            <mdb-icon fas icon="sign-out-alt"></mdb-icon> Sign Out
          </a>

        </div>
      </li>

      <li class="nav-item active">
        <a *ngIf="!loginDisplay" class="nav-link waves-light" mdbWavesEffect href="#" (click)="login()">
          <mdb-icon fas icon="sign-in-alt"></mdb-icon> Sign In
        </a>
      </li>

    </ul>
  </links>

</mdb-navbar>

<!-- Page Header -->
<div class='wrap alt pb-0'>
  <div class='container'>
    <div class='row'>
      <div class='col'>
        <h2>Disaster Recovery</h2>
      </div>
    </div>
  </div>
</div>

<app-nav-bar></app-nav-bar>

<div class="container">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">You have been idle</h4>
      </div>
      <div class="modal-body">
        {{idleState}}
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn class="waves-light btn-segal" aria-label="Close" (click)="onLogoutResponse()"
          mdbWavesEffect>Logout
        </button>
        <button type="submit" mdbBtn class="relative waves-light btn-segal" mdbWavesEffect
          (click)="onStayResponse()">Stay</button>
      </div>
    </div>
  </div>
</div>
