import { Injectable } from '@angular/core';
import { IAppStepListComponent } from '@app/_models/iappstep-component';
import { ToastService } from 'ng-uikit-pro-standard';
import { AppListPageService } from '../app-list/app-list-page.service';

@Injectable()
export class AppEditStepListService {

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  public stepComponent!: IAppStepListComponent;

  constructor(private toastService: ToastService,
    private pageService: AppListPageService) { }

  public setComponent(stepComponent: IAppStepListComponent) {
    this.stepComponent = stepComponent;
  }

  public onPriorStep(): void {
    this.pageService.onSetViewType(this.stepComponent.pageIndex - 1);
  }

  public onNextStep(): void {
    this.saveList(true, false);
  }

  public onSaveForm(): void {
    this.saveList(false, false);
  }

  public onSaveCloseForm(): void {
    this.saveList(false, true);
    this.pageService.navigateBack();
  }

  public onCloseForm(): void {

    if (this.stepComponent.isDirty) {
      this.pageService.confirmSaveChanges().subscribe(result => {
        if (result) {
          this.onSaveCloseForm();
          return;
        } else {
          this.closeForm();
        }
      });
    } else {
      this.closeForm();
    }    

  }

  private closeForm(): void {
    this.pageService.resetModel();
    this.pageService.navigateBack();
  }

  private saveList(
    nextStep: boolean,
    navigateBack: boolean) {

    if (this.stepComponent.saving) {
      return;
    }

    let lastStep = (this.stepComponent.pageIndex == AppListPageService.STEP_COUNT);

    if (nextStep) {

      if (lastStep) {

        this.pageService.modelDto.application.stepId = 0;
        this.stepComponent.isDirty = true;

      } else if (this.pageService.modelDto.application.stepId < this.stepComponent.pageIndex) {

        this.pageService.modelDto.application.stepId = this.stepComponent.pageIndex;
        this.stepComponent.isDirty = true;
      }

    }  

    if (!this.stepComponent.isDirty) {
      if (nextStep && !lastStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex + 1)
      }
      return;
    }

    this.stepComponent.saving = true;

    console.log("Application step list save start");

    let saveResult = false;

    this.pageService.saveApplication().subscribe(e => {

      if (e.statusId >= 0) {

        saveResult = true;

      } else {

        this.toastService.error(e.message, null, this.toastMessageOptions);
      }

      if (!saveResult) {
        return;
      }

      this.stepComponent.saving = false;

      console.log("Application step list save end");

      if (lastStep) {
        this.toastService.success('The Application has been completed.', 'Application Complete', this.toastMessageOptions);
        return;
      }

      if (nextStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex + 1);
        return;
      } else if (navigateBack) {
        this.pageService.navigateBack();
      }

      this.toastService.success('The Application section has been save.', 'Application', this.toastMessageOptions);
    })
  }
}
