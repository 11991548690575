<form [formGroup]="applicationForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>

        <app-spinner [isModal]="true">
          <div class="modal-body">
            <div class="container">

              <div class='row'>
                <div class='col'>
                  <div class="alert alert-danger" *ngIf="isSubmitted && (applicationForm?.invalid)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>
                      {{modalTitle}}
                      details could
                      not be saved. Please
                      fix the errors below.
                    </span>
                    <ul>
                      <li *ngIf="applicationForm?.get('contactType')?.errors?.required">
                        Missing the type of the contact.
                      </li>
                      <li *ngIf="applicationForm?.get('appContact')?.errors?.required">
                        Missing the selection of the contact.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Contact Type -->
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="md-form">
                    <mdb-select formControlName="contactType" [options]="optionsContactType"
                                placeholder="Please Select" class="form-control-mdb">
                    </mdb-select>
                    <label>Contact Type</label>
                  </div>
                </div>
              </div>

              <!-- Employee -->
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="md-form input-icons" (click)="onEmployeeSelect()">
                    <div class="icon1">
                      <i class="fa fa-bars icon"></i>
                    </div>
                    <input mdbInput type="text" class="form-control" placeholder="Please Select"
                           formControlName="appContact" readonly />
                    <label>Contact or Owner</label>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class="modal-footer">
            <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close"
                    (click)="onCancel()" mdbWavesEffect>
              Cancel
            </button>
            <button type="submit" mdbBtn [disabled]="applicationForm.invalid || applicationForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>
              Save
            </button>
          </div>


        </app-spinner>
    </div>
</form>
