import { Injectable } from '@angular/core';
import { UserRolePageService } from './user-role-page.service';

@Injectable()
export class UserRoleListSortService {

  private sortDirection = 'ASC';
  private sortColumn = 'NAME';

  constructor(public pageService: UserRolePageService) { }

  public sort() {
    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    this.sortBy(this.sortColumn);
  }

  public sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    this.sortColumn = columnName;

    if (columnName === 'NAME' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'NAME') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'BU' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.businessUnit;
        const d = b.employee == null ? '' : b.employee.businessUnit;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'BU') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.businessUnit;
        const d = b.employee == null ? '' : b.employee.businessUnit;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'TYPE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.roleType == null ? '' : a.roleType.roleTypeNm;
        const d = b.roleType == null ? '' : b.roleType.roleTypeNm;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'TYPE') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.roleType == null ? '' : a.roleType.roleTypeNm;
        const d = b.roleType == null ? '' : b.roleType.roleTypeNm;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'DATE' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });

    } else if (columnName === 'DATE') {

      this.pageService.modelList.sort((b, a) => {
        const c = a.createdOn == null ? new Date('01/01/1900') : new Date(a.createdOn);
        const d = b.createdOn == null ? new Date('01/01/1900') : new Date(b.createdOn);
        if (c < d) { return -1; }
        else if (c > d) { return 1; }
        else { return 0; }
      });
    }

  }
}
