<div class='wrap alt pb-0'>
  <div class='container'>

    <mdb-card class="mb-5">
      <mdb-card-body>

        <mdb-card-title>
          <h3>
            Business Impact Analysis {{filterByDesc}}
            <button *ngIf="isAdministrator()" mdbBtn type="button" class="btn-segal float-right"
              (click)="onNavigateBiaAdd()">
              <i class="fa fa-plus" aria-hidden="true"></i> Add
            </button>
            <button *ngIf="isAdministrator()" mdbBtn type="button" class="btn-segal float-right"
              (click)="onFilterList()">
              <i class="fa fa-filter" aria-hidden="true"></i> {{filterByName}}
            </button>
          </h3>
        </mdb-card-title>

        <div *ngIf="!loading" class="row search-row">
          <div class="col-md-6 md-form dashboard-search">
            <mdb-icon fas icon="search" class="prefix fa"></mdb-icon>
            <input type="text" class="form-control z-depth-1" [(ngModel)]="searchText" (input)="onSearchList()"
              placeholder="Search Keyword">
          </div>
        </div>

        <div *ngIf="loading" class="row loading-row">
          <div>
            <div class="spinner-border text-info segal-spinner full-page" role="status"></div>
          </div>
        </div>

        <div class="list-group list-group-flush bia-results" *ngIf="!loading">

          <div class="list-group-item waves-light bia" mdbWavesEffect *ngFor="let bia of filteredList"
            (click)="onNavigateBia(bia)">

            <div class="row align-bottom">
              <div class="col-12 pr-0" style="margin-bottom: 6px;">
                <b class='bia-name'>{{ bia.biaName }}</b>
              </div>
            </div>

            <div *ngIf="bia.biaBusinessProcess.length > 0">

              <div class="row m--8" *ngFor="let process of bia.biaBusinessProcess">

                <div class="col-8">
                  Application - Process:
                  <a class="medium m-0" href="javascript:void(0)"
                    (click)="onNavigateApplication(process.appId);$event.stopPropagation()">
                    {{ process.application.appName }} -  {{ process.processName }}
                  </a>
                  <div style="margin-left: 30px; margin-bottom: 10px;">
                    <p class="small m-0">Downtime: MTD: {{ process.mtdEstimate }}, RTO: {{ process.rtoEstimate }}, RPO: {{
                      process.rpoEstimate }}</p>
                  </div>
                </div>

                <div class="col-4 text-right align-bottom">
                  <p class="small m-0">Contacts: {{ process.contactList }}</p>
                </div>

              </div>
            </div>

          </div>

          <div *ngIf="filteredList?.length === 0">
            <div class="text-center no-results" style="background-color:#f5f5f5; height: 45px; padding-top: 15px;">
              No Business Impact Analysis Found
            </div>
          </div>

        </div>






        <!-- <div *ngIf="!loading" class="row">
          <div class="col">
            <div class="table-responsive table-clickable">

              <table mdbTable id="tableMain">

                <thead>
                  <tr>
                    <th><a (click)="sortBy('name')">Name</a></th>
                    <th><a (click)="sortBy('appName')">Application</a></th>
                    <th><a (click)="sortBy('buName')">Business Unit</a></th>
                    <th><a (click)="sortBy('contact')">Point Contact</a></th>
                  </tr>
                </thead>

                <tbody>
                  <tr mdbTableCol *ngFor="let bia of filteredList" (click)="onNavigateBia(bia)">

                    <td>{{bia.biaName}}</td>
                    <td>{{bia.biaApplicationList}}</td>
                    <td>{{bia.biaBusinessUnitList}}</td>
                    <td>{{bia.biaContactList}}</td>

                    <td class="text-right">
                      <button mdbBtn type="button" class="icon-btn"
                        (click)="onDeleteBia(bia); $event.stopPropagation()">
                        <i class="fa fa-trash" aria-hidden="true" title="Remove"></i>
                      </button>
                    </td>

                  </tr>
                </tbody>

                <tfoot *ngIf="filteredList.length === 0">
                  <tr>
                    <td class="text-center no-results" colspan="5">
                      No Business Impact Analysis Found
                    </td>
                  </tr>
                </tfoot>

              </table>
            </div>
          </div>
        </div> -->



      </mdb-card-body>
    </mdb-card>

  </div>
</div>