<div class="row">
  <div class="col-12">

    <div class="table-responsive table-clickable">

      <table mdbTable>

        <thead>
          <tr>
            <th><a (click)="sortBy('contactType')">Point of Contact</a></th>
            <th><a (click)="sortBy('fullName')">Contact Name</a></th>
            <th><a (click)="sortBy('email')">Contact Email</a></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr mdbTableCol *ngFor="let c of this.pageService.modelDto.application.appContacts; let i=index" (click)="onOpenContact(c, i)">

            <td>{{c.contactType == null ? '???' : c.contactType.contactType}}</td>
            <td>{{c.employee == null ? '???' : c.employee.fullName}}</td>
            <td>{{c.employee.email}}</td>
            <td>
              <button mdbBtn type="button" class="icon-btn"
                      (click)="onDeleteContact(c, i);$event.stopPropagation()">
                <i class="fa fa-trash" aria-hidden="true" title="Delete Contact"></i>
              </button>
            </td>

          </tr>
        </tbody>

        <tfoot *ngIf="this.pageService.modelDto.application.appContacts?.length === 0">
          <tr>
            <td class="text-center no-results" colspan="5">No Contacts Available</td>
          </tr>
        </tfoot>
      </table>

    </div>

  </div>
</div>

<div class="row" style="padding-top: 25px;">
  <div class="col-12">
    <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>
      PRIOR
    </button>
    <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>
      NEXT
    </button>
    <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>
      FINISH LATER
    </button>
    <button *ngIf="!pageService.viewMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Add Contact"
            style="padding-right: 20px;" (click)="onAddContact()">
      <i class="fa fa-plus" aria-hidden="true"></i> ADD CONTACT
    </button>

    <!--<button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save" (click)="stepService.onNextStep()"
            mdbWavesEffect>
      SAVE
    </button>-->
    <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>
      CLOSE & FINISH LATER
    </button>
  </div>
</div>

