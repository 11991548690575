<div class="modal-content">
  <!--Header-->
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Select {{lookupValueLabel}}</h4>
  </div>
  <!--Body-->

  <div class="modal-body">
    <div class="container">


      <div *ngIf="loading" class='row loading-row'>
        <div class='col'>
          <div class="spinner-border text-info segal-spinner" role="status">
          </div>
        </div>
      </div>


      <div *ngIf="!loading" class="row">
        <div class="col-md-12 mx-auto">
          <div class="md-form">
            <input type="text" [(ngModel)]="searchText" class="form-control" id="search" mdbInput />
            <label for="search">Search</label>
          </div>
        </div>
      </div>
      <div *ngIf="allowMultipleValues === false">
        <table [small]="true" mdbTable class="z-depth-1" [hover]="true">
          <thead>
            <th>
              <div *ngIf="lookupValueType.toLowerCase() === 'invmgrnotecb'" class="row">
                <div class="col-12 col-lg-3">
                  Note Type
                </div>
                <div class="col-12 col-lg-3">
                  Note Date
                </div>
                <div class="col-12 col-lg-6">
                  Subject
                </div>
              </div>
            </th>

          </thead>
          <tbody>

            <tr mdbTableCol *ngFor="let el of filteredElements; let i = index" (click)="selectElement(el)">
              <td *ngIf="lookupValueType.toLowerCase() !== 'invmgrnotecb'">
                {{el.label}}
              </td>
              <td>
                <div *ngIf="lookupValueType.toLowerCase() === 'invmgrnotecb'" class="row">
                  <div class="col-12 col-lg-3">
                    {{el.invMgrNoteTypeDesc}}
                  </div>
                  <div class="col-12 col-lg-3">
                    {{el.noteDate | date}}
                  </div>
                  <div class="col-12 col-lg-6">
                    {{el.subject}}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" *ngIf="allowMultipleValues">
        <div class="col-12 col-lg-6">
          <div>Source:</div>
          <table [small]="true" mdbTable #s mdbTableScroll [scrollY]="true" maxHeight="400" class="z-depth-1"
            [hover]="true">
            <thead>
              <th>
                <div *ngIf="lookupValueType.toLowerCase() === 'invmgrnotecb'" class="row">
                  <div class="col-12 col-lg-3">
                    Note Type
                  </div>
                  <div class="col-12 col-lg-3">
                    Note Date
                  </div>
                  <div class="col-12 col-lg-6">
                    Subject
                  </div>
                </div>
              </th>

            </thead>
            <tbody>

              <tr mdbTableCol *ngFor="let el of filteredElements; let i = index" (click)="selectElement(el)">
                <td *ngIf="lookupValueType.toLowerCase() !== 'invmgrnotecb'">
                  {{el.label}}
                </td>
                <td>
                  <div *ngIf="lookupValueType.toLowerCase() === 'invmgrnotecb'" class="row">
                    <div class="col-12 col-lg-3">
                      {{el.invMgrNoteTypeDesc}}
                    </div>
                    <div class="col-12 col-lg-3">
                      {{el.noteDate | date}}
                    </div>
                    <div class="col-12 col-lg-6">
                      {{el.subject}}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 col-lg-6">
          <div>Selected:</div>
          <table [small]="true" mdbTable #s mdbTableScroll [scrollY]="true" maxHeight="100px" [bordered]="true"
            class="z-depth-1" [hover]="true">
            <thead>
              <tr>
                <th scope="col">Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let el of selectedElements; let i = index" (click)="deselectElement(i)">
                <td>
                  {{el.label}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" mdbBtn color="primary" [outline]="true" class="waves-light" aria-label="Close"
      (click)="onCancel()" mdbWavesEffect>Cancel</button>
    <div *ngIf="allowMultipleValues">
      <button type="button" mdbBtn color="primary" class="waves-light" aria-label="Select"
        (click)="returnSelectedElements()" mdbWavesEffect>Ok</button>
    </div>
  </div>
</div>