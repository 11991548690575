<div class='wrap alt pb-0'>
    <div class='container'>

        <div class='row'>
            <div class='col' style="margin-bottom: 1.5rem; font-size: 0.8rem;">
                <a class='breadcrumb-title' (click)="onNavigateList()">
                    <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> Application Inventory
                </a>
                <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> {{appTitle}}
            </div>
        </div>

        <div class='row'>
            <div class="col-12 col-md-8" style="align-self: end;">
                <ul class="tabs segal-plan">
                    <li class="active"><a href="javascript:void(0)">Application</a></li>
                    <!--<li><a (click)="onShowDocuments()">Documentation</a></li>-->
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-12">
                <mdb-card class="mb-12">
                    <mdb-card-body>

                        <div *ngIf="loading" class='row'>
                            <div class='col'>
                                <div class="spinner-border text-info segal-spinner" role="status">
                                </div>
                            </div>
                        </div>

                        <div class="row" style="display: inherit;">
                            <!-- Vertical Steppers -->
                            <div class="col-12">

                              <!-- Stepers Wrapper -->
                              <ul class="stepper stepper-vertical" style="padding: 0px !important;">

                                <li [ngClass]="{'active': pageService.showStepLabel(1)}">
                                  <a (click)="pageService.onSetViewType(1)" href="javascript:void(0)">
                                    <span class="circle">1</span>
                                    <span class="label">Application Base Information</span>
                                  </a>
                                  <div class="step-content">
                                    <div *ngIf="pageService.showForm(1)" style="margin-bottom: 1px;">
                                    </div>
                                    <div *ngIf="pageService.showForm(1)" style="width: 1000px;">
                                      <app-app-basic-info #appBasicInfo></app-app-basic-info>
                                    </div>
                                  </div>
                                </li>

                                <li [ngClass]="{'active': pageService.showStepLabel(2)}">
                                  <a (click)="pageService.onSetViewType(2)" href="javascript:void(0)">
                                    <span class="circle">2</span>
                                    <span class="label">Application Advance Information</span>
                                  </a>
                                  <div class="step-content">
                                    <div *ngIf="pageService.showForm(2)" style="margin-bottom: 1px;">
                                    </div>
                                    <div *ngIf="pageService.showForm(2)" style="width: 1000px;">
                                      <app-app-advance-info #appAdvanceInfo></app-app-advance-info>
                                    </div>
                                  </div>
                                </li>

                                <li [ngClass]="{'active': pageService.showStepLabel(3)}">
                                  <a (click)="pageService.onSetViewType(3)" href="javascript:void(0)">
                                    <span class="circle">3</span>
                                    <span class="label">Point of Contact / Owner</span>
                                  </a>
                                  <div class="step-content">
                                    <div *ngIf="pageService.showForm(3)" style="margin-bottom: 1px;">
                                      <!--The contacts of Segal Application.-->
                                      <span class="label" style="color: navy;">
                                        Identify the individuals within or outside of Segal IT that depend on or support the application, also specify the relationship to the application.
                                      </span>                                      
                                    </div>
                                    <div *ngIf="pageService.showForm(3)" style="width: 800px; ">
                                      <app-app-contact-list #appContact></app-app-contact-list>
                                    </div>
                                  </div>
                                </li>

                                <li [ngClass]="{'active': pageService.showStepLabel(4)}">
                                  <a (click)="pageService.onSetViewType(4)" href="javascript:void(0)">
                                    <span class="circle">4</span>
                                    <span class="label">Application Comments</span>
                                  </a>
                                  <div class="step-content">
                                    <div *ngIf="pageService.showForm(4)" style="margin-bottom: 1px;">
                                    </div>
                                    <div *ngIf="pageService.showForm(4)" style="width: 1000px;">
                                      <app-app-comments #appComment></app-app-comments>
                                    </div>
                                  </div>
                                </li>
                              </ul>

                            </div>

                            <!-- Forms -->

                          </div>

                          <!-- Vertical Steppers -->

</mdb-card-body>
                </mdb-card>
            </div>
        </div>

    </div>
</div>
