import { Component, OnInit } from '@angular/core';
import { BIAResourceModel } from '@app/bia/_models/bia-resource-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { HelpPageService } from '@app/_services/help-page.service';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaRecoveryEditComponent } from '../bia-recovery-edit/bia-recovery-edit.component';
import { BiaRecoveryListSortService } from './bia-recovery-list-sort.service';

@Component({
  selector: 'app-bia-recovery-list',
  templateUrl: './bia-recovery-list.component.html',
  styleUrls: ['./bia-recovery-list.component.scss'],
  providers: [ BIAEditStepListService, BiaRecoveryListSortService ]
})
export class BiaRecoveryListComponent implements OnInit, IBIAStepListComponent {

  pageIndex = 5;
  isDirty = false;
  saving = false;

  public listItems: Array<BIAResourceModel> = new Array<BIAResourceModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaRecoveryListSortService,
    private helpService: HelpPageService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaResources;
  }

  public getHelpText(): void {
    this.helpService.showHelpText('bia-recovery.html', 'BIA Recovery Priority Help', false);
  }

  onOpenItem(biaItem: BIAResourceModel, i: number): void {

    const mf = this.modalService.show(BiaRecoveryEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIAResourceModel) => {
        this.pageService.modelDto.bia.biaResources[i] = updatedItem;
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaResources);
  }
  
}
