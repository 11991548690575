import { Component, OnInit } from '@angular/core';
import { BIABusinessProcessModel } from '@app/bia/_models/bia-business-process-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { HelpPageService } from '@app/_services/help-page.service';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaDowntimeComponent } from '../bia-downtime/bia-downtime.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaDowntimeListSortService } from './bia-downtime-list-sort.service';

@Component({
  selector: 'app-bia-downtime-list',
  templateUrl: './bia-downtime-list.component.html',
  styleUrls: ['./bia-downtime-list.component.scss'],
  providers: [BIAEditStepListService, BiaDowntimeListSortService]
})
export class BiaDowntimeListComponent implements OnInit, IBIAStepListComponent {

  pageIndex = 3;
  isDirty = false;

  isSubmitted = false;
  saving = false;

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaDowntimeListSortService,
    private helpService: HelpPageService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    if (this.pageService.modelDto.bia.biaBusinessProcess == null) {
      this.pageService.modelDto.bia.biaBusinessProcess = new Array<BIABusinessProcessModel>();
    }
  }

  onOpenDowntime(process: BIABusinessProcessModel, i: number): void {

    const mf = this.modalService.show(BiaDowntimeComponent, {
      class: 'modal-lg',
      data: {
        paramProcess: process
      }
    });

    mf.content.onUpdateProcess.subscribe(
      (updatedProcess: BIABusinessProcessModel) => {
        this.pageService.modelDto.bia.biaBusinessProcess[i] = updatedProcess;
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );

  }

  public getHelpText(): void {
    this.helpService.showHelpText('bia-downtime.html', 'BIA Downtime Help', false);
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaBusinessProcess);
  }
}
