import { Component, OnInit } from '@angular/core';
import { BIABackupStrategyModel } from '@app/bia/_models/bia-backup-strategy-model';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BiaBackupStrategyEditComponent } from '../bia-backup-strategy-edit/bia-backup-strategy-edit.component';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BIABackupStrategySortService } from './bia-backup-strategy-list-sort.service';

@Component({
   selector: 'app-bia-backup-strategy-list',
   templateUrl: './bia-backup-strategy-list.component.html',
   styleUrls: ['./bia-backup-strategy-list.component.scss'],
   providers: [ BIAEditStepListService, BIABackupStrategySortService ]
})
export class BiaBackupStrategyListComponent implements OnInit, IBIAStepListComponent {

  isDirty = false;
  pageIndex = 11;

  saving = false;  

  public listItems: Array<BIABackupStrategyModel> = new Array<BIABackupStrategyModel>();

  constructor(
    private modalService: MDBModalService,
    public stepService: BIAEditStepListService,
    public sortService: BIABackupStrategySortService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaBackupStrategy;
  }

  onAddItem() {
    this.onOpenItem(new BIABackupStrategyModel(), -100);
  }

  onDeleteItem(item: BIABackupStrategyModel, index: number) {

    this.pageService.confirmDeleteItem('backup strategy').subscribe(result => {

      if (result) {

        this.pageService.deleteBiaBackupStrategy(item).subscribe(deleteResult => {

          if (deleteResult) {
            this.pageService.modelDto.bia.biaBackupStrategy.splice(index, 1);
          }
        })
      }
    });
  }

  onOpenItem(biaItem: BIABackupStrategyModel, i: number): void {

    const mf = this.modalService.show(BiaBackupStrategyEditComponent, {
      class: 'modal-lg',
      data: {
        paramItem: biaItem
      }
    });

    mf.content.onUpdateItem.subscribe(
      (updatedItem: BIABackupStrategyModel) => {
        if (i == -100) {
          this.pageService.modelDto.bia.biaBackupStrategy.push(updatedItem);
        }
        else {
          this.pageService.modelDto.bia.biaBackupStrategy[i] = updatedItem;
        }
        this.isDirty = true;
        this.stepService.onSaveForm();
      }
    );
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(this.pageIndex-1);
  }
  
  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaBackupStrategy);
  }
}
