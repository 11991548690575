import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

/** Object that config values are loaded into. Should be used to access config values across the application */
export let APP_CONFIG: Readonly<IAppConfig>;

export interface IAppConfig {
  readonly apiDomain: string;
  readonly timeOutInterval: string;
  readonly reportServerUrl: string;
  readonly runAsUser: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Observable<Readonly<IAppConfig>>;

  private configSource: BehaviorSubject<IAppConfig> = new BehaviorSubject<IAppConfig>({
    apiDomain: '',
    timeOutInterval: '0',
    reportServerUrl: '',
    runAsUser: ''
  });
/**
 * Create a new observable from the config source object
 */
  constructor() {
    this.config = this.configSource.asObservable();
  }

  /**
   * Update the configuration values
   * @param config New value for configuration object
   */
  setConfig(config: Partial<IAppConfig>){
    const updatedConfig = {...this.configSource.value, ...config};
    this.configSource.next(updatedConfig);
    APP_CONFIG = this.configSource.value;
  }
}
