import { Injectable } from '@angular/core';
import { ContentTypeModel } from '@app/_models/content-type-model';
import { EmployeeModel } from '@app/_models/employee-model';
import { LookupDto } from '@app/wellness/_models/lookup-dto';
import { UserDocumentTypeModel } from '@app/wellness/_models/user-document-type-model';
import { EmployeeService } from '../../_services/employee.service';
import { AccommodationTypeModel } from '../_models/accommodation-type-model';
import { UserVaccineStatusTypeModel } from '../_models/user-vaccine-status-type-model';
import { VaccineTypeModel } from '../_models/vaccine-type-model';

@Injectable({
  providedIn: 'root'
})
export class WellnessLookupService {
  
  private _model: LookupDto = new LookupDto();
  private _employees: Array<EmployeeModel> = new Array<EmployeeModel>();

  constructor(private employeeService: EmployeeService) { }

  public set lookupModel(model: LookupDto) {
    this._model = model;
  }

  public get activeEmployees(): Array<EmployeeModel> {
    if (this._employees == null) {
      console.log("LookupService: Missing active employee list");
      return new Array<EmployeeModel>();      
    }

    return this._employees;
  }

  public get vaccineTypes(): Array<VaccineTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing user vaccine types");
      return new Array<VaccineTypeModel>();      
    }

    return this._model.vaccineTypes;
  }

  public get userVaccineStatusTypes(): Array<UserVaccineStatusTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing user vaccine status types");
      return new Array<UserVaccineStatusTypeModel>();      
    }

    return this._model.userVaccineStatusTypes;
  }

  public get userDocumentTypes(): Array<UserDocumentTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing user document types");
      return new Array<UserDocumentTypeModel>();      
    }

    return this._model.userDocumentTypes;
  }

  public get contentTypes(): Array<ContentTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing content types");
      return new Array<ContentTypeModel>();      
    }

    return this._model.contentTypes;
  }

  public get accommodationTypes(): Array<AccommodationTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing accommodation types");
      return new Array<AccommodationTypeModel>();      
    }

    return this._model.accommodationTypes;
  }

  public GetVaccineRecordTypeName(accommodationTypeId: number): string {
    return (accommodationTypeId > 0) ? "Accommodation" : "Vaccine";
  }

  public GetAccommodationType(accommodationTypeId: number): AccommodationTypeModel {    
    const result = this.accommodationTypes.find(a => a.accommodationTypeId === accommodationTypeId);
    return (result == undefined) ?  new AccommodationTypeModel() : result;
  }

  public GetVaccineStatus(statusId: number): UserVaccineStatusTypeModel {    
    const result = this.userVaccineStatusTypes.find(u => u.userVaccineStatusId === statusId);
    return (result == undefined) ?  new UserVaccineStatusTypeModel() : result;
  }  

  public SetEmployeeList(): void {
    
    if (this._employees.length > 0) {
      return;
    }

    this.employeeService.getActiveEmployees().subscribe((list) => {
      this._employees = list;
    } );
  }
}