import { EmployeeModel } from "@app/_models/employee-model";
import { UserVaccineDocumentModel } from "./user-vaccine-document-model";
import { VaccineTypeModel } from "./vaccine-type-model";

export class UserBoosterModel {

  public userBoosterId: number = 0;
  public userId: number = 0;
  public createdDt: Date = new Date();
  public updatedDt: Date = new Date();
  public doseDt!: Date | null;
  public vaccineNameId: number = 0;

  public file!: File;

  public vaccineName: VaccineTypeModel = new VaccineTypeModel();
  
  public document: UserVaccineDocumentModel = new UserVaccineDocumentModel();
  public user: EmployeeModel = new EmployeeModel();
}

