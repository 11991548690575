<div class="row">

    <div class="col-12">

        <div class="table-responsive table-clickable">

            <table [small]="true" mdbTable>

                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor="let item of listItems; let i=index" (click)="onOpenItem(item, i)">

                        <td>{{item.resourceCategory.resourceTypeName}}</td>
                        <td>{{item.resourceName}}</td>
                        <td>{{item.resourceTypeName}}</td>
                        <td>{{item.resourceDesc}}</td>
                        <td>
                            <button mdbBtn type="button" class="icon-btn"
                                (click)="onDeleteItem(item, i);$event.stopPropagation()">
                                <i class="fa fa-trash" aria-hidden="true" title="Delete System Resource"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>

                <tfoot *ngIf="listItems?.length === 0">
                    <tr>
                        <td class="text-center no-results" colspan="5">No System Resources Available</td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>

</div>

<div class="row" style="padding-top: 25px;">
    <div class="col-12">
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
        <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>NEXT</button>
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>FINISH LATER</button>
        <button *ngIf="!pageService.viewMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Add Process"
            style="padding-right: 20px;" (click)="onAddItem()"> <i class="fa fa-plus" aria-hidden="true"></i> ADD
            RESOURCE</button>
        <!-- <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save"
            (click)="stepService.onSaveForm()" mdbWavesEffect>SAVE</button> -->
        <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
    </div>
</div>