export class BIARecoveryPriorityModel {
    public recoveryPriorityId: number = 0;
    public recoveryPriority: string = '';
    public isActive: boolean = true;
    public sortOrder: number = 0;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
}
