import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbCheckboxChange, ToastService } from 'ng-uikit-pro-standard';
import { UserVaccinePageService } from '../user-vaccine/user-vaccine-page.service';
import { DatePipe } from '@angular/common';
import { AccommodationTypeModel } from '@app/wellness/_models/accommodation-type-model';

@Component({
  selector: 'app-unvaccinated',
  templateUrl: './unvaccinated.component.html',
  styleUrls: ['./unvaccinated.component.scss']
})
export class UnvaccinatedComponent implements OnInit {

  pageTitle = 'No BIA reports created yet';
  checkBoxText = 'Check this if you are an admin right to generate the reports';

  enableSave = false;
  saving = false;
  
  vaccineForm!: FormGroup;

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(private formBuilder: FormBuilder,
    private toastService: ToastService,
    public pageService: UserVaccinePageService) { }

  ngOnInit(): void {

    this.vaccineForm = this.formBuilder.group({
      unvaccinatedId: [Boolean]
    });

    if (this.pageService.cached) {

      this.unvaccinatedId.setValue(this.pageService.vaccine.unvaccinated);

      if (!this.isNew()) {

        this.setPageReadOnly();

        const datepipe: DatePipe = new DatePipe('en-US')
        let formattedDate = datepipe.transform(this.pageService.vaccine.createdDt, 'M/d/yy');

        this.checkBoxText = 'Check this if you are not vaccinated or do not intend to be vaccinated (Submitted: ' + formattedDate + ')';
        this.pageTitle = (this.pageService.adminView) ? this.pageService.vaccine.user.fullName : 'Not Vaccinated';

      }

    }
  }

  get unvaccinatedId(): any {
    return this.vaccineForm.get('unvaccinatedId');
  }

  public onUnvaccinatedChange(event: MdbCheckboxChange) {
    this.enableSave = (event.checked);
  }
  
  private setPageReadOnly() {
    this.unvaccinatedId.disable();
  }

  public isNew(): boolean {
    return this.pageService.vaccine.userVaccineId == 0;
  }

  public onSave() {

    if (this.saving) {
      return;
    }

    this.pageService.vaccine.unvaccinated = this.unvaccinatedId.value;
    this.pageService.vaccine.statusId = 1;

    // reset fields

    this.pageService.vaccine.accommodationTypeId = 0;
    this.pageService.vaccine.accommodationType = new AccommodationTypeModel();

    this.saving = true;

    console.log("unvaccinated save start");

    this.pageService.saveVaccine(false).subscribe(e => {

      if (e.statusId >= 0) {

        this.toastService.success(e.message, null, this.toastMessageOptions)

      } else {

        this.toastService.error(e.message, null, this.toastMessageOptions)
      }

      this.saving = false;

      console.log("unvaccinated save end");
    })
  }


}

