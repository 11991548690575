<div class="row">

    <div class="col-12">

        <div *ngIf="loading" class="row loading-row">
            <div>
              <div class="spinner-border text-info segal-spinner full-page" role="status"></div>
            </div>
          </div>

        <div class="table-responsive table-clickable">

            <table [small]="true" mdbTable>

                <thead>
                    <tr>                        
                        <th>Name</th>
                        <th>Type</th>
                        <th>Business Unit</th>
                        <th>Email</th>
                        <th>Application</th>
                    </tr>
                </thead>

                <tbody>
                    <tr mdbTableCol *ngFor="let item of listItems; let i=index" (click)="onOpenItem(item)">
                        
                        <td>{{item.employee == null ? '???' : item.employee.fullName}}</td>
                        <td>{{item.contactType == null ? '???' : item.contactType.contactType}}</td>
                        <td>{{item.employee == null ? '???' : (item.employee.businessUnit == null) ? '???' : item.employee.businessUnit}}</td>
                        <td>{{item.employee == null ? '???' : item.employee.email}}</td>
                        <td>{{item.appName}}</td>
                    </tr>
                </tbody>

                <tfoot *ngIf="listItems?.length === 0">
                    <tr>
                        <td class="text-center no-results" colspan="5">No Contacts Available</td>
                    </tr>
                </tfoot>

            </table>

        </div>

    </div>

</div>

<div class="row" style="padding-top: 25px;">
    <div class="col-12">
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-left" aria-label="Prior"
            (click)="stepService.onPriorStep()" mdbWavesEffect>PRIOR</button>
        <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next"
            (click)="stepService.onNextStep()" mdbWavesEffect>NEXT</button>
        <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish"
            (click)="stepService.onSaveCloseForm()" mdbWavesEffect>FINISH LATER</button>
        <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close"
            (click)="stepService.onCloseForm()" mdbWavesEffect>CLOSE</button>
    </div>
</div>
