<form [formGroup]="biaForm" class="md-form">

    <div class='row'>
        <div class='col'>
            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">

                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Business
                    Impact Analysis
                    details could
                    not be saved. Please
                    fix the errors below.</span>

                <ul>
                    <li *ngIf="biaForm?.get('biaName')?.errors?.required">
                        Missing the name of the impact analysis.
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!--Name-->
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="md-form">
                <input mdbInput type="text" class="form-control" formControlName="biaName" maxlength="100" />
                <label>Name</label>
            </div>
        </div>
    </div>

    <!--Overview-->
    <div class="row">
        <div class="col-12">
            <div class="md-form">
                <textarea type="text" placeholder="Enter ..." class="md-textarea form-control auto-height"
                    formControlName="biaOverview" mdbInput style="min-height: 143px;"></textarea>
                <label>System Overview</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next" (click)="stepService.onNextStep()" 
            mdbWavesEffect>NEXT</button>
            <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish" (click)="stepService.onSaveCloseForm()"
                mdbWavesEffect>FINISH LATER</button>  
            <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save" (click)="stepService.onSaveForm()" 
            mdbWavesEffect>SAVE</button>
            <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close" (click)="stepService.onCloseForm()"
                mdbWavesEffect>CLOSE</button>            
        </div>
    </div>

</form>