import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalCustomNavigationClient } from '@azure/msal-angular';
import { InteractionStatus, PopupRequest, RedirectRequest, AccountInfo } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { APP_CONFIG } from './_services/config.service';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('basicModal', { static: true })
  basicModal!: ModalDirective;

  isIframe = false;
  loginDisplay = false;
  userName: string = '';
  hideElement: boolean = false;

  idleState = 'Not started';
  timedOut = false;
  lastPing?: Date;

  title = 'DRApp'; //temp fix for app.component.spec.ts

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private idle: Idle, 
    private keepalive: Keepalive
  ) {
    const customNavigationClient = new MsalCustomNavigationClient(authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);        
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        console.log('App Component: ngOnInit - set login display');
        this.setLoginDisplay();
      })      
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  setLoginDisplay() {

    const accounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = accounts.length > 0;

    if (this.loginDisplay) {

      const account = accounts[0];
      this.setActiveAccount(account);
    }
  }

  setActiveAccount(account: AccountInfo) {

    console.log("Local Account ID: " + account.localAccountId);
    console.log("User Name: " + account.username);

    this.userService.GetUser().subscribe(e => {

      this.userService.user = e;

      this.userName = e.employee.firstName + ' ' + e.employee.lastName;

      if (e.employee.nickName != null) {
        if (e.employee.nickName.length > 0) {
          this.userName = e.employee.nickName + ' ' + e.employee.lastName;
        }
      }

    });
  }

  logout(popup?: boolean) {
    this.router.navigate(["logout"]);
    // this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  timeOutSetup(): void {

    const interval = + APP_CONFIG.timeOutInterval;

    this.idle.setIdle(interval);    // 300 seconds (15 minutes)
    this.idle.setTimeout(90);

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => { 
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.resetTimeOut();      
    });
    
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Signed Out';
      this.timedOut = true;
      console.log(this.idleState);
      this.router.navigate(['logout']);
    });
    
    this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You have been idle'
        console.log(this.idleState);
        this.basicModal.show();
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be signed out in ' + countdown + ' seconds'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.resetTimeOut();
  }

  public onLogoutResponse() {
    this.idleState = 'Signed Out';
    this.timedOut = true;
    console.log(this.idleState);
    this.router.navigate(['logout']);
  }

  public onStayResponse() {
    this.resetTimeOut();
    this.basicModal.hide();
  } 

  private resetTimeOut() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }



}
