import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBIAStepComponent } from '@app/_models/ibiastep-component';
import { BiaAdditionalInfoComponent } from '../bia-additional-info/bia-additional-info.component';
import { BiaAppContactListComponent } from '../bia-app-contact-list/bia-app-contact-list.component';
import { BiaBackupStrategyListComponent } from '../bia-backup-strategy-list/bia-backup-strategy-list.component';
import { BiaBusinessChangeListComponent } from '../bia-business-change-list/bia-business-change-list.component';
import { BiaBusinessFunctionListComponent } from '../bia-business-function-list/bia-business-function-list.component';
import { BiaContactListComponent } from '../bia-contact-list/bia-contact-list.component';
import { BiaDependencyListComponent } from '../bia-dependency-list/bia-dependency-list.component';
import { BiaDowntimeListComponent } from '../bia-downtime-list/bia-downtime-list.component';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { BiaNameComponent } from '../bia-name/bia-name.component';
import { BiaProcessListComponent } from '../bia-process-list/bia-process-list.component';
import { BiaRecoveryListComponent } from '../bia-recovery-list/bia-recovery-list.component';
import { BiaRecoveryOrderListComponent } from '../bia-recovery-order-list/bia-recovery-order-list.component';
import { BiaResourceListComponent } from '../bia-resource-list/bia-resource-list.component';

@Component({
  selector: 'app-bia-edit',
  templateUrl: './bia-edit.component.html',
  styleUrls: ['./bia-edit.component.scss']
})
export class BiaEditComponent implements OnInit, AfterViewInit  {

  @ViewChild('biaName')
  biaName!: BiaNameComponent;

  @ViewChild('biaProcess')
  biaProcess!: BiaProcessListComponent;

  @ViewChild('biaDowntime')
  biaDowntime!: BiaDowntimeListComponent;

  @ViewChild('biaResource')
  biaResource!: BiaResourceListComponent;

  @ViewChild('biaRecovery')
  biaRecovery!: BiaRecoveryListComponent;

  @ViewChild('biaContact')
  biaContact!: BiaAppContactListComponent;

  // @ViewChild('biaContact')
  // biaContact!: BiaContactListComponent;

  @ViewChild('biaFunction')
  biaFunction!: BiaBusinessFunctionListComponent;

  @ViewChild('biaDependency')
  biaDependency!: BiaDependencyListComponent;

  @ViewChild('biaOtherDependency')
  biaOtherDependency!: BiaDependencyListComponent;

  @ViewChild('biaBusinessChange')
  biaBusinessFunction!: BiaBusinessChangeListComponent;

  @ViewChild('biaBackupStrategy')
  biaBackupStrategy!: BiaBackupStrategyListComponent;

  @ViewChild('biaRecoveryOrder')
  biaRecoveryOrder!: BiaRecoveryOrderListComponent;

  @ViewChild('biaAdditionalInfo')
  biaAdditionalInfo!: BiaAdditionalInfoComponent;

  loading = false;

  biaTitle = ''

  toastMessageOptions = { positionClass: 'md-toast-top-center' };
  
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {

      if (params.biaId != undefined) {

        const biaId = +params.biaId;
        this.loading = true;

        // this.pageService.resetModel();
      
        this.pageService.getBia(biaId).subscribe(result => {

          if (result) {               
            
            this.biaTitle = (this.pageService.modelDto.bia.biaId == 0) ? 'New Business Impact Analysis' : this.pageService.modelDto.bia.biaName;

            if (this.pageService.modelDto.bia.stepId > 0) {
              this.pageService.setStepMode();
            } else {
              this.pageService.setEditMode();
            }
          }
          
          this.loading = false; 
        });
      }
    });  

  }

  ngAfterViewInit() {
    // this.loadAllSteps();    
  }  

  onShowDocuments(): void {
    this.router.navigate(['bia-document-list'], { queryParams: { biaId: this.pageService.modelDto.bia.biaId } });
  }

  onNavigateList(): void {
    this.router.navigate(['bia-list']);
  }

  private loadAllSteps(): void {
    for (let index = 1; index <= BiaListPageService.STEP_COUNT; index++) {
      this.loadStep(index);
      
    }
  }

  private loadStep(index: number): void {
    let step = this.activeStep(index);

    if (step == undefined){
      return;
    }

    step.loadPage();
  }

  private activeStep(index: number): IBIAStepComponent {

    switch (index) {
      case 1: return this.biaName;
      case 2: return this.biaProcess;
      case 3: return this.biaDowntime;
      case 4: return this.biaResource;
      case 5: return this.biaRecovery;
      case 6: return this.biaContact;
      case 7: return this.biaFunction;
      case 8: return this.biaDependency;
      case 9: return this.biaOtherDependency;
      case 10: return this.biaBusinessFunction;
      case 11: return this.biaBackupStrategy;
      case 12: return this.biaRecoveryOrder;
      case 13: return this.biaAdditionalInfo;
    }

    return this.biaName;
  }
  
}
