import { Component, OnInit } from '@angular/core';
import { IBIAStepListComponent } from '@app/_models/ibiastep-component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BIAEditStepListService } from '../bia-edit/bia-edit-step-list.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';
import { LookupService } from '@app/_services/lookup.service';
import { BiaAppContactListSortService } from './bia-app-contact-list-sort.service';
import { AppContactModel } from '@app/_models/app-contact-model';
import { AppContactEditComponent } from '@app/_components/app-contact-edit/app-contact-edit.component';
import { AppListPageService } from '@app/_components/app-list/app-list-page.service';

@Component({
  selector: 'app-bia-app-contact-list',
  templateUrl: './bia-app-contact-list.component.html',
  styleUrls: ['./bia-app-contact-list.component.scss'],
  providers: [BIAEditStepListService, BiaAppContactListSortService]
})
export class BiaAppContactListComponent implements OnInit, IBIAStepListComponent {

  pageIndex = 6;
  isDirty = false;

  saving = false;
  loading = false;

  public listItems: Array<AppContactModel> = new Array<AppContactModel>();

  constructor(
    private modalService: MDBModalService,
    private sortService: BiaAppContactListSortService,
    private lookupService: LookupService,
    public stepService: BIAEditStepListService,
    public pageService: BiaListPageService,
    private pageServiceApp: AppListPageService) { }

  ngOnInit(): void {
    this.pageService.registerStep(this.pageIndex, this);
    this.stepService.setComponent(this);
  }

  public loadPage(): void {
    this.listItems = this.pageService.modelDto.bia.biaAppContacts;
  }

  onOpenItem(appItem: AppContactModel): void {

    this.loading = true;

    this.pageServiceApp.getApplication(appItem.appId).subscribe(appResult => {

      this.loading = false;

      if (appResult) {

        this.modalService.show(AppContactEditComponent, {
          class: 'modal-lg',
          data: {
            paramItem: appItem,
            employeeList: this.lookupService.activeEmployees,
            readOnly: true
          }
        }),

          () => {
            this.loading = false;
          }

      }
    });
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName, this.pageService.modelDto.bia.biaAppContacts);
  }

}

