import { ApplicationModel } from "@app/_models/application-model";

export class BIABusinessProcessModel {
    public processId: number = 0;
    public biaId: number = 0;
    public processName: string = '';
    public processDesc: string = '';
    public appId: number = 0;
    public mtdEstimate: number = 0;
    public mtdDriver: string = '';
    public rtoEstimate: number = 0;
    public rtoDriver: string = '';
    public rpoEstimate: number = 0;
    public rpoDriver: string = '';
    public isActive: boolean = true;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
    public impactCategoryId: number = 0;

    public application: ApplicationModel = new ApplicationModel();

    public contactList: string = '';
}
