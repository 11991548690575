import { UserScreenQuestionModel } from "./user-screen-question-model";

export class UserScreenModel {
    
    public userScreenId: number = 0;
    public questionSetId: number = 0;
    public screenOfficeId: number = 0;
    public userId: string = '0';
    public createdDt: Date = new Date();
    public questions: Array<UserScreenQuestionModel> = new Array<UserScreenQuestionModel>();
}
