
import { EmployeeModel } from "@app/_models/employee-model";
import { AccommodationTypeModel } from "./accommodation-type-model";
import { UserBoosterModel } from "./user-booster-model";
import { UserVaccineDocumentModel } from "./user-vaccine-document-model";
import { UserVaccineStatusTypeModel } from "./user-vaccine-status-type-model";
import { VaccineTypeModel } from "./vaccine-type-model";

export class UserVaccineModel {

  public userVaccineId: number = 0;
  public userId: number = 0;
  public statusId: number = 0; 

  public createdDt: Date = new Date();
  public updatedDt: Date = new Date();

  public approvedBy: number = 0;
  public approvedDt!: Date;

  public doseDt1!: Date | null;
  public doseDt2!: Date | null;

  public vaccineNameId: number = 0;
  public accommodationTypeId: number = 0;
  public unvaccinated: boolean = false;

  public isFinal: boolean = false;

  public file!: File;

  public status: UserVaccineStatusTypeModel = new UserVaccineStatusTypeModel();
  public vaccineName: VaccineTypeModel = new VaccineTypeModel();
  public accommodationType: AccommodationTypeModel = new AccommodationTypeModel();
  
  public document: UserVaccineDocumentModel = new UserVaccineDocumentModel();
  public user: EmployeeModel = new EmployeeModel();

  public boosters: Array<UserBoosterModel> = new Array<UserBoosterModel>();
}

