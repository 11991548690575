<form [formGroup]="screenForm" class="md-form">

    <div class='wrap alt pb-0'>
        <div class='container'>

            <mdb-card class="mb-5">
                <mdb-card-body>

                    <mdb-card-title>
                        <h3>
                            BIA Missions / Business Processs {{screenDateLabel}}
                        </h3>

                        <div *ngIf="!loading && pageService.dataWarehouseOnline">
                            As part of our effort to safely return to the office and in compliance with the government
                            and
                            health officials’ recommendations, all employees must respond to the following daily health
                            screening regarding COVID-19 prior to coming to office listed below.
                            Please review the below questions and respond as follows before leaving to go to work.
                        </div>

                    </mdb-card-title>

                    <div *ngIf="pageService.loading" class="row loading-row">
                        <div>
                            <div class="spinner-border text-info segal-spinner full-page" role="status"></div>
                        </div>
                    </div>

                    <!--Office Name Selection-->
                    <div *ngIf="!loading && pageService.dataWarehouseOnline" class="row search-row">
                        <div class="col-md-6 dashboard-search">
                            <select formControlName="officeId" class="form-control sort-by z-depth-1"
                                (change)="onOfficeChange()">
                                <option *ngFor="let office of optionsOffice;" [value]="office.value">
                                    {{office.label}}
                                </option>
                            </select>
                            <label><b>Segal Office</b></label>
                        </div>
                    </div>

                    <!-- Info message screening already submitted -->
                    <div class='row' *ngIf="!isFlagForHR && !loading && !isNew && showQuestionList()"
                        style="padding-top: 25px;">
                        <div class='col'>
                            <div class="alert alert-success">
                                <i class="fa fa-info-circle" aria-hidden="true"></i> <span>{{saveText}}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Info message when data warehouse is offline -->
                    <div class='row' *ngIf="!loading && !pageService.dataWarehouseOnline">
                        <div class='col'>
                            <div class="alert alert-danger">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span>
                                    Unable to retrieve user information. The user data service is currently offline.
                                    Please wait
                                    and try again in a few minutes.
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- Flagged for HR review screening message -->
                    <div class='row' *ngIf="isFlagForHR && !loading && !isNew && showQuestionList()"
                        style="padding-top: 25px;">
                        <div class='col'>
                            <div class="alert alert-danger">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span>
                                    Based on your response you are <b>NOT authorized to work onsite</b>, which includes
                                    onsite
                                    in a Segal office, or while attending a client meeting (whether at the client’s
                                    office or elsewhere) or industry event where you will be representing Segal, until
                                    you speak with Monica Infosino and/or Janet Arce in HR — Benefits to evaluate an
                                    appropriate course of action. Please contact Monica at (212) 251-5159, email
                                    minfosino@segalco.com, or Janet Arce at (212) 251-5297 email jarce@segalco.com
                                </span>
                            </div>
                        </div>
                    </div>

                    <!--Question 1-->
                    <div *ngIf="!loading && showQuestion(1)" class="row question-row">
                        <div class="col col-10">
                            {{ questionText[0] }}
                        </div>
                    </div>

                    <div *ngIf="!loading && (showQuestion(1))">
                        <div class="col-12 col-md-auto">
                            <div class="md-form">

                                <!-- NO -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q1_n" [value]="false"
                                        (change)="onResponseChange()" formControlName="question1" name="question1">
                                    <label class="form-check-label" for="q1_n">No</label>
                                </div>

                                <!-- YES -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q1_y" [value]="true"
                                        (change)="onResponseChange()" formControlName="question1" name="question1">
                                    <label class="form-check-label" for="q1_y">Yes</label>
                                </div>


                            </div>
                        </div>
                    </div>


                    <!--Question 2-->
                    <div *ngIf="!loading && showQuestion(2)" class="row question-row">
                        <div class="col col-10">
                            {{ questionText[1] }}
                        </div>
                    </div>

                    <div *ngIf="!loading && showQuestion(2)">
                        <div class="col-12 col-md-auto">
                            <div class="md-form">

                                <!-- NO -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q2_n" [value]="false"
                                        formControlName="question2" name="question2">
                                    <label class="form-check-label" for="q2_n">No</label>
                                </div>

                                <!-- YES -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q2_y" [value]="true"
                                        formControlName="question2" name="question2">
                                    <label class="form-check-label" for="q2_y">Yes</label>
                                </div>


                            </div>
                        </div>
                    </div>

                    <!--Question 3-->
                    <div *ngIf="!loading && showQuestion(3)" class="row question-row">
                        <div class="col col-10">
                            {{ questionText[2] }}
                        </div>
                    </div>

                    <div *ngIf="!loading && showQuestion(3)">
                        <div class="col-12 col-md-auto">
                            <div class="md-form">

                                <!-- NO -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q3_n" [value]="false"
                                        formControlName="question3" name="question3">
                                    <label class="form-check-label" for="q3_n">No</label>
                                </div>

                                <!-- YES -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q3_y" [value]="true"
                                        formControlName="question3" name="question3">
                                    <label class="form-check-label" for="q3_y">Yes</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-md-auto" *ngIf='!loading && showQuestionList() && showFootnoteNY()'>
                            <small>
                                *If you are fully vaccinated or have fully recovered from COVID-19 in the last three
                                months,
                                you are exempt from answering “yes” to this question.
                            </small>
                        </div>
                    </div>

                    <!--Question 4 -->
                    <div *ngIf="!loading && showQuestion(4)" class="row question-row">
                        <div class="col col-10">
                            {{ questionText[3] }}
                        </div>
                    </div>

                    <div *ngIf="!loading && showQuestion(4)">
                        <div class="col-12 col-md-auto">
                            <div class="md-form">

                                <!-- NO -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q4_n" [value]="false"
                                        formControlName="question4" name="question4">
                                    <label class="form-check-label" for="q4_n">No</label>
                                </div>

                                <!-- YES -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q4_y" [value]="true"
                                        formControlName="question4" name="question4">
                                    <label class="form-check-label" for="q4_y">Yes</label>
                                </div>


                            </div>
                        </div>
                    </div>

                    <!--Question 5 -->
                    <div *ngIf="!loading && showQuestion(5)" class="row question-row">
                        <div class="col col-10">
                            {{ questionText[4] }}
                        </div>
                    </div>
                    <div *ngIf="!loading && showQuestion(5)">
                        <div class="col-12 col-md-auto">
                            <div class="md-form">

                                <!-- NO -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q5_n" [value]="false"
                                        formControlName="question5" name="question5">
                                    <label class="form-check-label" for="q5_n">No</label>
                                </div>

                                <!-- YES -->
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="q5_y" [value]="true"
                                        formControlName="question5" name="question5">
                                    <label class="form-check-label" for="q5_y">Yes</label>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="row question-row" *ngIf='!loading && showQuestionList()'>
                        <div class="col col-md-auto">
                            You must immediately notify Monica Infosino or Janet Arce if your responses to this
                            questionnaire change, such as if you begin to experience symptoms, including during or
                            outside of your work hours, or you learn that someone you have been in contact with received
                            a positive COVID-19 diagnosis.
                        </div>
                    </div>

                    <div class="row question-row" *ngIf='!loading && showQuestionList()'>

                        <!-- Verification Checkbox -->

                        <div class="col col-md-auto">
                            <div class="md-form" style="padding-left: 15px;">
                                <mdb-checkbox formControlName="verificationId" (change)="onVerifyChange()">By checking
                                    this box, I, the Employee, verify the above information</mdb-checkbox>
                            </div>
                        </div>

                        <!--SAVE Button-->

                        <div class="col" *ngIf='isNew'>
                            <button mdbBtn type="button" style="padding-left: 15px;" [disabled]="!enableSave"
                                class="btn-segal" (click)="onSave()"> SUBMIT </button>
                        </div>
                    </div>

                </mdb-card-body>
            </mdb-card>

        </div>
    </div>

</form>

<app-copyright [loading]=pageService.loading></app-copyright>
