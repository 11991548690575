import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleModel } from '@app/_models/user-role-model';
import { UserService } from '@app/_services/user.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { UserRoleListSortService } from './user-role-list-sort.service';
import { UserRolePageService } from './user-role-page.service';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.scss'],
  providers: [UserRoleListSortService]
})
export class UserRoleListComponent implements OnInit {

  filterTypeId = 1;

  optionsFilterType: any[] = new Array<{ value: string, label: string }>();

  loading = true;

  sortDirection = 'ASC';

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private toastService: ToastService,
    private router: Router,
    private sortService: UserRoleListSortService,
    public pageService: UserRolePageService) { }

  ngOnInit(): void {

    this.loading = true;

    this.optionsFilterType.push({ value: 1, label: 'Administrator' });

    this.pageService.getList().subscribe(result => {

      this.filterUserList();
      this.loading = false;

      if (result.statusId < 0) {
        this.toastService.error(result.message, 'User List Error', this.toastMessageOptions);
      }
    })

  }

  onNavigateList(): void {
    this.router.navigate(['bia-list']);
  }

  onNavigateEdit(item: UserRoleModel, index: number) {
    
    this.pageService.onEditItem(item, index).subscribe(result => {
      if (result) {
        let changedItem = this.pageService.modelList[index];
        this.pageService.saveUser(changedItem).subscribe(saveResult => {
          if (saveResult.statusId < 0) {
            this.toastService.error(saveResult.message, 'User Update Error', this.toastMessageOptions);
          }
        });
      }
    });
  }

  onNavigateAdd() {

    let newItem = new UserRoleModel();
    let newIndex = this.pageService.modelList.push(newItem) - 1;

    this.pageService.onEditItem(newItem, newIndex).subscribe(result => {
      if (result) {
        let changedItem = this.pageService.modelList[newIndex];

        this.pageService.saveUser(changedItem).subscribe(saveResult => {
          if (saveResult.statusId >= 0) {
            this.filterUserList();            
          } else {
            this.toastService.error(saveResult.message, 'User Add Error', this.toastMessageOptions);
          }
        });

      }
    });
  }

  onDelete(item: UserRoleModel, index: number) {

    this.pageService.confirmDeleteUser().subscribe(result => {

      if (result) {

        this.pageService.deleteItem(item).subscribe(deleteResult => {

          if (deleteResult.statusId >= 0) {
            this.pageService.modelList.splice(index, 1);
            this.filterUserList();   
          } else {
            this.toastService.error(deleteResult.message, 'User Delete Error', this.toastMessageOptions);
            return;
          }          
        })
      }
    });
  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName);
  }

  filterUserList() {

    this.pageService.filteredUsers = new Array<UserRoleModel>();

    let viewTypeId = +this.filterTypeId;

    this.pageService.modelList.forEach(user => {

      if (user.employee != null) {

        if (viewTypeId === 1) {

          this.pageService.filteredUsers.push(user);

        } 
      }

    });

    this.sortService.sort();
  }
}

