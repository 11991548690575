<div class='wrap alt pb-0'>
    <div class='container'>

        <div class='row'>
            <div class='col' style="margin-bottom: 1.5rem; font-size: 0.8rem;">
                <a class='breadcrumb-title' (click)="onNavigateList()">
                    <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> BIA Listing
                </a>
                <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> {{biaTitle}}
            </div>
        </div>

        <div class='row'>
            <div class="col-12 col-md-8" style="align-self: end;">
                <ul class="tabs segal-plan">
                    <li class="active"><a href="javascript:void(0)">Business Impact Analysis</a></li>
                    <li><a (click)="onShowDocuments()">Documentation</a></li>
                </ul>
            </div>
            <!-- <div class="col-md-4">
                <button mdbBtn type="button" class="btn-segal float-right" (click)="onViewOnly()"
                    mdbWavesEffect>VIEW</button>
                <button mdbBtn type="button" class="btn-segal float-right" (click)="onStep()"
                    mdbWavesEffect>STEP</button>
                <button mdbBtn type="button" class="btn-segal float-right" (click)="onEdit()"
                    mdbWavesEffect>EDIT</button>
            </div> -->
        </div>

        <div class="row">
            <div class="col-12">
                <mdb-card class="mb-5">
                    <mdb-card-body>

                        <div *ngIf="loading" class='row'>
                            <div class='col'>
                                <div class="spinner-border text-info segal-spinner" role="status">
                                </div>
                            </div>
                        </div>

                        <div class="row" style="display: inherit;">

                            <!-- Vertical Steppers -->

                            <div class="col-12">

                                <!-- Stepers Wrapper -->
                                <ul class="stepper stepper-vertical" style="padding: 0px !important;">

                                    <li [ngClass]="{'active': pageService.showStepLabel(1)}">
                                        <a (click)="pageService.onSetViewType(1)" href="javascript:void(0)">
                                            <span class="circle">1</span>
                                            <span class="label">Overview</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(1)" style="margin-bottom: 25px;">
                                                <strong>Purpose</strong>
                                                <div style="margin-bottom: 10px;">
                                                    The purpose of the BIA is to identify and prioritize system
                                                    components by correlating them to the mission/business process(es)
                                                    the system supports and using this information to characterize the
                                                    impact on the process(es) if the system were unavailable.
                                                </div>

                                                <div style="margin-bottom: 10px;">The BIA is composed of the following three steps:</div>
                                                                                                
                                                <ol>
                                                    <li><strong>1. Determine mission/business processes and recovery criticality.</strong>
                                                        Mission/business processes supported by the system are
                                                        identified and the impact of a system disruption to those
                                                        processes is determined along with outage impacts and estimated
                                                        downtime. The downtime should reflect the maximum that an
                                                        organization can tolerate while still maintaining the mission.
                                                    </li>
                                                    <li><strong>2. Identify resource requirements.</strong> Realistic recovery efforts
                                                        require a thorough evaluation of the resources required to
                                                        resume mission/business processes and related interdependencies
                                                        as quickly as possible. Examples of resources that should be
                                                        identified include facilities, personnel, equipment, software,
                                                        data files, system components, and vital records.</li>
                                                    <li><strong>3. Identify recovery priorities for system resources.</strong> Based upon
                                                        the results from the previous activities, system resources can
                                                        more clearly be linked to critical mission/business processes.
                                                        Priority levels can be established for sequencing recovery
                                                        activities and resources. </li>
                                                </ol>

                                                <div style="margin-top: 10px;">
                                                <strong>Roles and Responsibilities</strong>
                                                </div>

                                                <div style="margin-top: 10px;margin-bottom: 10px;">The following definitions of the roles and responsibilities identify the
                                                    key stakeholders and their actions in developing a business function
                                                    BIA.</div>
                                                
                                                <ol>
                                                    <li><strong>1. Business Owner:</strong> has primary responsibility for the BIA,
                                                        including development and ongoing maintenance. This includes
                                                        writing the BIA report and holding working sessions to collect
                                                        information and to achieve consensus on the details, including
                                                        impacts from potential threats and hazards. </li>
                                                    <li><strong>2. IT Application Owner and other associated IT Subject Matter
                                                        Experts (SMEs):</strong> will provide support to the development and
                                                        ongoing maintenance. The SMEs provide a wider perspective,
                                                        especially for impacts to related or interdependent functions.
                                                        SMEs also help formulate more efficient and effective mitigation
                                                        strategies. </li>
                                                </ol>

                                                <div style="margin-top: 10px;margin-bottom: 10px;">
                                                    <strong>Continuity Program</strong>
                                                    </div>
                                      

                                                <ul>
                                                    <li>* Will provide support to the development and ongoing maintenance
                                                        in the form of templates and guidance on how to do a business
                                                        function BIA. This support can include direct involvement in the
                                                        BIA working sessions to guide the BIA team on the details of the
                                                        BIA content. It can also include providing comments and reviews
                                                        that help maintain the level of detail and content across the
                                                        system. </li>
                                                    <li>* Will provide monitoring and verification that BIAs are updated
                                                        when has new features added, will be regularly maintained as
                                                        part of the change management process, and are updated at least
                                                        every two years. </li>
                                                    <li>* Leadership: will review the BIA findings and verify the
                                                        risk-based analysis and mitigation strategies reflect the needs
                                                        of the Business. </li>
                                                </ul>


                                            </div>
                                            <div *ngIf="pageService.showForm(1)">
                                                <app-bia-name #biaName></app-bia-name>
                                            </div>
                                        </div>
                                    </li>


                                    <li [ngClass]="{'active': pageService.showStepLabel(2)}">
                                        <a (click)="pageService.onSetViewType(2)" href="javascript:void(0)">
                                            <span class="circle">2</span>
                                            <span class="label">Business Process</span>
                                        </a>

                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(2)" style="margin-bottom: 25px;">
                                                Working with input from users, managers, mission/business process
                                                owners, and other internal or external points of contact (POC), identify
                                                the specific mission/business processes that depend on or support the
                                                information system.
                                            </div>
                                            <div *ngIf="pageService.showForm(2)">
                                                <app-bia-process-list #biaProcess></app-bia-process-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(3)}">
                                        <a (click)="pageService.onSetViewType(3)" href="javascript:void(0)">
                                            <span class="circle">3</span>
                                            <span class="label">Estimated Downtime</span>
                                        </a>

                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(3)" style="margin-bottom: 25px;">
                                                Working directly with mission/business process owners, departmental
                                                staff, managers, and other stakeholders, estimate the downtime factors
                                                for consideration as a result of a disruptive event. Include a
                                                description of the drivers for the MTD, RTO, and RPOs (e.g., mandate,
                                                workload, performance measure, etc.)
                                            </div>
                                            <div *ngIf="pageService.showForm(3)">
                                                <app-bia-downtime-list #biaDowntime></app-bia-downtime-list>
                                            </div>
                                        </div>
                                    </li>




                                    <li [ngClass]="{'active': pageService.showStepLabel(4)}">
                                        <a (click)="pageService.onSetViewType(4)" href="javascript:void(0)">
                                            <span class="circle">4</span>
                                            <span class="label">System Resources</span>
                                        </a>

                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(4)" style="margin-bottom: 25px;">
                                                List the system resources that support the mission/business processes.
                                                A system resource can be software, data files, servers, or other
                                                hardware and should be identified individually or as a logical group.
                                            </div>
                                            <div *ngIf="pageService.showForm(4)">
                                                <app-bia-resource-list #biaResource></app-bia-resource-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(5)}">
                                        <a (click)="pageService.onSetViewType(5)" href="javascript:void(0)">
                                            <span class="circle">5</span>
                                            <span class="label">Recovery Priority</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(5)" style="margin-bottom: 25px;">
                                                Set the order of recovery for the system resources. Also identify the
                                                expected time for
                                                recovering the resource following a “worst case” (complete
                                                rebuild/repair or
                                                replacement) disruption.
                                                Identify any alternate strategies in place to meet expected RTOs. This
                                                includes backup or spare equipment and vendor support contracts.
                                            </div>
                                            <div *ngIf="pageService.showForm(5)">
                                                <app-bia-recovery-list #biaRecovery></app-bia-recovery-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(6)}">
                                        <a (click)="pageService.onSetViewType(6)" href="javascript:void(0)">
                                            <span class="circle">6</span>
                                            <span class="label">Application Contacts</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(6)" style="margin-bottom: 25px;">
                                                Identify the individuals, positions, or offices within and outside of
                                                your organization that depend on or support the system, also specify the
                                                relationship to the systems.
                                            </div>
                                            <div *ngIf="pageService.showForm(6)">
                                                <app-bia-app-contact-list #biaContact></app-bia-app-contact-list>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- <li [ngClass]="{'active': pageService.showStepLabel(6)}">
                                        <a (click)="pageService.onSetViewType(6)" href="javascript:void(0)">
                                            <span class="circle">6</span>
                                            <span class="label">Point of Contact</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(6)" style="margin-bottom: 25px;">
                                                Identify the individuals, positions, or offices within and outside of
                                                your organization that depend on or support the system, also specify the
                                                relationship to the systems.
                                            </div>
                                            <div *ngIf="pageService.showForm(6)">
                                                <app-bia-contact-list #biaContact></app-bia-contact-list>
                                            </div>
                                        </div>
                                    </li> -->

                                    <li [ngClass]="{'active': pageService.showStepLabel(7)}">
                                        <a (click)="pageService.onSetViewType(7)" href="javascript:void(0)">
                                            <span class="circle">7</span>
                                            <span class="label">Critical Business Processes</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(7)" style="margin-bottom: 25px;">
                                                This section is provided to focus on your business function only
                                                and is to be used for identifying those systems/applications that
                                                your business function has a dependency on or that depends on your
                                                business function.

                                                <br><br>
                                                Document all the functions performed in the following table to establish
                                                a complete list of functions or services provided by the Business Owner.
                                                Work through the list and identify the essential functions, criticality
                                                of essential functions, and the purpose and process of the application.
                                                Include the Mission Essential Functions (MEFs), Essential Supporting
                                                Activities (ESAs), criticality of essential functions, supporting
                                                infrastructure, and other resources.

                                                <br><br>
                                                Document the critical peak times of any day, week, month, or year for
                                                the business function. For instance, these times include when batch jobs
                                                are normally processed, when periodic workloads such as end of month
                                                financial applications are needed, or when an open enrollment period
                                                occurs.
                                            </div>
                                            <div *ngIf="pageService.showForm(7)">
                                                <app-bia-business-function-list #biaFunction>
                                                </app-bia-business-function-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(8)}">
                                        <a (click)="pageService.onSetViewType(8)" href="javascript:void(0)">
                                            <span class="circle">8</span>
                                            <span class="label">Application Dependency</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(8)" style="margin-bottom: 25px;">
                                                Document any applications, systems, or other resources required for
                                                continuing critical business processes during an outage. Is the function
                                                fully reliant on any/all of these dependencies for continued operation?
                                                Describe the relationship between the function and each dependency.

                                                <br><br>
                                                Identify the necessary recovery time needed for each dependency that
                                                affects your function and business process(es). How long will it take to
                                                have a major impact to the operation of your function’s business
                                                process(es) i.e. minutes, hours, days, weeks, and/or months (System
                                                Downtime and Age of Data)?
                                            </div>
                                            <div *ngIf="pageService.showForm(8)">
                                                <app-bia-dependency-list [isOther]="false" #biaDependency>
                                                </app-bia-dependency-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(9)}">
                                        <a (click)="pageService.onSetViewType(9)" href="javascript:void(0)">
                                            <span class="circle">9</span>
                                            <span class="label">Other Requirements / Dependencies Not Identified
                                                Previously</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(9)" style="margin-bottom: 25px;">
                                                Document any additional requirements, such as indirect support, for
                                                continuing critical business processes during an outage.
                                            </div>
                                            <div *ngIf="pageService.showForm(9)">
                                                <app-bia-dependency-list [isOther]="true" #biaOtherDependency>
                                                </app-bia-dependency-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(10)}">
                                        <a (click)="pageService.onSetViewType(10)" href="javascript:void(0)">
                                            <span class="circle">10</span>
                                            <span class="label">Ongoing Business Changes</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(10)" style="margin-bottom: 25px;">
                                                Document any ongoing or planned changes that could impact the business
                                                functions or supporting resources. Describe the change and the estimated
                                                impact to the Critical Business Process(es),
                                                including the schedule for the planned changes.
                                            </div>
                                            <div *ngIf="pageService.showForm(10)">
                                                <app-bia-business-change-list #biaBusinessChange>
                                                </app-bia-business-change-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(11)}">
                                        <a (click)="pageService.onSetViewType(11)" href="javascript:void(0)">
                                            <span class="circle">11</span>
                                            <span class="label">Backup Strategy</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(11)" style="margin-bottom: 25px;">
                                                Document scheduled data backups required for continuing critical
                                                business processes during an outage. Identify the backup location such
                                                as Cloud, Data Center, or Manual.
                                            </div>
                                            <div *ngIf="pageService.showForm(11)">
                                                <app-bia-backup-strategy-list #biaBackupStrategy>
                                                </app-bia-backup-strategy-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(12)}">
                                        <a (click)="pageService.onSetViewType(12)" href="javascript:void(0)">
                                            <span class="circle">12</span>
                                            <span class="label">System Resource Recovery Order</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(12)" style="margin-bottom: 25px;">
                                                Document the order of recovery for system resources. A system resource
                                                can be software, data files, servers, or other hardware and should be
                                                identified individually or as a logical group.
                                            </div>
                                            <div *ngIf="pageService.showForm(12)">
                                                <app-bia-recovery-order-list #biaRecoveryOrder>
                                                </app-bia-recovery-order-list>
                                            </div>
                                        </div>
                                    </li>

                                    <li [ngClass]="{'active': pageService.showStepLabel(13)}">
                                        <a (click)="pageService.onSetViewType(13)" href="javascript:void(0)">
                                            <span class="circle">13</span>
                                            <span class="label">Additional Information</span>
                                        </a>
                                        <div class="step-content">
                                            <div *ngIf="pageService.showForm(13)" style="margin-bottom: 25px;">
                                                Please provide any additional information you would like to share
                                                concerning backup and recovery needs.
                                            </div>
                                            <div *ngIf="pageService.showForm(13)">
                                                <app-bia-additional-info #biaAdditionalInfo>
                                                </app-bia-additional-info>
                                            </div>
                                        </div>
                                    </li>

                                </ul>

                            </div>

                            <!-- Forms -->

                        </div>

                        <!-- Vertical Steppers -->

                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>
    </div>
</div>