import { Injectable } from '@angular/core';
import { ContentTypeModel } from '@app/_models/content-type-model';
import { EmployeeModel } from '@app/_models/employee-model';
import { LookupDto } from '@app/wellness/_models/lookup-dto';
import { UserDocumentTypeModel } from '@app/wellness/_models/user-document-type-model';
import { EmployeeService } from './employee.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  
  private _model: LookupDto = new LookupDto();
  private _employees: Array<EmployeeModel> = new Array<EmployeeModel>();

  constructor(private employeeService: EmployeeService) { }

  public set lookupModel(model: LookupDto) {
    this._model = model;
  }

  public get activeEmployees(): Array<EmployeeModel> {
    if (this._employees == null) {
      console.log("LookupService: Missing active employee list");
      return new Array<EmployeeModel>();      
    }

    return this._employees;
  }

  public get userDocumentTypes(): Array<UserDocumentTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing user document types");
      return new Array<UserDocumentTypeModel>();      
    }

    return this._model.userDocumentTypes;
  }

  public get contentTypes(): Array<ContentTypeModel> {
    if (this._model == null) {
      console.log("LookupService: Missing content types");
      return new Array<ContentTypeModel>();      
    }

    return this._model.contentTypes;
  }

  public SetEmployeeList(): void {
    
    if (this._employees.length > 0) {
      return;
    }

    this.employeeService.getActiveEmployees().subscribe((list) => {
      this._employees = list;
    } );
  }
}