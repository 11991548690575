import { AppDocumentTypeModel } from "./app-document-type-model";

export class AppDocumentModel {

    public documentId: number = 0;
    public appId: number = 0;
    public documentTypeId: number = 0;
    public documentDesc: string = '';
    public fileName: string = '';
    public fileExt: string = '';

    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
    
    public appDocumentType: AppDocumentTypeModel = new AppDocumentTypeModel();
}
