import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAppStepComponent } from '@app/_models/iappstep-component';
import { AppEditStepFormService } from '../app-edit/app-edit-step-form.service';
import { AppListPageService } from '../app-list/app-list-page.service';

import { ActivatedRoute, Router } from '@angular/router';
import { LookupValue } from '@app/_models/lookup-value';
import { IMyOptions, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AppContactModel } from '@app/_models/app-contact-model';
import { AppContactEditComponent } from '../app-contact-edit/app-contact-edit.component';

@Component({
  selector: 'app-app-advance-info',
  templateUrl: './app-advance-info.component.html',
  styleUrls: ['./app-advance-info.component.scss'],
  providers: [AppEditStepFormService]
})
export class AppAdvanceInfoComponent implements OnInit {

  pageIndex = 2;
  isSubmitted = false;
  saving = false;

  applicationForm!: FormGroup;

  //public datePickerOptions!: IMyOptions;
  
  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public stepService: AppEditStepFormService,
    public pageService: AppListPageService) { }

  ngOnInit(): void {

    //this.datePickerOptions = {
    //  dateFormat: 'mmmm dd, yyyy',
    //  useDateObject: true,
    //  editableDateField: false,
    //  closeAfterSelect: true,
    //  showClearDateBtn: false,
    //};

    this.applicationForm = this.formBuilder.group({
      appVendor: ['', Validators.required],
      appTier: [''],
      appBu: ['', Validators.required],
      sftwCategory: [''],
      appCategory: [''],
      availableVia: [''],
      localCloud: [''],
      itSupportType: ['']
    });

    this.stepService.setComponent(this);
    this.loadPage();

    this.pageService.loadAppVendorList();
    this.pageService.loadAppTierList();
    this.pageService.loadAppBUsList();
    this.pageService.loadAppCategoryList();
    this.pageService.loadAppSftwCategoryList();
    this.pageService.loadAppAvailableViaList();
    this.pageService.loadAppItSupportTypeList();
    this.pageService.loadAppLocationsList();

  }

  public loadPage(): void {
    //load advance section:
    this.appVendor.setValue(this.pageService.modelDto.application.appVendorId.toString());
    this.appTier.setValue(this.pageService.modelDto.application.appTierId.toString());
    this.appBu.setValue(this.pageService.modelDto.application.appBuid.toString());

    this.appAppSftwCategory.setValue(this.pageService.modelDto.application.sftwCategory.toString());
    this.appAppCategory.setValue(this.pageService.modelDto.application.appCategory.toString());

    this.appAppAvailableVia.setValue(this.pageService.modelDto.application.availableVia.toString());
    this.appAppLocations.setValue(this.pageService.modelDto.application.localCloud.toString());
    this.appAppItSupportType.setValue(this.pageService.modelDto.application.itSupportType.toString());
  }

  get appVendor(): any {
    return this.applicationForm.get('appVendor');
  }

  get appTier(): any {
    return this.applicationForm.get('appTier');
  }

  get appBu(): any {
    return this.applicationForm.get('appBu');
  }

  get appAppSftwCategory(): any {
    return this.applicationForm.get('sftwCategory');
  }

  get appAppCategory(): any {
    return this.applicationForm.get('appCategory');
  }

  get appAppAvailableVia(): any {
    return this.applicationForm.get('availableVia');
  }

  get appAppLocations(): any {
    return this.applicationForm.get('localCloud');
  }

  get appAppItSupportType(): any {
    return this.applicationForm.get('itSupportType');
  }

  public onAppBuChange() {
    //no actions
  }

  public isValid(): boolean {
    return this.applicationForm.valid;
  }

  public loadDto(): void {
    this.pageService.modelDto.application.appVendorId = Number(this.appVendor.value);
    this.pageService.modelDto.application.appTierId = Number(this.appTier.value);
    this.pageService.modelDto.application.appBuid = Number(this.appBu.value);

    this.pageService.modelDto.application.sftwCategory = Number(this.appAppSftwCategory.value);
    this.pageService.modelDto.application.appCategory = Number(this.appAppCategory.value);

    this.pageService.modelDto.application.availableVia = Number(this.appAppAvailableVia.value);
    this.pageService.modelDto.application.localCloud = Number(this.appAppLocations.value);
    this.pageService.modelDto.application.itSupportType = Number(this.appAppItSupportType.value);
  }

}
