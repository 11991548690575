import { Injectable } from '@angular/core';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BIAListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'name' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.biaName.localeCompare(b.biaName));

    } else if (columnName === 'name') {

      this.pageService.modelList.sort((a, b) => b.biaName.localeCompare(a.biaName));

    }

    else if (columnName === 'appName' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.biaApplicationList.localeCompare(b.biaApplicationList));

    } else if (columnName === 'appName') {

      this.pageService.modelList.sort((a, b) => b.biaApplicationList.localeCompare(a.biaApplicationList));

    }

    else if (columnName === 'buName' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.biaBusinessUnitList.localeCompare(b.biaBusinessUnitList));

    } else if (columnName === 'buName') {

      this.pageService.modelList.sort((a, b) => b.biaBusinessUnitList.localeCompare(a.biaBusinessUnitList));

    }

    else if (columnName === 'contact' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.biaContactList.localeCompare(b.biaContactList));

    } else if (columnName === 'contact') {

      this.pageService.modelList.sort((a, b) => b.biaContactList.localeCompare(a.biaContactList));

    }

  }
}
