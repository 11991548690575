<form [formGroup]="boosterForm" class="md-form">

    <mdb-card class="mb-5">
        <mdb-card-body>

            <mdb-card-title>
                <h3>
                    Booster
                    <button mdbBtn type="button" style="font-size: 0.6rem;" class="btn-segal float-right ml-2"
                        (click)="onBoosterShow()"><i [ngClass]='addButtonClass()' aria-hidden="true"></i>
                        {{addButtonText}}</button>
                </h3>
            </mdb-card-title>


            <div class="row" *ngIf="!showBoosterForm">
                <div class="col-12">
                    <table mdbTable class="table table-sm">
                        <thead>
                        </thead>
                        <tbody>
                            <tr mdbTableCol *ngFor="let booster of pageService.vaccine.boosters; let i=index"
                                (click)="null">

                                <td style="padding-left: 1px" title="Date">
                                    <div class="row">
                                        <div class="col-12">
                                            {{booster.doseDt | date}}
                                        </div>
                                    </div>
                                </td>

                                <td style="padding-left: 1px" title="Vaccine">
                                    <div class="row">
                                        <div class="col-12">
                                            {{booster.vaccineName.vaccineName}}
                                        </div>
                                    </div>
                                </td>

                                <td style="padding-left: 1px">
                                    <div class="row">
                                        <div class="col-12">
                                            <a href="javascript:void(0)"
                                                (click)="onDownloadFile(booster.userBoosterId)">
                                                {{booster.document.fileName}}
                                            </a>
                                        </div>
                                    </div>
                                </td>

                                <!-- Delete Column-->
                                <!-- <td class="text-right">
                                    <button mdbBtn type="button" class="icon-btn" (click)="onDelete(booster.userBoosterId, i); $event.stopPropagation()"><i
                                        class="fa fa-trash" aria-hidden="true" title="Delete Booster"></i> </button>
                                </td> -->
                            </tr>
                        </tbody>
                        <tfoot *ngIf="pageService.vaccine.boosters.length === 0">
                            <tr>
                                <td class="text-center no-results" colspan="4">No Records Available</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>


            <div class="row" *ngIf="showBoosterForm">

                <div class='col-12'>

                    <div class="alert alert-danger" *ngIf="isSubmitted && (boosterForm?.invalid)">

                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>

                        <span>
                            Booster vacination details could not be saved. Please fix the errors below.
                        </span>

                        <ul>
                            <li *ngIf="boosterForm?.get('vaccineNameId')?.errors?.required">
                                Missing the name of the vaccine.
                            </li>
                            <li *ngIf="boosterForm?.get('doseDt')?.errors?.required">
                                Missing the date of the booster.
                            </li>
                            <li *ngIf="boosterForm?.errors?.futureDate">
                                Booster date must be today or earlier
                            </li>

                        </ul>
                    </div>

                </div>
            </div>


            <!--Vaccine Name-->
            <div class="row" *ngIf="showBoosterForm">
                <div class="col-12">
                    <div class="md-form">
                        <mdb-select formControlName="vaccineNameId" [options]="optionsVaccineName"
                            placeholder="Please Select"
                            [ngClass]="{'form-invalid':isSubmitted && boosterForm.get('vaccineNameId')?.errors?.required}"
                            class="form-control-mdb">
                        </mdb-select>
                        <label>Vaccine Name</label>
                        <mdb-error *ngIf="isSubmitted && boosterForm.get('vaccineNameId')?.errors?.required">
                            Please select vaccine name
                        </mdb-error>
                        <div class="spinner-border text-info segal-spinner mobile-show" role="status" *ngIf="saving">
                        </div>
                    </div>
                </div>
            </div>

            <!--Dose Date-->
            <div class="row" *ngIf="showBoosterForm">
                <div class="col-12">
                    <div class="md-form">
                        <mdb-date-picker [inline]="true" formControlName="doseDt" [options]="datePickerOptions"
                            [placeholder]="'Select date'"
                            [ngClass]="{'form-invalid':isSubmitted && boosterForm?.get('doseDt')?.errors?.required}">
                        </mdb-date-picker>
                        <label>Date of Booster</label>
                        <mdb-error *ngIf="isSubmitted && boosterForm?.get('doseDt')?.errors?.required">
                            Please select date of booster vaccine</mdb-error>
                    </div>
                </div>

            </div>

            <div class="row" *ngIf="showBoosterForm">
                <div class="col-12">
                    <button mdbBtn type="button" class="btn-segal-green" (click)="onUploadCard()">UPLOAD
                        CARD</button>
                </div>
            </div>



        </mdb-card-body>
    </mdb-card>

</form>