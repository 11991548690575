import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBIAStepComponent } from '@app/_models/ibiastep-component';
import { BIAEditStepFormService } from '../bia-edit/bia-edit-step-form.service';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
  selector: 'app-bia-name',
  templateUrl: './bia-name.component.html',
  styleUrls: ['./bia-name.component.scss'],
  providers: [ BIAEditStepFormService ]
})
export class BiaNameComponent implements OnInit, IBIAStepComponent {

  isSubmitted = false;
  saving = false;
  pageIndex = 1;

  biaForm!: FormGroup;

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public stepService: BIAEditStepFormService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      biaName: ['', Validators.required],
      biaOverview: ['', Validators.required]
    });

    this.stepService.setComponent(this);
    this.loadPage();
  }

  public loadPage(): void {
    this.biaName.setValue(this.pageService.modelDto.bia.biaName);
    this.biaOverview.setValue(this.pageService.modelDto.bia.overview);
  }
  
  get biaName(): any {
    return this.biaForm.get('biaName');
  }

  get biaOverview(): any {
    return this.biaForm.get('biaOverview');
  }  

  public isValid(): boolean {
    return this.biaForm.valid;
  }

  public loadDto(): void {
    this.pageService.modelDto.bia.biaName = this.biaName.value;
    this.pageService.modelDto.bia.overview = this.biaOverview.value;
  }

  // onNextStep(): void {
  //   this.pageService.saveForm(this, true);
  // }

  // onSaveCloseForm(): void {
  //   this.pageService.saveForm(this, false);
  //   this.pageService.navigateBack();
  // }

  // onSaveForm(): void {
  //   this.pageService.saveForm(this, false);
  // }

  // onCloseForm(): void {
  //   this.pageService.resetModel();
  //   this.pageService.navigateBack();
  // }

  // private saveItem(nextStep: boolean) {

  //   if (this.saving) {
  //     return;
  //   }

  //   this.isSubmitted = true;

  //   if (!this.biaForm.valid) {
  //     return;
  //   }

  //   if (nextStep && this.pageService.modelDto.bia.stepId < this.pageIndex) {
  //     this.pageService.modelDto.bia.stepId = this.pageIndex;
  //     this.biaForm.markAsDirty;
  //   }

  //   if (!this.biaForm.dirty) {
  //     if (nextStep) {
  //       this.pageService.onSetViewType(this.pageIndex+1);
  //     }      
  //     return;
  //   }

  //   this.pageService.modelDto.bia.biaName = this.biaName.value;
  //   this.pageService.modelDto.bia.overview = this.biaOverview.value;
    
  //   this.saving = true;

  //   console.log("business impact analysis save start");

  //   let saveResult = false;

  //   this.pageService.saveBia().subscribe(e => {

  //     if (e.statusId >= 0) {

  //       saveResult = true;

  //     } else {

  //       this.toastService.error(e.message, null, this.toastMessageOptions);
  //     }

  //     this.saving = false;

  //     console.log("bia save end");

  //     if (saveResult && nextStep) {
  //       this.pageService.onSetViewType(this.pageIndex+1);
  //     }
  //   })
  // }

}

