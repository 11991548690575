import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BIAModel } from '@app/bia/_models/bia-model';
import { LookupService } from '@app/_services/lookup.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { BiaListPageService } from './bia-list-page.service';
import { BIAListSortService } from './bia-list-sort.service';

@Component({
  selector: 'app-bia-list',
  templateUrl: './bia-list.component.html',
  styleUrls: ['./bia-list.component.scss'],
  providers: [BIAListSortService]
})
export class BiaListComponent implements OnInit, AfterViewInit {

  searchText: string = '';
  filterByName = 'ALL';

  loading = true;

  filteredList: Array<BIAModel> = new Array<BIAModel>();
  filterByDesc = '';

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private toastService: ToastService,
    private router: Router,
    private sortService: BIAListSortService,
    private lookupService: LookupService,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {
    this.getBiaList();
  }

  ngAfterViewInit() {
  }  

  onSearchList(): void {

    let srchText = this.searchText.toUpperCase();

    if (srchText.length == 0) {

      this.filteredList = this.pageService.modelList;

    } else {

      this.filteredList = this.pageService.modelList.filter(
        (item) =>
        (
          item.biaName.toUpperCase().includes(srchText)
          || ((item.biaApplicationList != null) ? item.biaApplicationList.toUpperCase().includes(srchText) : false)
          || ((item.biaBusinessUnitList != null) ? item.biaBusinessUnitList.toUpperCase().includes(srchText) : false)
          || ((item.biaContactList != null) ? item.biaContactList.toUpperCase().includes(srchText) : false)
        )
      );

    }    
  }
  
  onFilterList() {
    if (this.pageService.isListByUser()) {
      this.pageService.setListAll();      
      this.filterByName = 'MINE';
    } else {
      this.pageService.setListByUser();
      this.filterByName = 'ALL';
    }
    this.getBiaList();
  }

  onNavigateBia(bia: BIAModel) {
    this.pageService.resetModel();
    this.router.navigate(['bia'], { queryParams: { biaId: bia.biaId.toString() } });
  }

  onNavigateBiaAdd() {

    if (this.isAdministrator()) {
      this.pageService.resetModel();
      this.router.navigate(['bia'], { queryParams: { biaId: '0' } });
    }
  }

  onNavigateApplication(appId: number) {
    this.router.navigate(['app-edit'], { queryParams: { appId: appId.toString() } });
  }

  onDeleteBia(argBia: BIAModel) {

    if (argBia === null) {
      return;
    }

    if (!this.isAdministrator()) {
      return;
    }

    const bia = this.pageService.modelList.find(
      (a) => a.biaId === argBia.biaId
    );

    if (bia == null) {
      console.log(
        `Unable to find business impact analysis with id ${argBia.biaName}`
      );
      return;
    }

    const index = this.pageService.modelList.indexOf(bia, 0);

    if (index < 0) {
      console.log(`Unable to find business impact analysis with index ${index}`);
      return;
    }

    this.pageService.confirmDeleteBia().subscribe(result => {

      if (result) {

        this.pageService.deleteBia(bia).subscribe(deleteResult => {

          if (deleteResult.statusId < 0) {
            this.toastService.error(deleteResult.message, 'BIA Delete Error', this.toastMessageOptions);
            return;
          }

          this.pageService.modelList.splice(index, 1);
        })
      }
    });
  }

  private getBiaList() {

    this.pageService.getList().subscribe(result => {

      if (result.statusId < 0) {
        this.toastService.error(result.message, 'BIA List Error', this.toastMessageOptions);
      }

      this.filterByDesc = this.pageService.isListByUser() ? '- ' + this.pageService.userFullName() : '- All'

      this.filteredList = this.pageService.modelList;

      this.lookupService.SetEmployeeList();  

      this.loading = false;      
    })

  }

  sortBy(columnName: string) {
    this.sortService.sortBy(columnName);
  }

  isAdministrator(): boolean {
    return this.pageService.isAdministrator();
  }
}
