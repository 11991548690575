import { Injectable } from '@angular/core';
import { BIABackupStrategyModel } from '@app/bia/_models/bia-backup-strategy-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BIABackupStrategySortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  public sortBy(columnName: string, items: BIABackupStrategyModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'backupTypeDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.backupTypeDesc.localeCompare(b.backupTypeDesc));

    } else if (columnName === 'backupTypeDesc') {

      items.sort((a, b) => b.backupTypeDesc.localeCompare(a.backupTypeDesc));

    }

    else if (columnName === 'scheduleDesc' && this.sortDirection === 'ASC') {

      items.sort((a, b) => a.scheduleDesc.localeCompare(b.scheduleDesc));

    } else if (columnName === 'scheduleDesc') {

      items.sort((a, b) => b.scheduleDesc.localeCompare(a.scheduleDesc));

    }
  }
}
