import { Injectable } from '@angular/core';
import { ApplicationModel } from '@app/_models/application-model';
import { AppListPageService } from '../app-list/app-list-page.service';

@Injectable()
export class AppListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: AppListPageService) { }

  sortBy(columnName: string) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';

    if (columnName === 'appId' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.appId - b.appId);

    } else if (columnName === 'appId') {

      this.pageService.modelList.sort((a, b) => b.appId - a.appId);

    }
    else if (columnName === 'appName' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.appName.localeCompare(b.appName));

    } else if (columnName === 'appName') {

      this.pageService.modelList.sort((a, b) => b.appName.localeCompare(a.appName));

    }
    else if (columnName === 'appComments' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.appComments.localeCompare(b.appComments));

    } else if (columnName === 'appComments') {

      this.pageService.modelList.sort((a, b) => b.appComments.localeCompare(a.appComments));

    }
    else if (columnName === 'buName' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a: ApplicationModel, b: ApplicationModel) => {
        const c = a.businessUnit == null ? '' : a.businessUnit.userGroupName;
        const d = b.businessUnit == null ? '' : b.businessUnit.userGroupName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'buName') {

      this.pageService.modelList.sort((a: ApplicationModel, b: ApplicationModel) => {
        const c = a.businessUnit == null ? '' : a.businessUnit.userGroupName;
        const d = b.businessUnit == null ? '' : b.businessUnit.userGroupName;
        return d.localeCompare(c);
      }
      );
    }
    else if (columnName === 'appOwnerId' && this.sortDirection === 'ASC') {

      this.pageService.modelList.sort((a, b) => a.appOwnerId.localeCompare(b.appOwnerId));

    } else if (columnName === 'appOwnerId') {

      this.pageService.modelList.sort((a, b) => b.appOwnerId.localeCompare(a.appOwnerId));

    } 

  }
}
