import { Injectable } from '@angular/core';
import { IBIAStepFormComponent } from '@app/_models/ibiastep-component';
import { ToastService } from 'ng-uikit-pro-standard';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BIAEditStepFormService {

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  public stepComponent!: IBIAStepFormComponent;

  constructor(private toastService: ToastService,
    private pageService: BiaListPageService) { }

  public setComponent(stepComponent: IBIAStepFormComponent) {
    this.stepComponent = stepComponent;
  }

  onPriorStep(): void {
    this.pageService.onSetViewType(this.stepComponent.pageIndex-1);
  }

  onNextStep(): void {
    this.saveForm(true, false);
  }

  onFinishStep(): void {
    this.saveForm(true, true);
  }

  onSaveCloseForm(): void {
    this.saveForm(false, true);
  }

  onSaveForm(): void {
    this.saveForm(false, false);
  }

  public onCloseForm(): void {

    if (this.stepComponent.biaForm.dirty) {
      this.pageService.confirmSaveChanges().subscribe(result => {
        if (result) {
          this.onSaveCloseForm();
          return;
        } else {
          this.closeForm();
        }
      });
    } else {
      this.closeForm();
    }    

  }

  private closeForm(): void {
    this.pageService.resetModel();
    this.pageService.navigateBack();
  }

  public saveForm(
    nextStep: boolean,
    navigateBack: boolean): void {

    if (this.stepComponent.saving) {
      return;
    }

    this.stepComponent.isSubmitted = true;

    if (!this.stepComponent.biaForm.valid) {
      return;
    }

    let lastStep = (this.stepComponent.pageIndex == BiaListPageService.STEP_COUNT);

    if (nextStep) {

      if (lastStep) {

        this.pageService.modelDto.bia.stepId = 0;
        this.stepComponent.biaForm.markAsDirty;

      } else if (this.pageService.modelDto.bia.stepId < this.stepComponent.pageIndex) {

        this.pageService.modelDto.bia.stepId = this.stepComponent.pageIndex;
        this.stepComponent.biaForm.markAsDirty;
      }

    }  

    this.stepComponent.loadDto();
    
    if (!this.stepComponent.biaForm.dirty && !lastStep) {
      if (nextStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex+1);
      }      

      return;
    }
        
    this.stepComponent.saving = true;

    console.log("bia step form save start");

    let saveResult = false;

    this.pageService.saveBia().subscribe(e => {

      if (e.statusId >= 0) {

        saveResult = true;

      } else {

        this.toastService.error(e.message, 'BIA Save Error', this.toastMessageOptions);
      }

      this.stepComponent.saving = false;

      if (!saveResult) {
        return;
      }

      console.log("bia step form save end");

      if (lastStep) {
        this.toastService.success('The Business Imapact Analysis has been completed.', 'BIA Complete', this.toastMessageOptions);
        this.pageService.navigateBack();
      }

      else if (nextStep) {
        this.pageService.onSetViewType(this.stepComponent.pageIndex+1);
      }

      else if (navigateBack) {
        this.pageService.navigateBack();        
      }

      this.toastService.success('The Business Imapact Analysis section has been saved.', 'BIA', this.toastMessageOptions);
    })
  }
}
