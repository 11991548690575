import { EmployeeModel } from "@app/_models/employee-model";
import { BIAContactTypeModel } from "./bia-contact-type-model";

export class BIAContactModel {
    public contactId: number = 0;
    public biaId: number = 0;
    public pocTypeId: number = 0;
    public empId: string = '';
    public criticalProcess: boolean = true;
    public isActive: boolean = true;
    public sortOrder: number = 0;
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';
    public employee: EmployeeModel = new EmployeeModel();
    public contactType: BIAContactTypeModel = new BIAContactTypeModel();
}
