import { IOption } from "ng-uikit-pro-standard";

export class LookupValue implements IOption {

    public value: string;
    public label: string;
    public disabled?: boolean;
    public group?: boolean;
    public icon: string = '';

    constructor(value: string, label: string) {
        this.value = value,
        this.label = label;
        this.icon = '';
    }

}
