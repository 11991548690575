export class BIAResourceCategoryModel {

    public resourceTypeId: number = 0;
    public resourceTypeName: string = '';
    public sortOrder: number = 0;
    public isActive: boolean = true;    
    public createdOn: Date = new Date();
    public createdBy: string = '';
    public modifiedOn: Date = new Date();
    public modifiedBy: string = '';

}
