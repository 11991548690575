import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '@app/_services/config.service';
import { UserBoosterModel } from '../_models/user-booster-model';
import { UserVaccineDto } from '../_models/user-vaccine-dto';
import { UserVaccineModel } from '../_models/user-vaccine-model';

@Injectable({
  providedIn: 'root'
})
export class UserVaccineService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient) { }

  public getVaccine(): Observable<UserVaccineDto> {

    let requestURL = APP_CONFIG.apiDomain + '/uservaccine';

    // if (APP_CONFIG.runAsUser.length > 0)  {
    //   requestURL = APP_CONFIG.apiDomain + '/uservaccineadmin/user/' +  this.userService.user.empId;
    // }

    return this.http
      .get<UserVaccineDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public saveVaccineFinal(formData: FormData): Observable<UserVaccineModel> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccine/save-final';

    return this.http.post<UserVaccineModel>(requestURL, formData).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );

  }

  public saveVaccine(model: UserVaccineModel): Observable<UserVaccineModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/uservaccine/save';

    return this.http.post<UserVaccineModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public addBooster(formData: FormData): Observable<UserBoosterModel> {

    const requestURL = APP_CONFIG.apiDomain + '/userbooster/add';

    return this.http.post<UserBoosterModel>(requestURL, formData).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );

  }

  public deleteVaccine(): Observable<boolean> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccine/delete';

    return this.http
      .get<boolean>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public deleteBooster(boosterId: number): Observable<boolean> {

    const requestURL = APP_CONFIG.apiDomain + '/userbooster/delete/' + boosterId.toString();

    return this.http
      .get<boolean>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getVaccineFile(fileName: string): void{

    this.downloadVaccineFile()
      .subscribe((blob) => {
        var a = document.createElement("a");
        a.target = '_blank';

        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        a.click();
      });
  }

  public getBoosterFile(boosterId: number, fileName: string): void{

    this.downloadBoosterFile(boosterId)
      .subscribe((blob) => {
        var a = document.createElement("a");
        a.target = '_blank';

        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        a.click();
      });
  }

  private downloadVaccineFile(): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/uservaccine/download';

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private downloadBoosterFile(boosterId: number): Observable<Blob> {

    const requestURL = APP_CONFIG.apiDomain + '/userbooster/download/' + boosterId.toString();

    return this.http
      .get<Blob>(requestURL, { responseType: 'blob' as 'json' })
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}
