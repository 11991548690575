<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>
        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>{{modalTitle}}
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('sortOrder')?.errors?.required">
                                        Missing the priority order of the system resource.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                     <!-- Sort Order -->
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="md-form">
                                <mdb-select formControlName="sortOrder" [options]="optionsSortOrder"
                                    placeholder="Please Select" class="form-control-mdb">
                                </mdb-select>
                                <label>Order</label>
                            </div>
                        </div>
                    </div>

                    <!-- Priority -->
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="recoveryPriority" class="form-control"
                                    maxlength="500" readonly />
                                <label>Priority</label>
                            </div>
                        </div>
                    </div>

                    <!-- Resource Name -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="resourceName" class="form-control"
                                    maxlength="500" readonly/>
                                <label>Resource</label>
                            </div>
                        </div>
                    </div>

                    <!-- RTO -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <input mdbInput type="text" formControlName="rto" class="form-control"
                                    maxlength="500" readonly/>
                                <label>MTD</label>
                            </div>
                        </div>
                    </div>

                    <!-- RTO Description -->
                    <div class="row">
                        <div class="col-12">
                            <div class="md-form">
                                <textarea type="text" formControlName="rtodesc" placeholder="Enter ..."
                                    class="md-textarea form-control auto-height" mdbInput
                                    style="min-height: 143px;" readonly></textarea>
                                <label>MTD Description</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close" (click)="onCancel()"
                    mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>


        </app-spinner>
    </div>
</form>
