<form [formGroup]="biaForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-content">

        <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="myModalLabel">{{modalTitle}} </h4>
        </div>

        <app-spinner [isModal]="true">
            <div class="modal-body">
                <div class="container">

                    <div class='row'>
                        <div class='col'>
                            <div class="alert alert-danger" *ngIf="isSubmitted && (biaForm?.invalid)">
                                <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>Business
                                    Process Details
                                    details could
                                    not be saved. Please
                                    fix the errors below.</span>
                                <ul>
                                    <li *ngIf="biaForm?.get('mtd')?.errors?.required">
                                        Missing value for Maximum Tolerable Downtime.
                                    </li>
                                    <li *ngIf="biaForm?.get('rto')?.errors?.required">
                                        Missing value for Recovery Time Objective.
                                    </li>
                                    <li *ngIf="biaForm?.get('rpo')?.errors?.required">
                                        Missing value for Recovery Point Objective.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <!-- MTD -->
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="mtd"
                                    maxlength="10" />
                                <label>Max Tolerable Downtime</label>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="mtdDriver"
                                    maxlength="1000" />
                                <label>MTD Driver</label>
                            </div>
                        </div>
                    </div>


                    <!-- RTO -->
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rto"
                                    maxlength="10" />
                                <label>Recovery Time Objective</label>
                            </div>
                        </div>
                        <div class="col-12 col-lg-9">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rtoDriver"
                                    maxlength="1000" />
                                <label>RTO Driver</label>
                            </div>
                        </div>
                    </div>

                    <!-- RPO -->
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rpo"
                                    maxlength="10" />
                                <label>Recovery Point Objective</label>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="md-form">
                                <input mdbInput type="text" class="form-control" formControlName="rpoDriver"
                                    maxlength="1000" />
                                <label>RPO Driver</label>
                            </div>
                        </div>
                    </div>






                </div>
            </div>


            <div class="modal-footer">
                <button type="button" mdbBtn class="btn-segal waves-light" aria-label="Close"
                    (click)="onCancel()" mdbWavesEffect>Cancel
                </button>
                <button type="submit" mdbBtn [disabled]="biaForm.invalid || biaForm.pristine"
                    class="btn-segal relative waves-light" mdbWavesEffect>Save</button>
            </div>

            
        </app-spinner>
    </div>
</form>

