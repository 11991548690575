
import { Injectable } from '@angular/core';
import { AppContactModel } from '@app/_models/app-contact-model';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Injectable()
export class BiaAppContactListSortService {

  sortDirection = 'ASC';

  constructor(public pageService: BiaListPageService) { }

  sortBy(columnName: string, list: AppContactModel[]) {

    this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    
    if (columnName === 'TYPE' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.contactType;
        const d = b.contactType == null ? '' : b.contactType.contactType;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'TYPE') {

      list.sort((a, b) => {
        const c = a.contactType == null ? '' : a.contactType.contactType;
        const d = b.contactType == null ? '' : b.contactType.contactType;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'NAME' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'NAME') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.fullName;
        const d = b.employee == null ? '' : b.employee.fullName;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'BU' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.businessUnit;
        const d = b.employee == null ? '' : b.employee.businessUnit;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'BU') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.businessUnit;
        const d = b.employee == null ? '' : b.employee.businessUnit;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'EMAIL' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return c.localeCompare(d);
      }
      );

    } else if (columnName === 'EMAIL') {

      list.sort((a, b) => {
        const c = a.employee == null ? '' : a.employee.email;
        const d = b.employee == null ? '' : b.employee.email;
        return d.localeCompare(c);
      }
      )
    }

    else if (columnName === 'APP' && this.sortDirection === 'ASC') {

      list.sort((a, b) => {
        return a.appName.localeCompare(b.appName);
      }
      );

    } else if (columnName === 'APP') {

      list.sort((a, b) => {
        return b.appName.localeCompare(a.appName);
      }
      )
    }

  }
}

