<form [formGroup]="applicationForm" class="md-form">

  <!--<div class='row'>
    <div class='col' style="margin-bottom: 1.5rem; font-size: 0.8rem;">
      <a class='breadcrumb-title' (click)="onNavigateList()">
        <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> Application Inventory
      </a>
      <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i> {{appTitle}}
    </div>
  </div>

  <div class='row'>
    <div class="col-12 col-md-8" style="align-self: end;">
      <ul class="tabs segal-plan">
        <li class="active"><a href="javascript:void(0)">Application</a></li>-->
  <!--<li><a (click)="onShowContacts()">Contact</a></li>-->
  <!--<li><a (click)="onShowDocuments()">Documentation</a></li>
      </ul>
    </div>
  </div>-->

  <div class='row'>
    <div class="col-12 col-lg-12">
      <div class="alert alert-danger" *ngIf="isSubmitted && (applicationForm?.invalid)">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <span>
          Application details could not be saved. Please fix the errors below.
        </span>

        <ul>
          <li *ngIf="applicationForm?.get('appName')?.errors?.required">
            Missing the name of the application.
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div class="row">
    <!--Application Name-->
    <div class="col-12 col-lg-6">
      <div class="md-form">
        <input mdbInput type="text" class="form-control" formControlName="appName"
               maxlength="100" />
        <label>Application Name<span *ngIf="'isSubmitted === false'"> *</span></label>
        <mdb-error *ngIf="isSubmitted && applicationForm.get('appName')?.errors?.required">
          Please enter Application Name
        </mdb-error>
      </div>
    </div>

    <!--Application Short Description-->
    <div class="col-12 col-lg-6">
      <div class="md-form">
        <input mdbInput type="text" class="form-control" formControlName="appShortDesc"
               maxlength="100" />
        <label>Application Short Description<span *ngIf="'isSubmitted === false'"> *</span></label>
      </div>
    </div>
  </div>

  <div class="row">
    <!--App Long Description-->
    <div class="col-12 col-lg-9">
      <div class="md-form">
        <textarea type="text" placeholder="Enter ..."
                  class="md-textarea form-control auto-height" formControlName="appLongDesc"
                  mdbInput style="min-height: 70px;"></textarea>
        <label>Application Long Description</label>
      </div>
    </div>

    <!--Application Version-->
    <div class="col-12 col-lg-3">
      <div class="md-form">
        <input mdbInput type="text" class="form-control" formControlName="appVersion"
               maxlength="100" />
        <label>Application Version</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button *ngIf="pageService.stepMode" mdbBtn type="button" class="btn-segal float-left" aria-label="Next" (click)="stepService.onNextStep()"
              mdbWavesEffect>
        NEXT
      </button>
      <button *ngIf="pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Finish" (click)="stepService.onSaveCloseForm()"
              mdbWavesEffect>
        FINISH LATER
      </button>
      <button *ngIf="pageService.editMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Save" (click)="stepService.onSaveForm()"
              mdbWavesEffect>
        SAVE
      </button>
      <button *ngIf="!pageService.stepMode" type="button" mdbBtn class="btn-segal float-right" aria-label="Close" (click)="stepService.onCloseForm()"
              mdbWavesEffect>
        CLOSE
      </button>
    </div>
  </div>

</form>

