import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdministratorDto } from '@app/_models/administrator-dto';
import { UserRoleModel } from '@app/_models/user-role-model';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  private postHeader = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  public getUser(empId: string): Observable<UserRoleModel> {

    let requestURL = APP_CONFIG.apiDomain + '/user/' + empId;

    return this.http
      .get<UserRoleModel>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public getList(): Observable<AdministratorDto> {

    let requestURL = APP_CONFIG.apiDomain + '/user/list';

    return this.http
      .get<AdministratorDto>(requestURL)
      .pipe(x => {
        return x;
      }, catchError(this.handleError));
  }

  public save(model: UserRoleModel): Observable<UserRoleModel> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/user/save';

    return this.http.post<UserRoleModel>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }

  public delete(model: UserRoleModel): Observable<boolean> {

    const documentJson = JSON.stringify(model);
    const requestURL = APP_CONFIG.apiDomain + '/user/delete';

    return this.http.post<boolean>(requestURL, documentJson, { headers: this.postHeader }).pipe(
      tap(data => {
        console.log(data);
      },
        catchError(this.handleError))
    );
  }
    
  private handleError(error: Response) {
    console.error(error);
    const msg = `Error status code ${error.status} at ${error.url}`;
    return throwError(msg);
  }
}

