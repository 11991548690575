import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BIABusinessChangeModel } from '@app/bia/_models/bia-business-change-model';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { BiaListPageService } from '../bia-list/bia-list-page.service';

@Component({
   selector: 'app-bia-business-change-edit',
   templateUrl: './bia-business-change-edit.component.html',
   styleUrls: ['./bia-business-change-edit.component.scss']
})
export class BiaBusinessChangeEditComponent implements OnInit {

  paramItem: BIABusinessChangeModel = new BIABusinessChangeModel();
  onUpdateItem: Subject<BIABusinessChangeModel> = new Subject<BIABusinessChangeModel>();

  isSubmitted = false;
  saving = false;
  modalTitle = 'Business Change';

  biaForm!: FormGroup;

  toastMessageOptions = { positionClass: 'md-toast-top-center' };

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: MDBModalRef,
    public pageService: BiaListPageService) { }

  ngOnInit(): void {

    this.biaForm = this.formBuilder.group({
      changeDesc: ['', Validators.required],
      impactDesc: [''],
    });

    this.loadPage();
  }

  get changeDesc(): any {
    return this.biaForm.get('changeDesc');
  }

  get impactDesc(): any {
    return this.biaForm.get('impactDesc');
  }

  onSave(): void {
    this.save();
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  public loadPage(): void {

    this.changeDesc.setValue(this.paramItem.changeDesc);
    this.impactDesc.setValue(this.paramItem.impactDesc);
    
    if (this.paramItem.biaId == 0) {
      this.modalTitle += ' [Add]';
    } else {
      this.modalTitle += ' [Edit]';
    }
  }

  public onSubmit(e: any): void {
    this.save();
  }

  public save() {

    if (this.saving) {
      return;
    }

    this.isSubmitted = true;

    if (!this.biaForm.valid) {
      return;
    }

    this.paramItem.changeDesc = this.changeDesc.value;
    this.paramItem.impactDesc = this.impactDesc.value;

    this.onUpdateItem.next(this.paramItem);

    this.modalRef.hide();
  }

}


